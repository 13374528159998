import './index.scss'

import { ReactNode, useCallback, useEffect, useState } from 'react'

import { Collapse } from '@mui/material'
import { enqueueSnackbar, VariantType } from 'notistack'
import { NavLink } from 'react-router-dom'

import ContentIcon from '@admin/assets/img/ContentIcon'
import DisputeIcon from '@admin/assets/img/DisputeIcon'
import Logo from '@admin/assets/img/Logo'
import LogoutIcon from '@admin/assets/img/LogoutIcon'
import PaymentsSettingsIcon from '@admin/assets/img/PaymentsSettingsIcon'
import ShieldIcon from '@admin/assets/img/ShieldIcon'
import TaxModerationIcon from '@admin/assets/img/TaxModerationIcon'
import Users2Icon from '@admin/assets/img/Users2Icon'
import Users3Icon from '@admin/assets/img/Users3Icon'
import WalletIcon from '@admin/assets/img/WalletIcon'
import { LogoutWarningModal } from '@admin/components/Layout/modals'
import LogoutModal from '@admin/components/LogoutModal/LogoutModal'
// import WorldIcon from '@admin/assets/img/WorldIcon';
import Avatar from '@admin/components/shared/Avatar/Avatar'
import Divider from '@admin/components/shared/Divider/Divider'
import Typography from '@admin/components/shared/Typography/Typography'
import { fetchSignOut, logout, selectIsWarningRedirect, selectUserPermissions, setIsWarningRedirect } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchAvatar, selectAvatarPreview } from '@admin/store/profileSlice'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

const NavLinkWithIcon = ({
  icon,
  isDisabled,
  resetBoundary,
  text,
  to,
}: {
  icon: ReactNode
  isDisabled?: boolean
  resetBoundary?: () => void
  text: string
  to: string
}) => (
  <NavLink
    className={({ isActive }) => `layout-menu-link${isActive ? ' layout-menu-link--active' : ''}${isDisabled ? ' layout-menu-link--disabled' : ''}`}
    onClick={resetBoundary}
    to={to}
  >
    {icon}
    <Typography variant="inputText">{text}</Typography>
  </NavLink>
)

const Navbar = ({
  handleOpen,
  isMenuOpen,
  resetBoundary,
}: {
  handleOpen: (value: boolean) => void
  isMenuOpen: boolean
  resetBoundary?: () => void
}) => {
  const dispatch = useAppDispatch()
  const memberAvatarPreview = useAppSelector(selectAvatarPreview)
  const userPermissions = useAppSelector(selectUserPermissions)
  const isWarningRedirect = useAppSelector(selectIsWarningRedirect)

  const hasPaymentsPermissions = checkPermissions(userPermissions, [
    Permissions.PAYMENTS_VIEW_OPERATIONS,
    Permissions.PAYMENTS_VIEW_PAGE_BULK_PAYOUTS,
  ])

  const hasSalesTaxModerationPermissions = checkPermissions(userPermissions, [
    Permissions.SALES_TAX_MODERATION_VIEW_SOLVED_PAGE,
    Permissions.SALES_TAX_MODERATION_VIEW_UNRESOLVED_PAGE,
  ])

  const hasSettingsPermissions = checkPermissions(userPermissions, [
    Permissions.SETTINGS_VIEW_PAGE_TARIFF_GROUPS,
    Permissions.SETTINGS_VIEW_IN_APP_TIERS,
    Permissions.SETTINGS_VIEW_TAXES_CODES,
    Permissions.SETTINGS_VIEW_TAXES_RULES,
    Permissions.SETTINGS_VIEW_PAYMENT_FEES,
    Permissions.SETTINGS_VIEW_TARIFFS_IN_GROUP,
    Permissions.SETTINGS_VIEW_PRICES_RESTRICTIONS_PAGE,
    Permissions.SETTINGS_VIEW_WITHDRAWAL_RESTRICTIONS_PAGE,
  ])

  const hasConsumptionRequestPermissions = checkPermissions(userPermissions, [
    Permissions.REQUESTS_REFUNDS_DISPUTES_VIEW_OPEN_PAGE,
    Permissions.REQUESTS_REFUNDS_DISPUTES_VIEW_CLOSED_PAGE,
  ])

  const hasContentDisplayPermissions = checkPermissions(userPermissions, [Permissions.CONTENT_DISPLAY_VIEW_CONTENT_DISPLAY])

  const hasContentModerationPermissions = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_VIEW_CONTENT_MODERATION])

  const shouldShowDivider = hasPaymentsPermissions || hasSalesTaxModerationPermissions || hasConsumptionRequestPermissions || hasSettingsPermissions

  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)

  const handleWarningModalClose = () => {
    dispatch(setIsWarningRedirect(false))
    setShowWarningModal(false)
  }

  const handleLogoutModalClose = () => {
    if (isWarningRedirect) {
      dispatch(setIsWarningRedirect(false))
    }
    setShowLogoutModal(false)
  }

  const handleWarningModalLogout = () => {
    setShowWarningModal(false)
    setShowLogoutModal(true)
  }

  const handleLogout = useCallback(async () => {
    setShowLogoutModal(false)
    if (isWarningRedirect) {
      dispatch(setIsWarningRedirect(false))
    }
    const result = await dispatch(fetchSignOut())

    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(logout({ message: '' }))
    }
    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Logging out error, try again later', {
        variant: 'error' as VariantType,
      })
    }
  }, [])

  useEffect(() => {
    dispatch(fetchAvatar())
  }, [])

  useEffect(() => {
    if (isWarningRedirect) {
      setShowWarningModal(true)
    }
  }, [isWarningRedirect])

  return (
    <>
      <div className="layout-menu-container">
        <Collapse collapsedSize={64} in={isMenuOpen} orientation={'horizontal'} style={{ height: '100%' }}>
          <div
            className={`layout-menu${isMenuOpen ? ' layout-menu--open' : ''}`}
            onMouseEnter={() => handleOpen(true)}
            onMouseLeave={() => handleOpen(false)}
          >
            <div className="layout-menu--navbar">
              <div className="layout-menu__logo-container">
                <Logo isMenuOpen={isMenuOpen} />
              </div>
              <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

              <NavLinkWithIcon icon={<Users2Icon />} resetBoundary={resetBoundary} text="YZZY users" to="/ui/users" />
              {hasContentDisplayPermissions && (
                <NavLinkWithIcon icon={<ContentIcon />} resetBoundary={resetBoundary} text="Content display" to="/ui/content-display" />
              )}
              {/*<NavLinkWithIcon
                to="/ui/world-management/collections"
                icon={<WorldIcon />}
                text="TiM World management"
              />*/}
              {hasContentModerationPermissions && (
                <NavLinkWithIcon icon={<ShieldIcon />} resetBoundary={resetBoundary} text="Content moderation" to="/ui/moderation" />
              )}

              {shouldShowDivider && <Divider className="layout-menu__divider" orientation="horizontal" flexItem />}

              {hasPaymentsPermissions && <NavLinkWithIcon icon={<WalletIcon />} resetBoundary={resetBoundary} text="Payments" to="/ui/payments" />}
              {hasSalesTaxModerationPermissions && (
                <NavLinkWithIcon icon={<TaxModerationIcon />} resetBoundary={resetBoundary} text="Sales tax moderation" to="/ui/tax-moderation" />
              )}
              {hasConsumptionRequestPermissions && (
                <NavLinkWithIcon
                  icon={<DisputeIcon />}
                  resetBoundary={resetBoundary}
                  text="Requests refunds & disputes"
                  to="/ui/consumption-requests"
                />
              )}
              {hasSettingsPermissions && (
                <NavLinkWithIcon icon={<PaymentsSettingsIcon />} resetBoundary={resetBoundary} text="Payments settings" to="/ui/payments-settings" />
              )}

              <Divider className="layout-menu__divider" orientation="horizontal" flexItem />
              <NavLinkWithIcon icon={<Users3Icon />} resetBoundary={resetBoundary} text="Team" to="/ui/members" />
            </div>

            <div className="layout-menu__user-container">
              <div className="layout-menu-link" onClick={() => setShowLogoutModal(true)}>
                <LogoutIcon />
                <Typography variant="inputText">Log out</Typography>
              </div>

              <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

              <NavLink
                className={({ isActive }) => `layout-menu-link${isActive ? ' layout-menu-link--active' : ''} avatar-icon`}
                onClick={() => resetBoundary?.()}
                to="/ui/profile"
              >
                <Avatar alt="avatar" src={memberAvatarPreview.originalLink ?? ''} />
                <Typography variant="inputText">Profile</Typography>
              </NavLink>
            </div>
          </div>
        </Collapse>
      </div>
      <LogoutModal isWarningRedirect={isWarningRedirect} logout={handleLogout} onClose={handleLogoutModalClose} open={showLogoutModal} />
      <LogoutWarningModal onClose={handleWarningModalClose} onLogout={handleWarningModalLogout} open={showWarningModal} />
    </>
  )
}

export default Navbar
