import { useMemo, useRef, useState } from 'react'

import { Box, Button, Stack } from '@mui/material'
import { DataGridPro, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'

import { calculateAge } from '@yzzy/utils'

import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'

import type { components } from '@admin/shared/api/@types/generated/tim-profile-profile-api'

import { useApproveUser } from './hooks/useApproveUser'
import { useGetWaitScreenUsers } from './hooks/useGetWaitScreenUsers'
import { SAge, SContainer, SEmail, SEmailContainer } from './styled'

const PAGE_SIZE = 10

const RenderApproveButton = ({ profileId }: { profileId: string }) => {
  const { isPending, isSuccess, mutateAsync } = useApproveUser()

  const handleClick = async () => {
    await mutateAsync({
      profileIds: [profileId],
    })
  }

  return (
    <Button disabled={isSuccess} loading={isPending} onClick={handleClick}>
      {isSuccess ? 'Approved' : 'Approve'}
    </Button>
  )
}

const cols: GridColDef[] = [
  {
    minWidth: 250,
    field: 'fullName',
    flex: 0.7,
    headerName: 'Full name',
    renderCell: (props: GridRenderCellParams) => (
      <Stack height="100%" justifyContent="center">
        <AvatarItem primaryText={props.value} subText={props.row?.profileId || ''} />
      </Stack>
    ),
  },
  {
    minWidth: 120,
    field: 'phoneNumber',
    flex: 0.5,
    headerName: 'Phone number',
    renderCell: ({ value }) => (
      <SEmailContainer>
        <Tooltip title={value} placement="top-end" followCursor>
          <SEmail>{value || '—'}</SEmail>
        </Tooltip>
      </SEmailContainer>
    ),
  },
  {
    minWidth: 216,
    editable: false,
    field: 'email',
    flex: 0.5,
    headerName: 'Email',
    renderCell: ({ value }) => (
      <SEmailContainer>
        <Tooltip title={value} placement="top-end" followCursor>
          <SEmail>{value || '—'}</SEmail>
        </Tooltip>
      </SEmailContainer>
    ),
  },
  {
    minWidth: 200,
    field: 'dateOfBirth',
    flex: 0.7,
    headerName: 'Date of birth',
    renderCell: ({ value }) => (
      <div>
        {dayjs(value).format('MM/DD/YYYY')}
        <SAge> ({calculateAge(value)} y.o.)</SAge>
      </div>
    ),
  },
  {
    field: 'socialNetworks',
    flex: 0.7,
    headerName: 'Social networks',
    renderCell: ({ value = [] }) => {
      if (value.length === 0) {
        return '—'
      }

      return (
        <Stack>
          {value.map((item: components['schemas']['SocialNetworkDto']) => (
            <Box key={`${item.type}:${item.username}`}>
              {item.type}: {item.username}
            </Box>
          ))}
        </Stack>
      )
    },
  },
  {
    minWidth: 82,
    align: 'right',
    field: 'settings',
    flex: 0.3,
    headerName: '',
    renderCell: (props: GridRenderCellParams) => {
      if (!props.row?.profileId) {
        return null
      }

      return <RenderApproveButton profileId={props.row.profileId} />
    },
  },
]

export const WaitScreen = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  })

  const { data, isError, isFetching } = useGetWaitScreenUsers(paginationModel.page, 10)

  const users = data?.data?.users || []
  const rowCountReference = useRef(data?.data?.totalElements || 0)

  const rowCount = useMemo(() => {
    if (data?.data?.totalElements !== undefined) {
      rowCountReference.current = data?.data?.totalElements
    }

    return rowCountReference.current
  }, [data?.data?.totalElements])

  const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        return { page: 0, pageSize: newPaginationModel.pageSize }
      }

      return newPaginationModel
    })
  }

  return (
    <SContainer flex={1} position="relative">
      <Box sx={{ inset: 0, position: 'absolute' }}>
        <DataGridPro
          hideFooter={users.length === 0 && !isError}
          hideFooterSelectedRowCount
          slots={{
            footer: CustomFooter,
          }}
          sx={{
            '.MuiDataGrid-cell': {
              borderBottom: 'none',
              margin: '0',
              padding: '0',
            },
          }}
          columnHeaderHeight={64}
          columns={cols}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.profileId}
          loading={isFetching}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[10]}
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={rowCount}
          rowHeight={72}
          rows={users}
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
          disableColumnSorting
          disableRowSelectionOnClick
          pagination
        />
      </Box>
    </SContainer>
  )
}
