import './index.scss'

import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Menu, MenuItem } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useErrorBoundary } from 'react-error-boundary'
import { createSearchParams, useNavigate } from 'react-router-dom'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import RenderExpandableCell from '@admin/components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell'
import ListItemIcon from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import SearchField from '@admin/components/shared/SearchField/SearchField'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { ITariffGroup, ITariffGroupStatus } from '@admin/store/slices/Settings/interface'
import { fetchTariffGroups, selectTariffGroups, selectTariffGroupsStatus } from '@admin/store/slices/Settings/tariffsSlice'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderActionsCell, RenderStatusSelect } from './components'
import { possibleTariffGroupsStatuses } from './constants'

export const TariffGroups: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userPermissions = useAppSelector(selectUserPermissions)
  const data = useAppSelector(selectTariffGroups)
  const status = useAppSelector(selectTariffGroupsStatus)
  const isLoading = status === 'loading'

  const { showBoundary } = useErrorBoundary()
  const [searchValue, setSearchValue] = useState<string>('')
  const [fetchingError, setFetchingError] = useState<boolean>(false)

  const [tableMenuAnchorElement, setTableMenuAnchorElement] = useState<HTMLElement | null>(null)
  const tableMenu = Boolean(tableMenuAnchorElement)

  const [statusMenuAnchorElement, setStatusMenuAnchorElement] = useState<HTMLElement | null>(null)
  const statusMenuOpen = Boolean(statusMenuAnchorElement)

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITariffGroupStatus | null>(null)
  const [currentSelectedTariffGroup, setCurrentSelectedTariffGroup] = useState<ITariffGroup | null>(null)

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: ITariffGroup) => {
    event.stopPropagation()
    setStatusMenuAnchorElement(event.currentTarget)
    setCurrentSelectedTariffGroup(data)
  }

  const handleTableMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setTableMenuAnchorElement(event.currentTarget)
  }

  const handleTableMenuClose = () => {
    setTableMenuAnchorElement(null)
  }

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorElement(null)
  }

  const handleChangeStatusClick = (element: ITariffGroupStatus) => {
    setCurrentSelectedStatus(element)
    handleStatusMenuClose()
  }

  const handleRowClick = (parameters: GridRowParams<TAny>) => {
    if (!checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TARIFFS_IN_GROUP])) {
      return
    }

    const currentRow = parameters.row
    const options = {
      pathname: 'details',
      search: `?${createSearchParams(currentRow)}`,
    }

    navigate(options, { replace: true })
  }

  const fetchTariffGroupsInitial = useCallback(async () => {
    try {
      await dispatch(fetchTariffGroups()).unwrap()
    } catch (error) {
      showBoundary(error)
    }
  }, [dispatch])

  const fetchTariffGroupsGrid = useCallback(async () => {
    dispatch(fetchTariffGroups()).then((result: TAny) => {
      if (result.meta && result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
        setFetchingError(true)
      } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
        setFetchingError(false)
      }
    })
  }, [dispatch])

  const repeatFetchingRequest = useCallback(async () => {
    fetchTariffGroupsGrid()
  }, [dispatch])

  const initialFetchData = useCallback(async () => {
    await fetchTariffGroupsInitial()
  }, [])

  useEffect(() => {
    initialFetchData()
  }, [])

  const columns: GridColDef[] = [
    {
      minWidth: 150,
      display: 'flex',
      field: 'tariffGroupId',
      flex: 0.6,
      headerName: 'ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} isWrappedText />,
      sortable: false,
    },
    {
      minWidth: 140,
      display: 'flex',
      field: 'tariffGroupTitle',
      flex: 0.6,
      headerName: 'Title',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 160,
      display: 'flex',
      field: 'groupDescription',
      flex: 3,
      headerName: 'Description',
      renderCell: (parameters) => <RenderExpandableCell {...parameters} />,
      sortable: false,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'status',
      flex: 0.4,
      headerName: 'Status',
      renderCell: (parameters) => (
        <RenderStatusSelect
          isMenuOpen={statusMenuOpen}
          onClick={(event, data) => handleStatusMenuClick(event, data)}
          props={parameters}
          selectedCellData={currentSelectedTariffGroup}
        />
      ),
      sortable: false,
    },
    {
      minWidth: 70,
      display: 'flex',
      field: 'hidden',
      flex: 0.3,
      headerName: '',
      renderCell: (parameters) => <RenderActionsCell onClick={handleTableMenuClick} props={parameters} />,
      sortable: false,
    },
  ]

  const rows: [] | ITariffGroup[] = useMemo(() => {
    if (data) {
      return data
    } else return []
  }, [data])

  const isEmptyData = !rows.length

  return (
    <div className="tariff-groups-table-container">
      <div className="tariff-groups-table-container__actions">
        {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_GROUP]) && (
          <Button variant="contained" disabled>
            Add tariff group
          </Button>
        )}

        {!isEmptyData && (
          <SearchField
            label="Search by ID"
            clearEvent={() => setSearchValue('')}
            maxlength={50}
            onChange={handleChangeSearch}
            searchValue={searchValue}
            disabled
          />
        )}
      </div>
      <Typography variant="h6">Tariff groups</Typography>
      <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGrid
            hideFooter
            columnVisibilityModel={{
              hidden: checkPermissions(userPermissions, [Permissions.SETTINGS_DELETE_TARIFF_GROUP]),
            }}
            getRowSpacing={() => ({
              bottom: 16,
            })}
            slots={{
              noRowsOverlay: () =>
                CustomNoRowsOverlay({
                  fetchingError: fetchingError,
                  isFiltersApplied: false,
                  refreshData: () => repeatFetchingRequest(),
                  renderNoDataComponent: () => (
                    <>
                      <Typography color="text.secondary" variant="body2">
                        No tariff group has been created yet
                      </Typography>
                      {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_GROUP]) && (
                        <Button color="primary" variant="text">
                          <Typography variant="buttonMedium">Create tariff group</Typography>
                        </Button>
                      )}
                    </>
                  ),
                }),
            }}
            className="tariff-groups-table"
            columnHeaderHeight={64}
            columns={columns}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.tariffGroupId}
            loading={isLoading}
            onRowClick={(parameters) => handleRowClick(parameters)}
            rows={rows}
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
      <Menu
        id="table-menu"
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        MenuListProps={{
          'aria-labelledby': 'table-button',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={tableMenuAnchorElement}
        onClose={handleTableMenuClose}
        open={tableMenu}
      >
        <MenuItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        id="status-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={statusMenuAnchorElement}
        onClose={handleStatusMenuClose}
        open={statusMenuOpen}
      >
        {possibleTariffGroupsStatuses.map((status: TAny) => (
          <MenuItem key={status.title} onClick={() => handleChangeStatusClick(status)} selected={status.status === currentSelectedStatus?.status}>
            {status.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
