import './index.scss'

import { useCallback, useEffect, useState } from 'react'

import { enqueueSnackbar, VariantType } from 'notistack'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router-dom'

import withAuth from '@admin/components/Authorization/withAuth'
import ManageLinkedCollectionsModal from '@admin/components/ManageLinkedCollections/ManageLinkedCollectionsModal'
import Modal from '@admin/components/shared/Modal/Modal'
import BannerDetailsForm from '@admin/components/WorldManagement/Banners/BannerDetails/BannerDetailsForm'
import BannerDetailsCloseModal from '@admin/components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/BannerDetailsCloseModal'
import SetCover, { TCoverAction } from '@admin/components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/SetCover'
import ArchiveBannersModal from '@admin/components/WorldManagement/Banners/BannersModal/ArchiveBanners/ArchiveBannersModal'
import ExpiredBannerModal from '@admin/components/WorldManagement/Banners/BannersModal/ExpiredBanner/ExpiredBannerModal'
import { ErrorBoundaryErrors } from '@admin/containers/ErrorPage/ErrorPage'
import { fetchBanner, IBanner, selectBanner, selectBannerStatus } from '@admin/store/bannerDetailsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchArchiveBanner,
  fetchExpireBanner,
  // selectLinkedCollections, selectWorldColumns
} from '@admin/store/worldManagementSlice'

import type { TAny } from '@yzzy/types'

function BannerDetails() {
  const [archiveBanners, setArchiveBanners] = useState<IBanner | null>(null)
  const [expiredBanners, setExpiredBanners] = useState<IBanner | null>(null)
  const [openLinkedCollections, setOpenLinkedCollections] = useState<IBanner | null>(null)
  const [showSetAvatar, setShowSetAvatar] = useState<{ action: TCoverAction; show: boolean }>({ action: 'edit', show: false })
  const [isOpenClosingModal, setIsOpenClosingModal] = useState(false)

  const { bannerId } = useParams()
  const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const bannerDetails = useAppSelector(selectBanner)
  const bannerStatus = useAppSelector(selectBannerStatus)

  // const linkedCollections = useAppSelector(selectLinkedCollections)
  // const columns = useAppSelector(selectWorldColumns)

  const initialFetchData = useCallback(async () => {
    Promise.all([bannerId && dispatch(fetchBanner(bannerId))]).then((result) => {
      result.forEach((value: TAny) => {
        if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
          showBoundary(new Error(value.error.message))
        }
      })
    })
  }, [])

  useEffect(() => {
    initialFetchData()
  }, [])

  const [errorMessage, setErrorMessage] = useState('')

  const archiveBannerFunction = useCallback(
    async (items: IBanner | IBanner[]) => {
      try {
        setErrorMessage('')
        const isArray = Array.isArray(items)
        const data = isArray ? items.map((item) => item.id) : [items.id]
        const result = await dispatch(fetchArchiveBanner(data))

        if (result.meta.requestStatus === 'fulfilled') {
          if (isArray) {
            setArchiveBanners(null)
            enqueueSnackbar(`${items.length} banners have been archived`, { variant: 'success' as VariantType })
          } else {
            setArchiveBanners(null)
            enqueueSnackbar(`${items.name} banner has been archived`, { variant: 'success' as VariantType })
          }
        } else if (result.meta.requestStatus === 'rejected') {
          if (isArray) setErrorMessage('Archiving error, try again later')
        } else {
          setArchiveBanners(null)
          enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType })
        }
      } catch {
        if (Array.isArray(items)) {
          setErrorMessage('Archiving error. Try later')
        } else {
          setArchiveBanners(null)
          enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType })
        }
      }
    },
    [dispatch],
  )

  const expireBannerFunction = useCallback(
    async (item: IBanner) => {
      const result = await dispatch(fetchExpireBanner([item.id]))

      if (result.meta.requestStatus === 'fulfilled') {
        setExpiredBanners(null)
        enqueueSnackbar(`${item.name} banner has been set as expired`, { variant: 'success' as VariantType })
      } else if (result.meta.requestStatus === 'rejected') {
        setExpiredBanners(null)
        enqueueSnackbar(`Saving error, try again later`, { variant: 'error' as VariantType })
      }
    },
    [dispatch],
  )

  return (
    <div className="bannerDetails-container">
      {bannerStatus === 'idle' && bannerDetails && (
        <BannerDetailsForm
          bannerDetails={bannerDetails}
          refreshData={initialFetchData}
          setArchiveBanners={setArchiveBanners}
          setExpiredBanners={setExpiredBanners}
          setIsOpenClosingModal={() => setIsOpenClosingModal(true)}
          setOpenLinkedCollections={setOpenLinkedCollections}
          setShowSetAvatar={(action: TCoverAction) => setShowSetAvatar({ action, show: true })}
        />
      )}
      <Modal
        onClose={() => {
          setArchiveBanners(null)
          setErrorMessage('')
        }}
        customstyle={{ minHeight: 208 }}
        open={!!archiveBanners}
      >
        <ArchiveBannersModal
          archive={(items) => {
            archiveBannerFunction(items)
          }}
          onClose={() => {
            setArchiveBanners(null)
            setErrorMessage('')
          }}
          errorMessage={errorMessage}
          items={archiveBanners!}
        />
      </Modal>
      <Modal onClose={() => setExpiredBanners(null)} open={!!expiredBanners}>
        <ExpiredBannerModal expire={(item) => expireBannerFunction(item)} item={expiredBanners!} onClose={() => setExpiredBanners(null)} />
      </Modal>
      {bannerDetails && !!openLinkedCollections && (
        <ManageLinkedCollectionsModal
          entityId={bannerDetails.id}
          entityType={'BANNER'}
          onClose={() => setOpenLinkedCollections(null)}
          open={!!openLinkedCollections}
        />
      )}
      {bannerDetails && (
        <Modal onClose={() => setShowSetAvatar({ action: 'edit', show: false })} open={showSetAvatar.show}>
          <SetCover
            avatar={showSetAvatar.action === 'edit' ? bannerDetails.cover : null}
            bannerId={bannerDetails.id}
            coverAction={showSetAvatar.action}
            onClose={() => setShowSetAvatar({ action: 'edit', show: false })}
          />
        </Modal>
      )}
      <Modal customstyle={{ minHeight: 188 }} open={isOpenClosingModal}>
        <BannerDetailsCloseModal
          onClose={() => {
            navigate('/ui/world-management/banners')
          }}
          closeModal={() => setIsOpenClosingModal(false)}
        />
      </Modal>
    </div>
  )
}

export default withAuth(BannerDetails)
