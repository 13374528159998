import { ComponentClass, ReactNode } from 'react'

import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc'

interface ISortableContainer extends SortableContainerProps {
  children: ReactNode
  className?: string
}

const SortableList: ComponentClass<ISortableContainer> = SortableContainer(({ children, className }: { children: ReactNode; className: string }) => (
  <div className={className || ''}>{children}</div>
))

export default SortableList
