import './index.scss'

import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Autocomplete, TextField } from '@mui/material'
import dayjs from 'dayjs'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import { noop } from '@yzzy/utils'

import InfoIcon from '@admin/assets/img/InfoIcon'
import { IMediaProps } from '@admin/components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer'
import ModerationCardContent from '@admin/components/Moderation/ModerationCard/ModerationCardContent/ModerationCardContent'
import CardType from '@admin/components/Moderation/types/CardType'
import ModerationTabs from '@admin/components/Moderation/types/ModerationTabs'
import getRenderCardProps from '@admin/components/Moderation/utils/getRenderCardProps'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { IStatusModal } from '@admin/containers/Moderation/Moderation'
import { selectAuthMemberId } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchChangeStatus } from '@admin/store/moderationSlice'
import colors from '@admin/theme/constants/colors'
import { EntityType, IDropdownItem } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import ChangeTicketStatusModal from '../ChangeTicketStatusModal/ChangeTicketStatusModal'
import SubjectType from '../types/SubjectType'

interface IModerationCardProps {
  allMetrics: {
    content: string[]
    profile: string[]
  }
  dangerousMetrics: {
    content: []
    hive: []
    profile: []
  }
  onChangeStatus: () => void
  renderCardProps: TAny
  showDetails: (entityInfo: TAny) => void
  showPreview: (newValue: IMediaProps) => void
  showUsersProfile: () => void
  subjectTypeConfirmed: SubjectType
  subjectTypeDeclined: SubjectType
  tabValue: ModerationTabs
  ticketsGroup: string
}

const ModerationCard = ({
  allMetrics,
  dangerousMetrics,
  onChangeStatus,
  renderCardProps,
  showDetails,
  showPreview,
  showUsersProfile,
  subjectTypeConfirmed,
  subjectTypeDeclined,
  tabValue,
  ticketsGroup,
}: IModerationCardProps) => {
  const dispatch = useAppDispatch()
  const moderatorId = useAppSelector(selectAuthMemberId)

  const cardProps = useMemo(() => getRenderCardProps(renderCardProps, tabValue), [renderCardProps, tabValue])
  const createdDate = useMemo(() => dayjs(cardProps?.createdDate).format('MM/DD/YYYY, hh:mm A') || null, [cardProps])
  const cardType: CardType = cardProps.cardType
  const isManualMode = cardProps.manualMode

  const complaintsCriteria = useMemo(() => {
    if ((allMetrics && cardType === CardType.CONTENT) || isManualMode) {
      return allMetrics.content.map((item) => ({ titles: [item], value: item }) as IDropdownItem)
    }
    if (allMetrics && cardType === CardType.PROFILE) {
      return allMetrics.profile.map((item) => ({ titles: [item], value: item }) as IDropdownItem)
    }

    return []
  }, [cardType, allMetrics, isManualMode])

  const mostDangerousMetrics = useMemo(() => {
    if (dangerousMetrics && cardType === CardType.HIVE) {
      return dangerousMetrics.hive
    }
    if (dangerousMetrics && cardType === CardType.CONTENT) {
      return dangerousMetrics.content
    }
    if (dangerousMetrics && cardType === CardType.PROFILE) {
      return dangerousMetrics.profile
    }

    return []
  }, [cardType, dangerousMetrics])

  const initialStatusViolation = useMemo(() => {
    return tabValue === ModerationTabs.CONFIRMED_VIOLATIONS ? true : tabValue === ModerationTabs.DECLINED_REQUESTS ? false : undefined
  }, [tabValue])

  const initialViolations = useMemo(() => {
    return isManualMode
      ? cardProps.confirmedManualModeViolations || []
      : cardProps.confirmedComplaintsViolations || cardProps.confirmedHiveViolations || []
  }, [cardProps, isManualMode])

  const shouldDisplayViolations = (isManualMode || cardType !== CardType.HIVE) && !!initialViolations.length

  const [isShowChangeStatusModal, setIsShowChangeStatusModal] = useState<IStatusModal>({
    moderator: '',
    onChangeStatus: noop,
    openModal: false,
    status: false,
    violations: [],
  })

  const [statusViolations] = useState<boolean | undefined>(initialStatusViolation)
  const [selectedViolations, setSelectedViolations] = useState<IDropdownItem[]>([])
  const [isFullHeightCard, setIsFullHeightCard] = useState(false)

  const moderatorName = useMemo(() => {
    if (cardProps?.hiveModeratorId?.toUpperCase() === 'HIVE') return 'HIVE'
    if (cardProps.hiveModerator) return `${cardProps.hiveModerator.firstName} ${cardProps.hiveModerator.lastName}`
    if (cardProps.complaintsModerator) return `${cardProps.complaintsModerator.firstName} ${cardProps.complaintsModerator.lastName}`

    return null
  }, [cardProps.complaintsModerator, cardProps.hiveModerator, cardProps.hiveModeratorId])

  const handleChangeStatus = useCallback(async (data: TAny) => {
    try {
      const result = await dispatch(fetchChangeStatus(data))

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(
          `${getCardType(data.entityType)} ${getCardId(data.entityType)} ${
            !data.status ? 'ticket has been declined' : 'violation has been confirmed'
          } `,
          { variant: 'success' as VariantType },
        )
        onChangeStatus()
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Decision has not been applied, try again later', { variant: 'error' as VariantType })
      }
    } catch {
      enqueueSnackbar('Decision has not been applied, try again later', { variant: 'error' as VariantType })
    }
  }, [])

  const handleConfirmViolation = () => {
    const selectViolationsValues = selectedViolations.map(({ value }) => value)
    const manualCheckedViolationsValues = isManualMode ? selectViolationsValues : null

    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS) {
      setIsShowChangeStatusModal({
        moderator: moderatorName,
        onChangeStatus: () =>
          handleChangeStatus({
            cardType,
            confirmedViolations: cardType === CardType.HIVE ? manualCheckedViolationsValues : selectViolationsValues,
            entityId: cardProps.entityId,
            entityType: cardProps.entityType,
            historical: true,
            manualMode: isManualMode,
            moderatorId,
            status: true,
          }).then(() => {
            setSelectedViolations([])
          }),
        openModal: true,
        status: true,
        violations: selectViolationsValues,
      })

      return
    }
    setSelectedViolations([])
    handleChangeStatus({
      cardType,
      confirmedViolations: cardType === CardType.HIVE ? manualCheckedViolationsValues : selectViolationsValues,
      entityId: cardProps.entityId,
      entityType: cardProps.entityType,
      historical: false,
      manualMode: isManualMode,
      moderatorId,
      status: true,
    })
  }

  const handleNoViolation = () => {
    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS) {
      setIsShowChangeStatusModal({
        moderator: moderatorName,
        onChangeStatus: () =>
          handleChangeStatus({
            cardType,
            entityId: cardProps.entityId,
            entityType: cardProps.entityType,
            historical: true,
            moderatorId,
            status: false,
          }).then(() => {
            setSelectedViolations([])
          }),
        openModal: true,
        status: false,
        violations: [],
      })

      return
    }
    setSelectedViolations([])
    handleChangeStatus({
      cardType,
      entityId: cardProps.entityId,
      entityType: cardProps.entityType,
      historical: false,
      moderatorId,
      status: false,
    })
  }

  const isAssignedToModerator = useMemo(() => !!cardProps.complaintsModerator || !!cardProps.hiveModerator, [cardProps])

  const getCardType = (entityType: string) => {
    if (entityType?.toUpperCase() === EntityType.AVATAR) {
      return 'Profile pic of user'
    }
    if (entityType?.toUpperCase() === EntityType.ABOUT_ME) {
      return 'What im about of user'
    } else return startCase(entityType?.toLowerCase())
  }

  const getCardId = (entityType: string, removeHash = false) => {
    const id =
      entityType?.toUpperCase() === EntityType.ABOUT_ME || entityType?.toUpperCase() === EntityType.AVATAR
        ? cardProps?.entityOwnerInfo?.entityOwnerId
        : cardProps?.entityId

    return removeHash ? `${id}` : `#${id}`
  }

  useEffect(() => setSelectedViolations([]), [subjectTypeConfirmed, subjectTypeDeclined])
  useEffect(() => setIsFullHeightCard(false), [ticketsGroup])

  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const textReference = useRef<HTMLDivElement>(null)

  const isTextEllipsisActive = (element: HTMLDivElement) => {
    return element && element.offsetWidth < element.scrollWidth
  }

  useEffect(() => {
    const element = textReference.current

    if (element) {
      setIsEllipsisActive(isTextEllipsisActive(element))
    }
  }, [cardProps?.entityType])

  return (
    <div className="card" style={{ maxHeight: isFullHeightCard ? '100%' : '400px', minHeight: '400px' }}>
      <div className="card-header">
        <div className="card-header-text">
          <Tooltip
            title={getCardId(cardProps?.entityType, true)}
            disableHoverListener={!isEllipsisActive}
            placement="top-end"
            style={{ display: 'inline-flex' }}
            followCursor
          >
            <Typography className="card-header-name" component="div" variant="body1">
              <div className="card-header-name" ref={textReference}>
                {getCardType(cardProps?.entityType)} {getCardId(cardProps?.entityType)}
              </div>
            </Typography>
          </Tooltip>
          <Stack alignItems="center" direction="row" gap={0.5}>
            <Typography color="text.secondary" component="span" variant="caption">
              Ticket from {createdDate}
            </Typography>
            {isManualMode && (
              <Tooltip title="Manual mode ticket. It wasn't checked by Hive" placement="top" followCursor>
                <InfoIcon sx={{ color: colors.primary, fontSize: 20 }} />
              </Tooltip>
            )}
          </Stack>
        </div>
        {(ticketsGroup === 'My' || [ModerationTabs.CONFIRMED_VIOLATIONS, ModerationTabs.DECLINED_REQUESTS].includes(tabValue)) && (
          <div className="card-buttons">
            {statusViolations !== undefined && (
              <div className="card-buttons-moderator">
                <Typography color="text.primary" component="span" sx={{ width: 'max-content' }} variant="subtitle2">
                  {moderatorName}&nbsp;
                </Typography>
                <Typography color="text.primary" component="span" variant="body2">
                  {statusViolations ? 'confirmed violation' : "didn't confirm a violation"}
                </Typography>
                {statusViolations && shouldDisplayViolations && (
                  <>
                    &nbsp;for&nbsp;
                    <Typography color="text.primary" component="span" variant="subtitle2">
                      {initialViolations[0]}
                    </Typography>
                    {initialViolations.length > 1 && (
                      <>
                        <Typography color="text.primary" component="span" variant="body2">
                          &nbsp;and&nbsp;
                        </Typography>
                        <Tooltip
                          title={initialViolations.slice(1)?.map((violation: string) => (
                            <ul key={violation} style={{ margin: 0, paddingLeft: '1rem' }}>
                              <li>{violation}</li>
                            </ul>
                          ))}
                          placement="top"
                          arrow
                        >
                          <Typography className="text--dashed" color="text.primary" component="span" variant="subtitle2">
                            {initialViolations.length - 1} other categories
                          </Typography>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {(isManualMode || cardType !== CardType.HIVE) && !statusViolations && (
              <Autocomplete
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    label="Violation category"
                    slotProps={{
                      htmlInput: { ...inputProps, readOnly: true },
                    }}
                    sx={{ input: { width: 0, cursor: 'pointer', flex: 'none' } }}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  const numberTags = tagValue.length

                  return tagValue.slice(0, 1).map((option, index) => {
                    return (
                      <Fragment key={`+${numberTags - 1}`}>
                        <Chip
                          label={option.titles[0]}
                          size="small"
                          variant="filled"
                          {...getTagProps({ index })}
                          sx={{ maxWidth: 'calc(100% - 24px)' }}
                        />
                        {numberTags > 1 && ` +${numberTags - 1}`}
                      </Fragment>
                    )
                  })
                }}
                sx={{
                  minWidth: 245,
                  '.MuiAutocomplete-inputRoot': { flexWrap: 'nowrap' },
                  '.MuiAutocomplete-tag': { maxWidth: 'calc(100% - 24px)' },
                  maxHeight: 40,
                  minHeight: 40,
                  ml: 2,
                }}
                clearIcon={null}
                getOptionLabel={({ titles }) => titles[0]}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, value) => setSelectedViolations(value)}
                options={complaintsCriteria}
                size="small"
                value={selectedViolations}
                disableCloseOnSelect
                multiple
              />
            )}
            {!statusViolations ? (
              <Button
                color="error"
                disabled={!isManualMode ? cardType !== CardType.HIVE && !selectedViolations.length : !selectedViolations.length}
                onClick={handleConfirmViolation}
                sx={{ maxHeight: 40, minHeight: 40, ml: 1, w: 1 }}
                variant={statusViolations === undefined || (cardType !== CardType.HIVE && !selectedViolations.length) ? 'contained' : 'outlined'}
              >
                Confirm violation
              </Button>
            ) : null}
            {statusViolations === undefined || statusViolations ? (
              <Button
                color="success"
                onClick={handleNoViolation}
                sx={{ maxHeight: 40, minHeight: 40, ml: 2 }}
                variant={statusViolations === undefined ? 'contained' : 'outlined'}
              >
                No violation
              </Button>
            ) : null}
          </div>
        )}
        {ticketsGroup === 'All' &&
          tabValue !== ModerationTabs.CONFIRMED_VIOLATIONS &&
          tabValue !== ModerationTabs.DECLINED_REQUESTS &&
          moderatorName && (
            <div className="card-assign-info">
              {isAssignedToModerator && (
                <Typography color="text.secondary" variant="body2">
                  Assigned to{' '}
                  <Typography color="text.primary" component="span" variant="subtitle2">
                    {moderatorName}
                  </Typography>
                </Typography>
              )}
            </div>
          )}
      </div>
      <ModerationCardContent
        amountViolations={cardProps.amountViolations}
        cardType={cardProps.cardType}
        complaints={cardProps.complaints}
        entityInfo={cardProps.entityInfo}
        entityOwnerInfo={cardProps.entityOwnerInfo}
        hiveViolationConfirmed={cardProps.hiveViolationConfirmed}
        isFullHeightCard={isFullHeightCard}
        isManualMode={cardProps.manualMode}
        moderationResults={cardProps.moderationJobResults}
        mostDangerousMetrics={mostDangerousMetrics}
        profileInfo={cardProps.profileInfo}
        setIsFullHeightCard={setIsFullHeightCard}
        showDetails={showDetails}
        showPreview={showPreview}
        showUsersProfile={showUsersProfile}
        tabValue={tabValue}
        violationsFrom={cardProps.violationsFrom}
      />
      <ChangeTicketStatusModal
        onClose={() =>
          setIsShowChangeStatusModal({
            moderator: '',
            openModal: false,
            status: false,
            violations: [],
          })
        }
        moderator={isShowChangeStatusModal.moderator}
        onChangeStatus={isShowChangeStatusModal.onChangeStatus}
        open={isShowChangeStatusModal.openModal}
        status={isShowChangeStatusModal.status}
        violations={isShowChangeStatusModal.violations}
      />
    </div>
  )
}

export default ModerationCard
