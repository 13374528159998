import './index.scss'

import { useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { DateTimePickerTabs } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import ArrowBack from '@admin/assets/img/ArrowBack'
import CalendarIcon from '@admin/assets/img/CalendarIcon'
import ChevronRightIcon from '@admin/assets/img/ChevronRightIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import RefreshIcon from '@admin/assets/img/RefreshIcon'
import Breadcrumbs from '@admin/components/shared/Breadcrumbs/Breadcrumbs'
import DateTimePicker from '@admin/components/shared/DateTimePicker/DateTimePicker'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import TextField from '@admin/components/shared/TextField/TextField'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { TCoverAction } from '@admin/components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/SetCover'
import TextEditorField from '@admin/components/WorldManagement/Banners/BannerDetails/TextEditorField/TextEditorField'
import getValidationSchema from '@admin/components/WorldManagement/Banners/BannersModal/utils/getValidationSchema'
import ContextMenu from '@admin/components/WorldManagement/ContextMenu/ContextMenu'
import { fetchEditBanner, IBanner, savingBannerStatus } from '@admin/store/bannerDetailsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'

import type { TAny } from '@yzzy/types'

interface IProps {
  bannerDetails: IBanner
  refreshData(): void
  setArchiveBanners(banner: IBanner): void
  setExpiredBanners(banner: IBanner): void
  setIsOpenClosingModal(): void
  setOpenLinkedCollections(banner: IBanner): void
  setShowSetAvatar(action: TCoverAction): void
}

// todo добавить сохранение деталей.
const BannerDetailsForm = ({
  bannerDetails,
  refreshData,
  setArchiveBanners,
  setExpiredBanners,
  setIsOpenClosingModal,
  setOpenLinkedCollections,
  setShowSetAvatar,
}: IProps) => {
  const [dateWithInitialValue, setDateWithInitialValue] = useState<Dayjs | null>(bannerDetails.expiredDate ? dayjs(bannerDetails.expiredDate) : null)
  const disableEditing = useMemo(() => bannerDetails.status === 'LIVE', [bannerDetails])

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const savingStatus = useAppSelector(savingBannerStatus)

  const schema = useMemo(() => getValidationSchema('edit'), [])
  const initialDetails = useMemo(() => {
    return {
      name: bannerDetails.name,
      subtitle: bannerDetails.subtitle,
      title: bannerDetails.title,
      article: bannerDetails.article,
      expiredDate: bannerDetails.expiredDate,
    }
  }, [bannerDetails.article, bannerDetails.expiredDate, bannerDetails.name, bannerDetails.subtitle, bannerDetails.title])

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm<IBanner>({
    defaultValues: initialDetails,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(schema),
  })

  // todo картинка баннера должна загружаться отдельно и сохраняться сразу. она не будет влиять на сохранение остального
  // замена картинки уже работает. с изменением текущей косямба :(
  // (смотри src/components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/SetCover.tsx)
  const onSubmit = async (data: TAny) => {
    if (disableEditing) {
      return
    }

    trigger()

    try {
      // enqueueSnackbar('Changes have been saved', { variant: 'success' as VariantType });
      const result = await dispatch(fetchEditBanner({ bannerId: bannerDetails.id, data: data }))

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Changes have been saved`, { variant: 'success' as VariantType })
        refreshData()
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType })
        // setErrorMessage('Saving error, try again later');
      }
    } catch {
      enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType })
    }
  }

  return (
    <form className="bannerDetails-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="bannerDetails-breadcrumbs">
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <div className="bannerDetails-header-text">
            <IconButton
              onClick={() => {
                if (!disableEditing && isDirty) {
                  setIsOpenClosingModal()

                  return
                }
                navigate('/ui/world-management/banners')
              }}
            >
              <ArrowBack />
            </IconButton>
            Tim World Management
          </div>
          <div className="bannerDetails-header-text">Banners</div>
          <div className="bannerDetails-header-text bannerDetails-header-text_main">
            {bannerDetails.name}
            {isDirty && <span className="bannerDetails-header-text"> (not saved)</span>}
          </div>
        </Breadcrumbs>
        <div className="bannerDetails-header-actions">
          <LoadingButton color="primary" disabled={disableEditing || !isDirty} loading={savingStatus === 'loading'} type="submit" variant="contained">
            Save
          </LoadingButton>
          <ContextMenu
            item={bannerDetails}
            setArchiveBanners={(banner: IBanner) => setArchiveBanners(banner)}
            setExpiredBanners={(banner: IBanner) => setExpiredBanners(banner)}
            setLinkedCollections={(banner: IBanner) => setOpenLinkedCollections(banner)}
          />
        </div>
      </div>
      <div className={disableEditing ? 'bannerDetails-header bannerDetails-header_disabled' : 'bannerDetails-header'}>
        <div className="bannerDetails-header-title">Settings</div>
        <div className="bannerDetails-header-lastModified">Last modified: {new Date(bannerDetails.updatedAt).toLocaleDateString()}</div>
        <TextField
          disabled={disableEditing}
          {...register('name')}
          label="Name*"
          name="name"
          defaultValue={initialDetails.name}
          error={!!errors.name}
          onBlur={() => trigger('name')}
          size="small"
        />
        <DateTimePicker
          label="Validity date & time"
          onChange={(newValue) => {
            setDateWithInitialValue(newValue)
            setValue('expiredDate', newValue ? new Date(newValue.toString()) : null, { shouldDirty: true })
          }}
          slotProps={{
            textField: {
              ...register('expiredDate'),
              defaultValue: initialDetails.expiredDate,
              size: 'small',
            },
          }}
          slots={{
            openPickerIcon: CalendarIcon,
            tabs: DateTimePickerTabs,
          }}
          disabled={disableEditing}
          minDateTime={dayjs(new Date()).add(5, 'minutes')}
          value={dateWithInitialValue}
        />
      </div>
      <div className="bannerDetails-content">
        <div className="bannerDetails-content-title">IBanner</div>
        <div className="bannerDetails-content-info">
          <div>
            <div className="avatar-imageLabel">Cover</div>
            <div className="avatar-imageContainer">
              <div className="avatar-imageContainer-buttons">
                <Tooltip title="Edit photo" placement="top-start">
                  <span className={disableEditing ? 'avatar-imageContainer-buttons_disabled' : ''}>
                    <IconButton disabled={disableEditing} onClick={() => setShowSetAvatar('edit')}>
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Replace photo" placement="top-start">
                  <span className={disableEditing ? 'avatar-imageContainer-buttons_disabled' : ''}>
                    <IconButton disabled={disableEditing} onClick={() => setShowSetAvatar('replace')}>
                      <RefreshIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
              <img alt="avatar" src={bannerDetails.cover.originalLink} />
            </div>
          </div>
          <div
            className={
              disableEditing ? 'bannerDetails-content-info-fields bannerDetails-content-info-fields_disabled' : 'bannerDetails-content-info-fields'
            }
          >
            <TextField
              disabled={disableEditing}
              {...register('title')}
              fullWidth={true}
              label="Title (optional)"
              name="title"
              defaultValue={initialDetails.title}
              size="small"
            />
            <TextField
              disabled={disableEditing}
              {...register('subtitle')}
              fullWidth={true}
              label="Subtitle (optional)"
              name="subtitle"
              defaultValue={initialDetails.subtitle}
              size="small"
            />
            <TextEditorField
              label="Article"
              onChange={(textString) => {
                if (!disableEditing && textString !== initialDetails.article?.textString) {
                  setValue('article.textString', textString)
                }
              }}
              onSave={(editorString) => {
                if (!disableEditing && editorString !== initialDetails.article?.editorString) {
                  setValue('article.editorString', editorString, { shouldDirty: true })
                }
              }}
              defaultValue={initialDetails.article?.editorString}
              maxLength={2000}
              placeholder="Write an article"
              readOnly={disableEditing}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default BannerDetailsForm
