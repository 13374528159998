import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { enqueueSnackbar, type VariantType } from 'notistack'

import type { components } from '@admin/shared/api/@types/generated/tim-profile-profile-api'

const patchApproveUserAPI = async (requestBody: components['schemas']['ApproveUsersInWaitingListRequest']) =>
  axios.patch<void>('/moderation/tim-profile-profile-api/api/v2/waiting-list/approve', requestBody)

export const useApproveUser = () =>
  useMutation({
    mutationFn: patchApproveUserAPI,
    onError: () => {
      enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
    },
  })
