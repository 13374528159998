import './index.scss'

import { useCallback, useEffect, useState } from 'react'

import { enqueueSnackbar, VariantType } from 'notistack'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import ZoomInIcon from '@admin/assets/img/ZoomInIcon'
import ZoomOuttaIcon from '@admin/assets/img/ZoomOuttaIcon'
import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Dropzone from '@admin/components/shared/Dropzone/Dropzone'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ImageCrop from '@admin/components/shared/ImageCrop/ImageCrop'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Modal from '@admin/components/shared/Modal/Modal'
import Slider from '@admin/components/shared/Slider/Slider'
import { TAvatarAction } from '@admin/containers/Profile/Profile'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchUpdateAvatar, selectProfileStatus } from '@admin/store/profileSlice'
import { IMedia } from '@admin/types/commonTypes'
import { blobToFile } from '@admin/utils//blobToFile'
import { getCroppedImg } from '@admin/utils//canvasUtils'

import type { Area } from 'react-easy-crop/types'

interface IProps {
  avatar: IMedia | null
  avatarAction: TAvatarAction
  onClose(): void
}

const BYTE = 1
const KILOBYTE = 1024 * BYTE
const MEGABYTE = 1024 * KILOBYTE
const ACCEPTED_FILES = ['image/jpeg', 'image/jpg', 'image/png']

interface Image {
  file: File
  src: string
  type?: string
}

const SetAvatar = ({ avatar, avatarAction, onClose }: IProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [image, setImage] = useState<Image | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,sonarjs/no-unused-vars
  const [_, setPreviewImage] = useState<Image | null>(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [zoom, setZoom] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const status = useAppSelector(selectProfileStatus)

  const dispatch = useAppDispatch()

  const initialAvatar = useCallback(async () => {
    if (avatar) {
      const result = await fetch(avatar.originalLink, {
        headers: {
          'Access-Control-Allow-Headers': 'Authorization, X-Requested-With, Accept, Content-Type, Origin, Cache-Control, X-File-Name',
          'Access-Control-Allow-Origin': '*',
        },
        method: 'GET',
        mode: 'no-cors',
      })
      const data = await result?.blob()
      const metadata = { type: data.type }
      const filename = avatar.originalLink.replace(/\?.+/, '').split('/').pop()

      setImage({
        file: new File([data], filename!, metadata),
        src: avatar.originalLink,
        type: avatar.type,
      })
    }
  }, [])

  useEffect(() => {
    initialAvatar()

    return () => setImage(null)
  }, [])

  const onLoadImage = (files: File[]) => {
    if (files && files[0]) {
      const img = new Image()
      const blobUrl: string = URL.createObjectURL(files[0])

      img.onload = () => {
        if (img.width > 5000 || img.height > 5000) {
          setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed')
        } else {
          setImage({
            file: files[0],
            src: blobUrl,
            type: files[0].type,
          })
        }
      }
      img.src = blobUrl
    }
  }

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleUploadPhoto = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image?.src ?? '',
        image?.type ?? '',
        croppedAreaPixels ?? {
          width: 244,
          height: 244,
          x: 0,
          y: 0,
        },
        0,
      )

      if (croppedImage) {
        const fileCroppedImage = blobToFile(croppedImage, image?.file.name + 'preview')

        setPreviewImage({
          file: fileCroppedImage,
          src: URL.createObjectURL(croppedImage),
        })

        const result = await dispatch(
          fetchUpdateAvatar({
            avatar: avatar?.originalLink !== image?.src ? image?.file : undefined,
            avatarPreview: fileCroppedImage,
          }),
        )

        if (result.meta.requestStatus === 'fulfilled') {
          const snackMessage = 'Photo has been uploaded'

          enqueueSnackbar(snackMessage, { variant: 'success' as VariantType })
          onClose()
        }
        if (result.meta.requestStatus === 'rejected') {
          setErrorMessage('Uploading error, try again later')
        }
      }
    } catch {
      setErrorMessage('Uploading photo error')
    }
  }, [image, croppedAreaPixels])

  return (
    <div className="profileModal">
      <div className="profileModal-title">
        {avatarAction === 'edit' && 'Edit photo'}
        {avatarAction === 'replace' && 'Replace photo'}
        {avatarAction === 'add' && 'Add photo'}
      </div>
      <div className="profileModal-form profileModal-form_p0">
        <div className="profileModal-form-uploadArea">
          {image !== null ? (
            <div className="profileModal-form-uploadArea_containerCropper">
              {avatarAction !== 'edit' && (
                <IconButton className="profileModal-form-uploadArea_deleteButton" disabled={status === 'loading'} onClick={() => setImage(null)}>
                  <DeleteIcon />
                </IconButton>
              )}
              <div className="profileModal-form-uploadArea_crop">
                <ImageCrop
                  style={{
                    containerStyle: { borderRadius: '8px' },
                  }}
                  aspect={1}
                  image={image.src}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  zoom={zoom}
                />
              </div>
              <div className="profileModal-form-uploadArea_controls">
                <IconButton onClick={() => setZoom((previousState) => (previousState <= 1 ? previousState : Number(previousState - 0.1)))}>
                  <ZoomOuttaIcon />
                </IconButton>
                <Slider
                  aria-labelledby="Zoom"
                  classes={{ root: 'slider' }}
                  max={3}
                  min={1}
                  onChange={(_event, zoom) => setZoom(Number(zoom))}
                  size="small"
                  step={0.1}
                  value={zoom}
                />
                <IconButton onClick={() => setZoom((previousState) => (previousState >= 3 ? previousState : Number(previousState + 0.1)))}>
                  <ZoomInIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className="profileModal-form-uploadArea_containerDropzone">
              <span className="profileModal-form-uploadArea_title">One .jpg, .jpeg or .png file only</span>
              <Dropzone
                getFileAddedMessage={() => {
                  setErrorMessage('')

                  return ''
                }}
                onDropRejected={(rejectedFiles) => {
                  setErrorMessage('')
                  if (rejectedFiles.length > 1) {
                    setErrorMessage('Only one file allowed')

                    return
                  }
                  if (!ACCEPTED_FILES.includes(rejectedFiles[0].type)) {
                    setErrorMessage('Unsupported file extension')

                    return
                  }
                  if (rejectedFiles[0].size > MEGABYTE * 30) {
                    setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed')
                  }
                }}
                acceptedFiles={ACCEPTED_FILES}
                dropzoneClass="profileModal-form-uploadArea_dropzone"
                dropzoneText={'Drag and drop a file here or click'}
                filesLimit={1}
                initialFiles={avatarAction === 'edit' && image ? [image as File] : undefined}
                maxFileSize={30 * MEGABYTE}
                onDrop={onLoadImage}
                showAlerts={false}
                showPreviews={false}
                showPreviewsInDropzone={false}
              />
            </div>
          )}
        </div>
        {errorMessage && errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        <div className="profileModal-buttonsGroup">
          <Button disabled={status === 'loading'} onClick={() => (image ? setIsOpenModal(true) : onClose())} variant="text">
            Cancel
          </Button>
          <LoadingButton
            disabled={image === null}
            loading={status === 'loading'}
            loadingPosition="start"
            onClick={handleUploadPhoto}
            startIcon={null}
            type="submit"
            variant="contained"
          >
            {avatarAction === 'edit' ? 'Save changes' : 'Upload photo'}
          </LoadingButton>
        </div>
      </div>
      <Modal customstyle={{ minHeight: 188 }} open={isOpenModal}>
        <div className="cancelModal">
          <div className="cancelModal-header">
            {avatarAction === 'add' && `Cancel adding photo?`}
            {avatarAction === 'edit' && `Cancel editing photo`}
            {avatarAction === 'replace' && `Cancel replacing photo`}
          </div>
          <div className="cancelModal-content">
            <span className="cancelModal-content-value">Changes won’t be applied</span>
          </div>
          <div className="cancelModal-footer">
            <div className="cancelModal-footer-buttons">
              <Button onClick={() => setIsOpenModal(false)}>Don’t cancel</Button>
              <Button
                onClick={() => {
                  setIsOpenModal(false)
                  onClose()
                }}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SetAvatar
