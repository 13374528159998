import './index.scss'

import { useEffect, useRef, useState } from 'react'

import PauseFillIcon from '@admin/assets/img/PauseFillIcon'
import PlayFillIcon from '@admin/assets/img/PlayFillIcon'
import SpeedIcon from '@admin/assets/img/SpeedIcon'
import VolumeIcon from '@admin/assets/img/VolumeIcon'
import VolumeMuteIcon from '@admin/assets/img/VolumeMuteIcon'
import Button from '@admin/components/shared/Button/Button'
import Card from '@admin/components/shared/Card/Card'
import CardActionArea from '@admin/components/shared/Card/CardActionArea'
import CardActions from '@admin/components/shared/Card/CardActions'
import CardMedia from '@admin/components/shared/Card/CardMedia'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Slider from '@admin/components/shared/Slider/Slider'
import useVideoPlayer from '@admin/hooks/useVideoPlayer'
import colors from '@admin/theme/constants/colors'
import { ContentType, IMediaItem } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import MediaHeader from './MediaHeader/MediaHeader'
import MediaThumbnails from './MediaThumbnails/MediaThumbnails'

export interface IMediaProps {
  contentType: string
  entityId: string
  entityOwnerId?: string
  entityType: string
  mainEntity: IMediaItem
  mediaId?: string
  mediaUrl: string
  orderedMediaList: IMediaItem[]
  showDetailedHeader?: boolean
}

interface IMediaPreviewDrawerProps {
  mediaProps: IMediaProps
  onClose: () => void
}

const MediaPreviewDrawer = ({ mediaProps, onClose }: IMediaPreviewDrawerProps) => {
  const { contentType, entityId, entityOwnerId, entityType, mainEntity, mediaId, mediaUrl, orderedMediaList, showDetailedHeader = false } = mediaProps

  const videoReference = useRef<HTMLVideoElement | null>(null)
  const imageSourceReference = useRef(mediaUrl)
  const [playerSliderVolumeState, setPlayerSliderVolumeState] = useState(false)
  const [playerSliderSpeedState, setPlayerSliderSpeedState] = useState(false)
  const [wasPausedBeforeDragging, setWasPausedBeforeDragging] = useState(false)
  const [currentMainEntity, setCurrentMainEntity] = useState<IMediaItem>(mainEntity)
  const [error, setError] = useState(false)
  const [isLoadingMainMedia, setIsLoadingMainMedia] = useState<boolean>(true)
  const [isReloadingMainMedia, setIsReloadingMainMedia] = useState<boolean>(false)
  const [isWaitingMainMedia, setIsWaitingMainMedia] = useState<boolean>(false)
  const [mediaWidth, setMediaWidth] = useState(0)

  const {
    handleReloadVideo,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoVolume,
    handleOnTimeUpdate,
    playerState,
    setPlayerState,
    toggleMute,
    togglePlay,
  } = useVideoPlayer(videoReference, isLoadingMainMedia)

  const formatDuration = (value: number) => {
    const minute = Math.floor(value / 60)
    const secondLeft = Math.floor(value - minute * 60)
    const secondString = secondLeft < 10 ? `0${secondLeft}` : `${secondLeft}`

    return `${minute}:${secondString}`
  }

  const handleError = () => {
    setError(true)
    setIsLoadingMainMedia(false)
  }

  const handleMouseDown = () => {
    setWasPausedBeforeDragging(!playerState.isPlaying)
  }

  const handleMouseUp = () => {
    if (!wasPausedBeforeDragging) {
      setPlayerState((previous) => ({
        ...previous,
        isPlaying: true,
      }))
    }
  }

  const handleReloadImage = async () => {
    const now = new Date()

    imageSourceReference.current = `${mediaUrl}?timestamp=${now.getTime()}`
    setCurrentMainEntity({
      ...currentMainEntity,
      mediaUrl: `${currentMainEntity.mediaUrl}?timestamp=${now.getTime()}`,
    })
  }

  const onThumbnailClick = (maniEntity: IMediaItem) => {
    setError(false)
    setIsLoadingMainMedia(true)
    setCurrentMainEntity(maniEntity)
  }

  const handleReload = () => {
    setError(false)
    setIsLoadingMainMedia(true)
    setIsReloadingMainMedia(true)
    handleReloadVideo()
    handleReloadImage()
  }

  useEffect(() => {
    const handleMouseUp = () => {
      setPlayerSliderVolumeState(false)
      setPlayerSliderSpeedState(false)
    }

    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  useEffect(() => {
    if (videoReference.current) {
      const width = videoReference.current.getBoundingClientRect().width

      setMediaWidth(width)
    }
  }, [mediaUrl, isLoadingMainMedia])

  return (
    <div className={`media ${error ? 'media-initial-width' : ''}`}>
      <MediaHeader
        contentType={contentType}
        entityId={entityId}
        entityOwnerId={entityOwnerId}
        entityType={entityType}
        mediaId={mediaId}
        onClose={onClose}
        showDetailedHeader={showDetailedHeader}
      />
      {orderedMediaList?.length > 1 ? (
        <div className="multimedia">
          <Card
            sx={{
              backgroundColor:
                currentMainEntity?.mediaType?.toUpperCase() === ContentType.VIDEO && !isLoadingMainMedia && !error
                  ? colors.backdropOverlay
                  : colors.white,
              boxShadow: 0,
            }}
            onError={handleError}
          >
            {error && (
              <div className="media-fallback">
                <p className="media-fallback-text">Cannot load media</p>
                <Button className="media-fallback-button" onClick={handleReload}>
                  Reload
                </Button>
              </div>
            )}
            {(isLoadingMainMedia || isWaitingMainMedia) && (
              <div className="media-fallback-loader">
                <CircularProgress />
              </div>
            )}
            <div className="main-media">
              {currentMainEntity?.mediaType === ContentType.VIDEO ? (
                <>
                  <CardActionArea disabled={isLoadingMainMedia} onClick={togglePlay}>
                    <CardMedia
                      onCanPlayThrough={() => {
                        setIsWaitingMainMedia(false)
                        setIsLoadingMainMedia(false)
                      }}
                      onLoadedData={(event) => {
                        videoReference.current = event.target as HTMLVideoElement
                        setIsLoadingMainMedia(false)
                      }}
                      sx={{
                        maxWidth: `${isLoadingMainMedia ? mediaWidth + 'px' : '100%'}`,
                      }}
                      className="media-content-preview"
                      component="video"
                      onError={(event: TAny) => (videoReference.current = event.target)}
                      onTimeUpdate={handleOnTimeUpdate}
                      onWaiting={() => setIsWaitingMainMedia(true)}
                      ref={videoReference}
                      src={currentMainEntity.mediaUrl}
                    />
                  </CardActionArea>
                  {currentMainEntity?.mediaType === ContentType.VIDEO && !error && !isLoadingMainMedia && (
                    <CardActions>
                      <Button className="media-actions_play-buttons" onClick={togglePlay}>
                        {playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100 && <PauseFillIcon />}
                        {(playerState.progress === 0 ||
                          playerState.progress === 100 ||
                          (!playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100)) && <PlayFillIcon />}
                      </Button>
                      <div className="media-actions">
                        <div
                          className={`media-actions_volume ${playerSliderVolumeState ? 'active' : ''}`}
                          onMouseDown={() => setPlayerSliderVolumeState(true)}
                        >
                          <Button className="media-actions_volumeButton" onClick={toggleMute}>
                            {playerState.isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
                          </Button>
                          <div className="media-actions_slider media-actions_slider-vertical media-actions_slider-volume">
                            <Slider
                              getAriaValueText={(value) => `${value}`}
                              max={100}
                              min={0}
                              onChange={handleVideoVolume}
                              orientation="vertical"
                              step={5}
                              value={playerState.volume}
                              valueLabelDisplay="on"
                              valueLabelFormat={(value) => `${value}`}
                            />
                          </div>
                        </div>
                        <div className="media-actions_slider">
                          <Slider
                            getAriaValueText={(value) => formatDuration(value)}
                            max={playerState.duration}
                            min={0}
                            onChange={handleVideoProgress}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            step={0.1}
                            value={playerState.currentTime}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => formatDuration(value)}
                          />
                          <div className="media-duration">
                            <span>{formatDuration(playerState.currentTime)}</span>
                            <span>-{formatDuration(playerState.duration - playerState.currentTime)}</span>
                          </div>
                        </div>
                        <div
                          className={`media-actions_speed ${playerSliderSpeedState ? 'active' : ''}`}
                          onMouseDown={() => setPlayerSliderSpeedState(true)}
                        >
                          <Button className="media-actions_speedButton">
                            <SpeedIcon />
                          </Button>
                          <div className="media-actions_slider media-actions_slider-vertical">
                            <Slider
                              getAriaValueText={(index) => index + 'x'}
                              max={2}
                              min={0.5}
                              onChange={handleVideoSpeed}
                              orientation="vertical"
                              step={0.25}
                              value={playerState.speed}
                              valueLabelDisplay="on"
                              valueLabelFormat={(index) => index + 'x'}
                              marks
                            />
                          </div>
                        </div>
                      </div>
                    </CardActions>
                  )}
                </>
              ) : (
                <div className="card-multimedia-container">
                  <CardMedia
                    onLoad={(event) => {
                      const target = event.target as HTMLImageElement

                      imageSourceReference.current = target.src
                      setIsLoadingMainMedia(false)
                    }}
                    alt=""
                    className={`multimedia-content-img media-content-${!error ? '' : 'hidden'}`}
                    component="img"
                    src={currentMainEntity.mediaUrl}
                  />
                </div>
              )}
            </div>
          </Card>
          <div className="multimedia-preview">
            <MediaThumbnails
              currentMainEntity={currentMainEntity}
              error={error}
              isReloadingMainMedia={isReloadingMainMedia}
              onThumbnailClick={onThumbnailClick}
              orderedMediaList={orderedMediaList}
              setIsReloadingMainMedia={setIsReloadingMainMedia}
            />
          </div>
        </div>
      ) : (
        <div className="media-container">
          <Card
            sx={{
              backgroundColor:
                contentType?.toUpperCase() === ContentType.VIDEO && !isLoadingMainMedia && !error ? colors.backdropOverlay : colors.white,
              boxShadow: 0,
            }}
            className="media-container__card"
            onError={handleError}
          >
            {error && (
              <div className="media-fallback">
                <p className="media-fallback-text">Cannot load media</p>
                <Button className="media-fallback-button" onClick={handleReload}>
                  Reload
                </Button>
              </div>
            )}
            {isLoadingMainMedia && (
              <div className="media-fallback-loader">
                <CircularProgress />
              </div>
            )}
            {(contentType?.toUpperCase() === ContentType.IMAGE || contentType?.toUpperCase() === ContentType.AVATAR) && (
              <div className="card-media-container">
                <CardMedia
                  onLoad={(event) => {
                    const target = event.target as HTMLImageElement

                    imageSourceReference.current = target.src
                    setIsLoadingMainMedia(false)
                  }}
                  alt=""
                  className={`media-content-img media-content-${!error ? '' : 'hidden'}`}
                  component="img"
                  src={imageSourceReference.current}
                />
              </div>
            )}
            {contentType?.toUpperCase() === ContentType.VIDEO && (
              <CardActionArea disabled={isLoadingMainMedia} onClick={togglePlay}>
                <CardMedia
                  onCanPlayThrough={() => {
                    setIsWaitingMainMedia(false)
                    setIsLoadingMainMedia(false)
                  }}
                  onLoadedData={(event) => {
                    videoReference.current = event.target as HTMLVideoElement
                    setIsLoadingMainMedia(false)
                  }}
                  sx={{
                    maxWidth: `${isLoadingMainMedia ? mediaWidth + 'px' : '100%'}`,
                  }}
                  className="media-content-preview"
                  component="video"
                  onError={(event: TAny) => (videoReference.current = event.target)}
                  onTimeUpdate={handleOnTimeUpdate}
                  onWaiting={() => setIsWaitingMainMedia(true)}
                  ref={videoReference}
                  src={mediaUrl}
                />
              </CardActionArea>
            )}
            {contentType?.toUpperCase() === ContentType.VIDEO && !error && !isLoadingMainMedia && (
              <CardActions>
                <Button className="media-actions_play-buttons" onClick={togglePlay}>
                  {playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100 && <PauseFillIcon />}
                  {(playerState.progress === 0 ||
                    playerState.progress === 100 ||
                    (!playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100)) && <PlayFillIcon />}
                </Button>
                <div className="media-actions">
                  <div
                    className={`media-actions_volume ${playerSliderVolumeState ? 'active' : ''}`}
                    onMouseDown={() => setPlayerSliderVolumeState(true)}
                  >
                    <Button className="media-actions_volumeButton" onClick={toggleMute}>
                      {playerState.isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
                    </Button>
                    <div className="media-actions_slider media-actions_slider-vertical media-actions_slider-volume">
                      <Slider
                        getAriaValueText={(value) => `${value}`}
                        max={100}
                        min={0}
                        onChange={(event, value) => handleVideoVolume(event, value)}
                        orientation="vertical"
                        step={5}
                        value={playerState.volume}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => `${value}`}
                      />
                    </div>
                  </div>
                  <div className="media-actions_slider">
                    <Slider
                      getAriaValueText={(value) => formatDuration(value)}
                      max={playerState.duration}
                      min={0}
                      onChange={handleVideoProgress}
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      step={0.1}
                      value={playerState.currentTime}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => formatDuration(value)}
                    />
                    <div className="media-duration">
                      <span>{formatDuration(playerState.currentTime)}</span>
                      <span>-{formatDuration(playerState.duration - playerState.currentTime)}</span>
                    </div>
                  </div>
                  <div
                    className={`media-actions_speed ${playerSliderSpeedState ? 'active' : ''}`}
                    onMouseDown={() => setPlayerSliderSpeedState(true)}
                  >
                    <Button className="media-actions_speedButton">
                      <SpeedIcon />
                    </Button>
                    <div className="media-actions_slider media-actions_slider-vertical">
                      <Slider
                        getAriaValueText={(index) => index + 'x'}
                        max={2}
                        min={0.5}
                        onChange={handleVideoSpeed}
                        orientation="vertical"
                        step={0.25}
                        value={playerState.speed}
                        valueLabelDisplay="on"
                        valueLabelFormat={(index) => index + 'x'}
                        marks
                      />
                    </div>
                  </div>
                </div>
              </CardActions>
            )}
          </Card>
        </div>
      )}
    </div>
  )
}

export default MediaPreviewDrawer
