import './index.scss'

import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, SelectChangeEvent } from '@mui/material'
import { enqueueSnackbar, VariantType } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

import Alert from '@admin/components/shared/Alert/Alert'
import Autocomplete from '@admin/components/shared/Autocomplete/Autocomplete'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Select, { ISelectOptions } from '@admin/components/shared/Select/Select'
import TextField from '@admin/components/shared/TextField/TextField'
import getValidationSchema from '@admin/components/WorldManagement/Cohorts/CohortsModal/utils/getValidationSchema'
import { fetchEditCohort, ICohort, TCohortDetails } from '@admin/store/cohortDetailsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { CommonErrorMessages, WorldText } from '@admin/store/types/ErrorMessages'
import { fetchAddCohort, fetchAllCohorts, fetchLocations, selectErrorText, selectLocations } from '@admin/store/worldManagementSlice'
import { IGender, ILocation } from '@admin/types/commonTypes'

interface IProps {
  action: 'add' | 'edit'
  genders: IGender[]
  item: null | TCohortDetails
  onClose(): void
}

const CohortsModal = ({ action, genders, item, onClose }: IProps) => {
  const [title, setTitle] = useState('Add new cohort')
  const [showMessage, setShowMessage] = useState<{ show: boolean; type?: 'error' | 'info' }>({
    show: false,
  })
  const [minAgeValue, setMinAgeValue] = useState<null | string>(item?.minAge?.toString() ?? '')
  const [maxAgeValue, setMaxAgeValue] = useState<null | string>(item?.maxAge?.toString() ?? '')
  const [selectedGenders, setSelectedGenders] = useState<string[]>(item?.genders?.map(({ name }) => name) ?? [])
  const [selectedLocations, setSelectedLocations] = useState<ILocation[]>(item?.locations ?? [])
  const [alertMessage, setAlertMessage] = useState({ errorMessage: 'Fill at least one attribute field', isError: false })
  const [showFieldError, setShowFieldError] = useState({ isShow: false, message: '' })
  const schema = useMemo(() => getValidationSchema(), [])

  const {
    control,
    formState: { isValid, errors, isDirty },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      id: item?.id ?? '',
      title: item?.title ?? '',
      genders: item?.genders ?? null,
      locations: item?.locations ?? null,
      maxAge: item?.maxAge ?? null,
      minAge: item?.minAge ?? null,
    },
    resolver: yupResolver(schema),
  })

  const locations = useAppSelector(selectLocations)
  const statusText = useAppSelector(selectErrorText)

  const dispatch = useAppDispatch()

  const arrayOptions = useMemo(() => {
    const array = []

    for (let index = 13; index < 151; index++) {
      array.push({ id: index, label: index.toString() })
    }

    return array
  }, [])

  const handleChangeGender = useCallback((event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    const newValue = typeof value === 'string' ? value.split(',') : (value as string[])

    setSelectedGenders(newValue)
  }, [])

  const handleDeleteGender = useCallback(
    (event: MouseEvent, deleted: string) => {
      const newValue = selectedGenders.filter((selected) => selected !== deleted)

      setSelectedGenders(newValue)
    },
    [selectedGenders],
  )

  const handleChangeLocation = async (event: SyntheticEvent, value: string) => {
    value.length > 2 && (await dispatch(fetchLocations({ text: value })))
  }

  const triggerAttributeFields = useCallback((checkErrors = false): Promise<void> | void => {
    if (checkErrors) {
      return Promise.all([trigger('genders'), trigger('locations'), trigger('minAge'), trigger('maxAge')]).then((results) => {
        const isValidResult = results.some((result) => !!result)

        setAlertMessage({ errorMessage: 'Fill at least one attribute field', isError: !isValidResult })
        setShowMessage({ show: !isValidResult || action === 'add', type: isValidResult ? 'info' : 'error' })
      })
    } else {
      setAlertMessage({ errorMessage: 'Fill at least one attribute field', isError: false })
      setShowMessage({ show: action === 'add', type: 'info' })
      trigger('genders')
      trigger('locations')
      trigger('minAge')
      trigger('maxAge')
    }
  }, [])

  const onSubmit = async (data: Omit<ICohort, 'createdAt' | 'creatorLastName' | 'creatorName' | 'status'>) => {
    trigger()

    try {
      const result = action === 'add' ? await dispatch(fetchAddCohort(data)) : await dispatch(fetchEditCohort(data))

      if (result.meta.requestStatus === 'fulfilled') {
        onClose()
        enqueueSnackbar(`“${data.title}” cohort ${action === 'add' ? 'has been created' : 'was changed'}`, { variant: 'success' as VariantType })
        action === 'add' && void dispatch(fetchAllCohorts())
      }
    } catch {
      setAlertMessage({
        errorMessage: `Cohort ${action === 'add' ? 'adding' : 'saving'} error, try again later`,
        isError: true,
      })
      setShowMessage({ show: true, type: 'error' })
    }
  }

  useEffect(() => {
    if (action === 'edit' && item) {
      setTitle(`Edit “${item.title}” cohort`)
      setShowMessage(alertMessage.isError ? { show: true, type: 'error' } : { show: false })
    } else {
      setTitle('Add new cohort')
      setShowMessage({ show: true, type: alertMessage.isError ? 'error' : 'info' })
    }
  }, [item, action])

  useEffect(() => {
    const newGendersArray: IGender[] | null = selectedGenders.length
      ? selectedGenders.map((value) => genders.filter((gender) => gender.name === value)[0])
      : null

    setValue('genders', newGendersArray, { shouldDirty: true })
    triggerAttributeFields()
  }, [selectedGenders])

  useEffect(() => {
    setValue('locations', selectedLocations, { shouldDirty: true })
    triggerAttributeFields()
  }, [selectedLocations])

  useEffect(() => {
    switch (statusText) {
      case CommonErrorMessages.UNEXPECTED_ERROR:
        setAlertMessage({
          errorMessage: `Cohort ${action === 'add' ? 'adding' : 'saving'} error, try again later`,
          isError: true,
        })
        setShowMessage({ show: true, type: 'error' })
        break
      case WorldText.DUPLICATE_TITLE:
        setShowFieldError({ isShow: true, message: 'Cohort with the same name already exists ' })
        break
      default:
        setShowFieldError({ isShow: false, message: '' })
    }
  }, [statusText])

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <form className="cohortsModal" onSubmit={handleSubmit(onSubmit)}>
      <div className="cohortsModal-header">{title}</div>
      <div className="cohortsModal-content">
        <div className="cohortsModal-content-group">
          <TextField
            {...register('title')}
            fullWidth={true}
            label="Name*"
            name="title"
            defaultValue={item?.title ?? ''}
            error={!!errors.title || showFieldError.isShow}
            onBlur={() => trigger('title')}
            size="small"
          />
          {(errors.title || showFieldError.isShow) && (
            <FormHelperText error>{showFieldError.isShow ? showFieldError.message : (errors.title?.message as string)}</FormHelperText>
          )}
        </div>
        <div className="cohortsModal-content-group">
          <div>
            <div className="cohortsModal-content-name">Age</div>
            <Controller
              name="minAge"
              render={() => (
                <Autocomplete
                  label="From"
                  inputprops={{
                    error:
                      (!!errors.minAge && errors.minAge.type !== 'oneOrMore') ||
                      (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError),
                  }}
                  onChange={(event, value) => {
                    setValue('minAge', value ? parseInt(value) : null, { shouldDirty: true })
                    setMinAgeValue(value)
                    triggerAttributeFields()
                  }}
                  onInputChange={(event, value: null | string) => {
                    setValue('minAge', value ? parseInt(value) : null, { shouldDirty: true })
                    setMinAgeValue(value)
                  }}
                  clearIcon={null}
                  getOptionLabel={(option) => option || ''}
                  inputValue={minAgeValue ?? ''}
                  isOptionEqualToValue={(option, value) => option === value}
                  onBlur={() => triggerAttributeFields()}
                  options={arrayOptions.map(({ label }) => label)}
                  size="small"
                  sx={{ width: '190px', display: 'inline-block' }}
                  value={minAgeValue ?? ''}
                  disabled
                />
              )}
              control={control}
            />
            <Controller
              name="maxAge"
              render={() => (
                <Autocomplete
                  label="to"
                  inputprops={{
                    error:
                      (!!errors.maxAge && errors.maxAge.type !== 'oneOrMore') ||
                      (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError),
                  }}
                  onChange={(event, value) => {
                    setValue('maxAge', value ? parseInt(value) : null, { shouldDirty: true })
                    setMaxAgeValue(value)
                    triggerAttributeFields()
                  }}
                  onInputChange={(event, value: null | string) => {
                    setValue('maxAge', value ? parseInt(value) : null, { shouldDirty: true })
                    setMaxAgeValue(value)
                  }}
                  clearIcon={null}
                  getOptionLabel={(option) => option || ''}
                  inputValue={maxAgeValue ?? ''}
                  isOptionEqualToValue={(option, value) => option === value}
                  onBlur={() => triggerAttributeFields()}
                  options={arrayOptions.map(({ label }) => label)}
                  size="small"
                  sx={{ width: '190px', display: 'inline-block' }}
                  value={maxAgeValue ?? ''}
                />
              )}
              control={control}
            />
          </div>
          {errors.minAge && <FormHelperText error>{errors.minAge?.message as string}</FormHelperText>}
        </div>
        <div className="cohortsModal-content-group">
          <div className="cohortsModal-content-name">Gender</div>
          <Select
            fullWidth
            name="genders"
            error={
              (!!errors.genders && errors.genders.type !== 'oneOrMore') ||
              (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError)
            }
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, overflow: 'hidden' }}>
                {(selected as string[]).length === 0 ? (
                  <span className="placeholder">Choose gender</span>
                ) : (
                  (selected as string[]).map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable={true}
                      onDelete={(event) => handleDeleteGender(event, value)}
                      onMouseDown={(event) => event.stopPropagation()}
                      variant="filled"
                    />
                  ))
                )}
              </Box>
            )}
            multiple={true}
            onChange={handleChangeGender}
            options={genders.map(({ name }) => ({ text: name, value: name }) as ISelectOptions)}
            size="small"
            value={selectedGenders}
            variant="outlined"
            displayEmpty
          />
          {errors.genders && <FormHelperText error>{errors.genders.message as string}</FormHelperText>}
        </div>
        <div className="cohortsModal-content-group">
          <div className="cohortsModal-content-name">Location</div>
          <Controller
            name="locations"
            render={() => (
              <Autocomplete
                fullWidth={true}
                inputprops={{
                  error:
                    (!!errors.locations && errors.locations.type !== 'oneOrMore') ||
                    (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError),
                  placeholder: selectedLocations.length === 0 ? 'Choose location' : '',
                }}
                onChange={(event, value) => {
                  triggerAttributeFields()
                  setSelectedLocations(value)
                }}
                className="autocompleteLocations"
                clearIcon={null}
                getOptionLabel={(option) => option.fullAddress || ''}
                isOptionEqualToValue={(option, value) => option.fullAddress === value.fullAddress}
                multiple={true}
                onBlur={() => triggerAttributeFields()}
                onInputChange={handleChangeLocation}
                options={locations || []}
                size="small"
                value={selectedLocations}
              />
            )}
            control={control}
          />
        </div>
      </div>
      <div className="cohortsModal-footer">
        {showMessage.show && <Alert severity={showMessage.type}>{alertMessage?.errorMessage}</Alert>}
        <div className="cohortsModal-footer-buttons">
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            disabled={!isValid || (!isDirty && action === 'edit')}
            loading={false}
            onClick={() => triggerAttributeFields(true)}
            type="submit"
            variant="contained"
          >
            {action === 'add' ? 'Add' : 'Save'}
          </LoadingButton>
        </div>
      </div>
    </form>
  )
}

export default CohortsModal
