import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import dayjs from 'dayjs'

import ContentDisplayTabs from '@admin/components/ContentDisplay/types/ContentDisplayTabs'
import ModerationTabs from '@admin/components/Moderation/types/ModerationTabs'
import { CONTENT_DISPLAY_API, MEMBERS_API, MODERATION_API, OPERATIONS_API, PAYMENTS_API, TAXES_API, USERS_API } from '@admin/shared/api/constants'
import { IFilterItem, TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { TRootState } from './store'

export interface IFiltersState {
  activePresetId: string
  currentData: TAny
  data: TAny
  filters: TAny[]
  possibleOptions: TAny
  presets: TAny[]
  sortedFilters: TAny[]
  status: TStatus
}

const initialState: IFiltersState = {
  activePresetId: '',
  currentData: {},
  data: {},
  filters: [],
  possibleOptions: {},
  presets: [],
  sortedFilters: [],
  status: 'idle',
}

export const fetchFilterPresets = createAsyncThunk('filters/fetchPresets', async (type: string) => {
  const presetsUrl = USERS_API + 'timusers/presets?presetType=FILTERING'

  const response = await axios.get(presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { presets: response.data.presets }
})

export const setActivePreset = createAsyncThunk('filters/setActivePreset', async ({ presetId, type }: { presetId: string; type: string }) => {
  const presetUrl = USERS_API + `timusers/presets/filters/${presetId}`

  const response = await axios.get(presetUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { filters: response.data.filters, presetId: presetId }
})

export const savePreset = createAsyncThunk('filters/savePreset', async ({ presetName, type }: { presetName: string; type: string }, thunkAPI) => {
  const rootState: TRootState = (await thunkAPI.getState()) as TRootState
  const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
  const presetsUrl = USERS_API + 'timusers/presets?presetType=FILTERING'

  const response = await axios.post(
    presetsUrl,
    {
      filters: filters,
      presetName: presetName,
      presetType: 'FILTERING',
    },
    {
      headers: {
        'Member-Id': '0',
      },
    },
  )

  return { presets: response.data.presets }
})

export const deletePreset = createAsyncThunk('filters/deletePreset', async ({ presetId, type }: { presetId: string; type: string }) => {
  const presetsUrl = USERS_API + `timusers/presets/${presetId}`

  const response = await axios.delete(presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { presets: response.data.presets }
})

export const fetchFilters = createAsyncThunk('filters/fetchFilters', async (type: string) => {
  let filtersUrl

  switch (type) {
    case ContentDisplayTabs.EVENTS:
    case ContentDisplayTabs.LIFESTYLES:
    case ContentDisplayTabs.OFFERS:
    case ContentDisplayTabs.WISHES: {
      filtersUrl = CONTENT_DISPLAY_API + `content/screen/${type}/filters`
      break
    }
    case 'fees': {
      filtersUrl = PAYMENTS_API + 'screen/filters?belongsToKind=fees_find'
      break
    }
    case 'members': {
      filtersUrl = MEMBERS_API + 'members/screen/filters'
      break
    }
    case ModerationTabs.CONFIRMED_VIOLATIONS:
    case ModerationTabs.DECLINED_REQUESTS: {
      filtersUrl = MODERATION_API + `moderation/screen/history/filters`
      break
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      filtersUrl = MODERATION_API + `moderation/screen/content/filters`
      break
    }
    case ModerationTabs.HIVE: {
      filtersUrl = MODERATION_API + `moderation/screen/hive/filters`
      break
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      filtersUrl = MODERATION_API + `moderation/screen/profile/filters`
      break
    }
    case 'operations': {
      filtersUrl = OPERATIONS_API + 'screen/filters?belongsToKinds=operation_find'
      break
    }
    case 'tax-moderation': {
      filtersUrl = TAXES_API + 'sales/tax/screen/filters'
      break
    }
    case 'tiers': {
      filtersUrl = PAYMENTS_API + 'screen/filters?belongsToKind=tier_find'
      break
    }
    case 'users':
    default: {
      filtersUrl = USERS_API + 'timusers/presets/filters'
    }
  }
  const response = await axios.get(filtersUrl, {
    // headers: {
    //   'Member-Id': '0'
    // }
  })

  return { filters: response.data.filters, type: type }
})

export const fetchPossibleOptions = createAsyncThunk(
  'filters/fetchPossibleOptions',
  async ({ additionalValue, field, type, value }: { additionalValue?: string; field: string; type: string; value?: string }) => {
    let optionsUrl

    switch (type) {
      case 'content-display': {
        optionsUrl = CONTENT_DISPLAY_API + 'content/screen/filters/dropdown'
        break
      }
      case 'fees': {
        optionsUrl = PAYMENTS_API + 'payment-adapter/fees/screen/filters/dropdown'
        break
      }
      case 'members':
        optionsUrl = MEMBERS_API + 'members/screen/filters/dropdown'
        break
      case ModerationTabs.CONFIRMED_VIOLATIONS:
      case ModerationTabs.CONTENT_COMPLAINTS:
      case ModerationTabs.DECLINED_REQUESTS:
      case ModerationTabs.HIVE:
      case ModerationTabs.PROFILE_COMPLAINTS: {
        optionsUrl = MODERATION_API + `moderation/screen/filters/dropdown`
        break
      }
      case 'operations': {
        optionsUrl = OPERATIONS_API + 'screen/filters/dropdown?belongsToKinds=operation_find'
        break
      }
      case 'tax-moderation': {
        optionsUrl = TAXES_API + 'sales/tax/screen/filters/dropdown'
        break
      }
      case 'tiers': {
        optionsUrl = PAYMENTS_API + 'payment-adapter/tiers/screen/filters/dropdown'
        break
      }
      case 'users':
      default: {
        optionsUrl = USERS_API + 'timusers/filters/dropdown'
      }
    }
    const response = await axios.get(optionsUrl, {
      headers: {
        'Profile-Id': '0',
      },
      params: {
        additionalValue: additionalValue,
        columnId: field,
        value: value,
      },
    })

    return {
      field: field,
      options: response.data.values.map((item: TAny) => ({ id: item.value, titles: item.titles })),
    }
  },
)

export const filtersSlice = createSlice({
  name: 'filters',
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state) => {
        state.status = 'loading'
        state.data = {}
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.status = 'idle'
        state.filters = action.payload.filters
      })
      .addCase(fetchFilters.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchFilterPresets.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchFilterPresets.fulfilled, (state, action) => {
        state.status = 'idle'
        state.presets = action.payload.presets
      })
      .addCase(fetchFilterPresets.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(setActivePreset.fulfilled, (state, action) => {
        const newData: TAny = {}

        action.payload.filters.forEach((item: TAny) => {
          newData[item.columnId] = item
        })
        state.data = newData
        state.activePresetId = action.payload.presetId
      })
      .addCase(fetchPossibleOptions.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPossibleOptions.fulfilled, (state, action) => {
        state.status = 'idle'
        const currentField = action.payload.field

        state.possibleOptions[currentField] = action.payload.options
      })
      .addCase(fetchPossibleOptions.rejected, (state) => {
        state.status = 'failed'
      })
  },
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.data = {}
      state.currentData = {}
      state.possibleOptions = {}
    },
    clearPossibleOptions: (state, action) => {
      state.possibleOptions[action.payload.columnId] = []
    },
    removeFilter: (state, action) => {
      state.data[action.payload.columnId] = null
      state.currentData[action.payload.columnId] = null
      state.possibleOptions[action.payload.columnId] = []
    },
    reverseFilters: (state) => {
      state.currentData = state.data
      state.possibleOptions = {}
    },
    saveFilters: (state) => {
      state.data = {
        ...state.currentData,
      }
    },
    setActiveFilter(state, action) {
      let item = action.payload.item

      if ((item.valueType === 'DATE' || item.displayValueType === 'DATE') && item.type === 'BETWEEN') {
        item = {
          ...item,
          from: dayjs().format('YYYY-MM-DD'),
          to: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        }
      }

      if (action.payload.checked) {
        state.currentData[action.payload.item.columnId] = item
      } else {
        delete state.currentData[action.payload.item.columnId]
      }

      if (!action.payload.checked) {
        state.possibleOptions[action.payload.item.columnId] = []
      }
    },
    setCustomFilterValue: (state, action) => {
      state.data[action.payload.columnId] = {
        ...action.payload,
      }
      state.currentData[action.payload.columnId] = {
        ...action.payload,
      }
    },
    setFilterValue: (state, action) => {
      state.currentData[action.payload.columnId][action.payload.valueType] = Array.isArray(action.payload.value)
        ? action.payload.value.map((item: TAny) => ({
            titles: item.titles,
            value: item.value ?? item.id,
          }))
        : action.payload.value
    },
    sortFilters: (state, action) => {
      state.sortedFilters = state.filters.filter((filter: IFilterItem) => filter.belongsToKinds?.includes(action.payload as string))
    },
    sortFiltersByDependsOnValue: (state, action) => {
      state.sortedFilters = state.filters.filter((filter: IFilterItem) => filter.dependsOn?.value?.includes(action.payload as string))
    },
  },
})

export const {
  clearFilters,
  clearPossibleOptions,
  removeFilter,
  reverseFilters,
  saveFilters,
  setActiveFilter,
  setCustomFilterValue,
  setFilterValue,
  sortFilters,
  sortFiltersByDependsOnValue,
} = filtersSlice.actions

export const selectFiltersStatus = (state: TRootState): TStatus => state.filters.status
export const selectPossibleOptions = (state: TRootState): TAny => state.filters.possibleOptions
export const selectFiltersCurrentData = (state: TRootState): TAny => state.filters.currentData
export const selectFilters = (state: TRootState): TAny[] => state.filters.filters
export const selectPresets = (state: TRootState): TAny[] => state.filters.presets
export const selectActivePresetId = (state: TRootState): string => state.filters.activePresetId
export const selectSortedFilters = (state: TRootState): TAny[] => state.filters.sortedFilters
export const selectFiltersData = (state: TRootState): TAny[] => state.filters.data

export default filtersSlice.reducer
