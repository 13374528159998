import { RefObject, useCallback, useEffect, useMemo, useState } from 'react'

import { LocalStorageKeys } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

const useVideoPlayer = (videoElement: RefObject<HTMLVideoElement | null>, isLoadingMainMedia: boolean) => {
  const playerSettings = useMemo(() => {
    return {
      mutedVolume: localStorage.getItem(LocalStorageKeys.MUTED_VOLUME),
      speed: localStorage.getItem(LocalStorageKeys.SPEED),
      volume: localStorage.getItem(LocalStorageKeys.VOLUME),
    }
  }, [])

  const [playerState, setPlayerState] = useState({
    currentTime: 0,
    duration: 0,
    isMuted: playerSettings.volume ? Number(playerSettings.volume) === 0 : false,
    isPlaying: false,
    mutedVolume: playerSettings.volume ? Number(playerSettings.mutedVolume) : 0,
    progress: 0,
    speed: playerSettings.speed ? Number(playerSettings.speed) : 1,
    volume: playerSettings.volume ? Number(playerSettings.volume) : 50,
  })

  const togglePlay = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.muted = playerState.isMuted

      if (playerState.isPlaying) {
        if (playerState.progress !== 100) {
          videoElement.current?.pause()
        } else {
          videoElement.current?.play()
        }
      }

      setPlayerState((previous) => ({
        ...previous,
        isPlaying: playerState.progress === 100 ? true : !playerState.isPlaying,
      }))
    }
  }, [playerState, videoElement])

  const toggleMute = useCallback(() => {
    if (videoElement.current) {
      const volume = !playerState.isMuted ? 0 : Number(playerState.mutedVolume)
      let newPlayerSettings: TAny = {
        isMuted: !playerState.isMuted,
        volume: volume,
      }

      if (!playerState.isMuted) {
        newPlayerSettings = {
          ...newPlayerSettings,
          mutedVolume: Number(playerState.volume),
        }
      }
      videoElement.current.muted = !playerState.isMuted
      videoElement.current.volume = volume / 100
      setPlayerState((previousState) => {
        return {
          ...previousState,
          ...newPlayerSettings,
        }
      })
    }
  }, [playerState.isMuted, playerState.mutedVolume, playerState.volume, videoElement])

  const handleOnTimeUpdate = useCallback(() => {
    if (videoElement.current) {
      const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100

      setPlayerState((previous) => ({
        ...previous,
        progress,
      }))
    }
  }, [videoElement])

  const handleVideoProgress = useCallback(
    (event: TAny, value: TAny) => {
      if (videoElement.current) {
        const manualChange = Number(value)

        videoElement.current.currentTime = manualChange
        setPlayerState((previous) => ({
          ...previous,
          isPlaying: false,
          progress: manualChange,
        }))
      }
    },
    [videoElement],
  )

  const handleVideoSpeed = useCallback(
    (event: TAny, value: TAny) => {
      if (videoElement.current) {
        const speed = Number(value)

        videoElement.current.playbackRate = speed
        setPlayerState((previous) => ({
          ...previous,
          speed,
        }))
      }
    },
    [videoElement],
  )

  const handleVideoVolume = useCallback(
    (event: TAny, value: TAny) => {
      if (videoElement.current) {
        const manualChange = Number(value)

        videoElement.current.volume = manualChange / 100
        videoElement.current.muted = manualChange === 0
        setPlayerState((previous) => ({
          ...previous,
          isMuted: manualChange === 0,
          volume: manualChange,
        }))
      }
    },
    [videoElement],
  )

  const handleReloadVideo = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.load()
    }
  }, [videoElement])

  const handleChangeCurrentTime = useCallback(() => {
    setPlayerState((previous) => ({
      ...previous,
      currentTime: videoElement.current?.currentTime ?? 0,
    }))
  }, [videoElement])

  const handleSetDuration = useCallback(() => {
    setPlayerState((previous) => ({
      ...previous,
      duration: videoElement.current?.duration ?? 0,
    }))
  }, [videoElement])

  useEffect(() => {
    const video = videoElement.current

    if (video) {
      video.addEventListener('timeupdate', handleChangeCurrentTime)
      video.addEventListener('loadeddata', handleSetDuration)
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleChangeCurrentTime)
        video.removeEventListener('loadeddata', handleSetDuration)
      }
    }
  }, [handleChangeCurrentTime, handleSetDuration, videoElement, isLoadingMainMedia])

  useEffect(() => {
    const video = videoElement.current

    if (video) {
      video.playbackRate = playerState.speed
      video.volume = playerState.volume / 100
    }
  }, [videoElement, playerState.speed, playerState.volume, isLoadingMainMedia])

  useEffect(() => {
    if (playerState.isPlaying) {
      videoElement.current?.play()
    } else {
      videoElement.current?.pause()
    }
  }, [playerState.isPlaying, videoElement, isLoadingMainMedia])

  useEffect(() => {
    return () => {
      localStorage.setItem(LocalStorageKeys.SPEED, playerState.speed.toString())
      localStorage.setItem(LocalStorageKeys.VOLUME, playerState.volume.toString())
      localStorage.setItem(LocalStorageKeys.MUTED_VOLUME, playerState.mutedVolume.toString())
    }
  }, [playerState.mutedVolume, playerState.speed, playerState.volume])

  return {
    handleReloadVideo,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoVolume,
    handleOnTimeUpdate,
    playerState,
    setPlayerState,
    toggleMute,
    togglePlay,
  }
}

export default useVideoPlayer
