import { MouseEvent, useCallback, useEffect, useState } from 'react'

import { Avatar as AvatarMUI } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useParams } from 'react-router-dom'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import CopyIcon from '@admin/assets/img/CopyIcon'
import SystemIcon from '@admin/assets/img/SystemIcon'
import Avatar from '@admin/components/shared/Avatar/Avatar'
import Box from '@admin/components/shared/Box/Box'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LinearProgress from '@admin/components/shared/LinearProgress/LinearProgress'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  IOperation,
  OperationActions,
  OperationStatuses,
  OperationTypes,
  TransactionStatuses,
  TransactionTypes,
} from '@admin/store/slices/Payments/interface'
import {
  fetchOperationDetails,
  selectCurrentOperationAction,
  selectOperationActionStatus,
  selectOperationDetails,
  selectOperationDetailsStatus,
} from '@admin/store/slices/Payments/operationsSlice'
import { fetchRefundReasons, selectRefundReasons } from '@admin/store/slices/Settings/dictionariesSlice'
import { fetchUserProfile, selectUserProfile } from '@admin/store/userProfileSlice'
import colors from '@admin/theme/constants/colors'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import { MemberProfilePopover, ProfilePopover, RefundModal, SuspensionConfirmModal } from '../modals'
import TransactionsTable from './TransactionsTable/TransactionsTable'

export const CustomDetailPanelContent = ({ onDataRefresh, row: operationData }: { onDataRefresh?: () => void; row: IOperation }) => {
  const { id: requestId } = useParams()
  const dispatch = useAppDispatch()
  const profileInfo = useAppSelector(selectUserProfile)
  const operationDetails = useAppSelector(selectOperationDetails)
  const operationDetailsStatus = useAppSelector(selectOperationDetailsStatus)
  const operationActionStatus = useAppSelector(selectOperationActionStatus)
  const currentOperationAction = useAppSelector(selectCurrentOperationAction)
  const userPermissions = useAppSelector(selectUserPermissions)
  const refundReasons = useAppSelector(selectRefundReasons)

  const isOperationDetailsLoading = operationDetailsStatus === 'loading'
  const isOperationActionLoading = operationActionStatus === 'loading'

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [memberInfoAnchorElement, setMemberInfoAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [controlledOperationAction, setControlledOperationAction] = useState<null | OperationActions>(null)

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [currentProfileId, setCurrentProfileId] = useState('')
  const [isEntityInfoExpanded, setIsEntityInfoExpanded] = useState<boolean>(false)
  const [isPaymentInfoExpanded, setIsPaymentInfoExpanded] = useState<boolean>(false)
  const [buttonLoadingState, setButtonLoadingState] = useState<{
    [profileId: string]: boolean
  }>({})
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false)
  const [showSuspensionModal, setShowSuspensionModal] = useState<boolean>(false)

  const isInAppOperation =
    operationData.operationType === OperationTypes.IAP_FOLLOW ||
    operationData.operationType === OperationTypes.IAP_DONATE ||
    operationData.operationType === OperationTypes.IAP_EVENT ||
    operationData.operationType === OperationTypes.IAP_LIFESTYLE
  const isBoostOperation = operationData.operationType === OperationTypes.BOOST
  const isChargeOperation = operationData.operationType === OperationTypes.CHARGE
  const isPayoutOperation = operationData.operationType === OperationTypes.PAY_OUT
  const isOperationFailed = operationData.status === OperationStatuses.FAILED
  const isOperationInTransit = operationData.status === OperationStatuses.IN_TRANSIT
  const isOperationPending = operationData.status === OperationStatuses.PENDING
  const isOperationRefunded = operationData.status === OperationStatuses.REFUNDED
  const isOperationRequested = operationData.status === OperationStatuses.REQUESTED
  const isOperationStarted = operationData.status === OperationStatuses.START
  const isOperationSuspended = operationData.isTransferSuspended

  const isRefundTransactionSuccess = operationDetails?.transactions.some((transaction) => {
    return (
      transaction.status === TransactionStatuses.CANCELED ||
      (transaction.type === TransactionTypes.REFUND && transaction.status === TransactionStatuses.SUCCESS)
    )
  })
  const isRefundTransactionProceed = operationDetails?.transactions.some((transaction) => {
    return transaction.status === TransactionStatuses.CANCELED || transaction.type === TransactionTypes.REFUND
  })
  const isValidOperationDetails = operationDetails?.operation.id === operationData.id || operationDetailsStatus !== 'failed'
  const isContainsAppleTransaction = operationDetails?.transactions.some((transaction) => {
    return transaction.type === TransactionTypes.APPLE_IAP
  })

  const isContainsValidTransferTransaction = operationDetails?.transactions.some((transaction) => {
    const invalidStatuses = [TransactionStatuses.CANCELED, TransactionStatuses.FAILED, TransactionStatuses.REFUNDED]

    return transaction.type === TransactionTypes.TRANSFER && !invalidStatuses.includes(transaction.status)
  })
  const isEmptyOperationHistory = isEmpty(operationDetails?.lastAdminOperationHistory)

  const isAllowedRefundOrSuspend = !isPayoutOperation && !isBoostOperation && !isChargeOperation
  const idDisabledUnblockButton = !!requestId || isEmptyOperationHistory
  const isShowRefundButton = !(
    isOperationFailed ||
    isOperationStarted ||
    isOperationPending ||
    isOperationInTransit ||
    isOperationRequested ||
    isContainsAppleTransaction
  )

  const isShowSuspendButton = !isOperationSuspended && !isOperationRefunded && isContainsValidTransferTransaction
  const isShowUnblockButton = isOperationSuspended && !isOperationRefunded && isContainsValidTransferTransaction

  const handleLoadProfileInfo = async (profileId: string) => {
    setButtonLoadingState((previousLoadingStates) => ({
      ...previousLoadingStates,
      [profileId]: true,
    }))
    setCurrentProfileId(profileId)

    const result = await dispatch(fetchUserProfile(profileId))

    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Failed to load the profile, try again later', {
        variant: 'error' as VariantType,
      })
    }

    setButtonLoadingState((previousLoadingStates) => ({
      ...previousLoadingStates,
      [profileId]: false,
    }))
  }

  const copyId = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation()

    if (!value) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      })

      return
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      })

      return navigator.clipboard.writeText(value)
    }
  }

  const initialFetchData = useCallback(async () => {
    const result = await dispatch(fetchOperationDetails(operationData.id))

    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Failed to load operation details, try again later', {
        variant: 'error' as VariantType,
      })
    }
  }, [dispatch, operationData.id])

  useEffect(() => {
    initialFetchData()
  }, [operationData.id])

  useEffect(() => {
    dispatch(fetchUserProfile(operationData.profileId!))
  }, [dispatch, operationData.profileId])

  useEffect(() => {
    dispatch(fetchRefundReasons())
  }, [dispatch])

  return (
    <Stack alignContent="center" direction="column" gap={1}>
      {isOperationDetailsLoading && <LinearProgress sx={{ left: 0, position: 'absolute', right: 0, top: 0 }} variant="query" />}
      <Stack width={1} direction="row" sx={{ border: `1px solid ${colors.divider}`, borderRadius: 2 }}>
        <Box component="div" pl={1} pt={1}>
          <IconButton
            aria-label={isEntityInfoExpanded ? 'Close' : 'Open'}
            onClick={() => setIsEntityInfoExpanded(!isEntityInfoExpanded)}
            size="small"
          >
            <ChevronDownIcon
              sx={{
                fontSize: 32,
                transform: `rotateZ(${isEntityInfoExpanded ? 180 : 0}deg)`,
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
            />
          </IconButton>
        </Box>

        <Stack width={1} direction="column" sx={{ pr: '50px' }}>
          <Stack alignItems="start" direction="row">
            <ListItem sx={{ maxWidth: '50%' }}>
              <ListItemText
                primary={
                  <Typography color="text.secondary" variant="body2">
                    Subject of payment
                  </Typography>
                }
                secondary={
                  <Typography color="text.primary" variant="subtitle1">
                    {operationData.entityTitle || '—'}
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
            <Stack width={1} alignItems="start" direction="row" sx={{ maxWidth: '50%', pl: 2 }}>
              <ListItem sx={{ px: 0 }}>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      ID
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="subtitle1">
                      {operationData.entityId || '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
              <ListItem sx={{ width: '20%', ml: 4, px: 0 }}>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Version
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="subtitle1">
                      {operationData.entityVersion || '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
            </Stack>
          </Stack>
          {isEntityInfoExpanded && (
            <Stack width={1} alignItems="start" direction="row">
              <ListItem sx={{ maxWidth: '50%' }}>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Description
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="subtitle1">
                      {operationData.entityDescription || '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
              <ListItem sx={{ maxWidth: '50%' }}>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Entity execution ID
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                      }}
                      color="text.primary"
                      variant="subtitle1"
                    >
                      {operationData.entityExecutionId || '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          border: `1px solid ${colors.divider}`,
          borderRadius: 2,
          position: 'relative',
        }}
      >
        <Stack direction="row">
          <Box component="div" pl={1} pt={1}>
            <IconButton
              aria-label={isPaymentInfoExpanded ? 'Close' : 'Open'}
              onClick={() => setIsPaymentInfoExpanded(!isPaymentInfoExpanded)}
              size="small"
            >
              <ChevronDownIcon
                sx={{
                  fontSize: 32,
                  transform: `rotateZ(${isPaymentInfoExpanded ? 180 : 0}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
              />
            </IconButton>
          </Box>

          <ProfilePopover
            anchorEl={anchorElement}
            coordinates={coordinates}
            copyId={copyId}
            onClose={() => setAnchorElement(null)}
            profileId={currentProfileId}
            profileInfo={profileInfo}
          />

          {isValidOperationDetails && (
            <MemberProfilePopover
              action={controlledOperationAction}
              anchorEl={memberInfoAnchorElement}
              coordinates={coordinates}
              lastAdminOperationHistory={operationDetails?.lastAdminOperationHistory}
              onClose={() => setMemberInfoAnchorElement(null)}
            />
          )}

          <Stack direction="column" flex={1}>
            <Stack alignItems="start" direction="row" gap={2}>
              <Stack>
                <Stack alignItems="center" direction="row" flex="auto" gap={1}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography color="text.secondary" variant="body2">
                          Payer profile ID
                        </Typography>
                      }
                      secondary={
                        <>
                          <Tooltip title={operationData.profileId} placement="top-end" followCursor>
                            <Typography color="text.primary" variant="subtitle1">
                              {operationData.profileId || '—'}
                            </Typography>
                          </Tooltip>
                          <LoadingButton
                            onClick={(event) => {
                              handleLoadProfileInfo(operationData.profileId)
                              setAnchorElement(event.currentTarget)
                              setCoordinates({
                                x: event.clientX,
                                y: event.clientY,
                              })
                            }}
                            loading={buttonLoadingState[operationData.profileId] || false}
                            size="small"
                            sx={{ minWidth: 'unset', p: '4px 0' }}
                            variant="text"
                          >
                            More
                          </LoadingButton>
                        </>
                      }
                      disableTypography
                    />
                  </ListItem>
                  <Tooltip title="Copy" placement="top">
                    <IconButton color="default" onClick={(event) => copyId(event, operationData.profileId ?? '')} size="small">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {isPaymentInfoExpanded && (
                  <>
                    <Stack alignItems="center" direction="row" gap={1}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography color="text.secondary" variant="body2">
                              External payer ID
                            </Typography>
                          }
                          secondary={
                            <Tooltip title={operationData.externalPayerId} placement="top-end" followCursor>
                              <Typography color="text.primary" variant="subtitle1">
                                {operationData.externalPayerId || '—'}
                              </Typography>
                            </Tooltip>
                          }
                          disableTypography
                        />
                      </ListItem>
                      <Tooltip title="Copy" placement="top">
                        <IconButton color="default" onClick={(event) => copyId(event, operationData.externalPayerId ?? '')} size="small">
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack alignItems="center" direction="row" flex="auto" gap={1}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography color="text.secondary" variant="body2">
                              External tax payer ID
                            </Typography>
                          }
                          secondary={
                            <Tooltip title={operationData.externalTaxPayerId} placement="top-end" followCursor>
                              <Typography color="text.primary" variant="subtitle1">
                                {operationData.externalTaxPayerId || '—'}
                              </Typography>
                            </Tooltip>
                          }
                          disableTypography
                        />
                      </ListItem>
                      <Tooltip title="Copy" placement="top">
                        <IconButton color="default" onClick={(event) => copyId(event, operationData.externalTaxPayerId ?? '')} size="small">
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </>
                )}
              </Stack>

              <Box pt={5}>
                <svg width="33" fill="none" height="8" viewBox="0 0 33 8" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM0 4.5H32V3.5H0V4.5Z"
                    fill="#BCC0CD"
                  />
                </svg>
              </Box>

              <Stack>
                <Stack alignItems="center" direction="row" flex="auto" gap={1}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography color="text.secondary" variant="body2">
                          Recipient profile ID
                        </Typography>
                      }
                      secondary={
                        <>
                          <Tooltip title={operationData.recipientProfileId} placement="top-end" followCursor>
                            <Typography color="text.primary" variant="subtitle1">
                              {operationData.recipientProfileId || '—'}
                            </Typography>
                          </Tooltip>
                          <LoadingButton
                            onClick={(event) => {
                              handleLoadProfileInfo(operationData.recipientProfileId)
                              setAnchorElement(event.currentTarget)
                              setCoordinates({
                                x: event.clientX,
                                y: event.clientY,
                              })
                            }}
                            loading={buttonLoadingState[operationData.recipientProfileId] || false}
                            size="small"
                            sx={{ minWidth: 'min-content', p: '4px 0' }}
                            variant="text"
                          >
                            More
                          </LoadingButton>
                        </>
                      }
                      disableTypography
                    />
                  </ListItem>
                  <Tooltip title="Copy" placement="top">
                    <IconButton color="default" onClick={(event) => copyId(event, operationData.recipientProfileId ?? '')} size="small">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {isPaymentInfoExpanded && (
                  <Stack alignItems="center" direction="row" flex="auto" gap={1}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography color="text.secondary" variant="body2">
                            External recipient ID
                          </Typography>
                        }
                        secondary={
                          <Tooltip title={operationData.externalReceiverId} placement="top-end" followCursor>
                            <Typography color="text.primary" variant="subtitle1">
                              {operationData.externalReceiverId || '—'}
                            </Typography>
                          </Tooltip>
                        }
                        disableTypography
                      />
                    </ListItem>
                    <Tooltip title="Copy" placement="top">
                      <IconButton color="default" onClick={(event) => copyId(event, operationData.externalReceiverId ?? '')} size="small">
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
          {isAllowedRefundOrSuspend && (
            <Stack
              alignItems="flex-end"
              gap={isOperationSuspended || isRefundTransactionProceed || isRefundTransactionSuccess ? 1 : 2}
              p={1}
              sx={{ opacity: isOperationDetailsLoading ? 0 : 1, transition: 'opacity 0.2s' }}
            >
              {isShowRefundButton &&
                (isValidOperationDetails && isRefundTransactionProceed ? (
                  <Stack alignItems="center" border={`1px solid ${colors.divider}`} borderRadius={2} direction="row" gap={1} p={1}>
                    <IconButton
                      onClick={(event) => {
                        setControlledOperationAction(OperationActions.REFUND)
                        setMemberInfoAnchorElement(event.currentTarget)
                        setCoordinates({ x: event.clientX, y: event.clientY })
                      }}
                      size="small"
                      sx={{ maxHeight: 24, p: 0 }}
                      disableRipple
                    >
                      <Avatar
                        style={{
                          backgroundColor: memberInfoAnchorElement ? colors.standardInputLine : colors.actionDisabled,
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                        }}
                        iconSize="16px"
                      />
                    </IconButton>
                    <Typography color="text.secondary" fontSize="small" variant="buttonSmall">
                      {isRefundTransactionSuccess ? 'Refunded' : 'Refund initiated'}
                    </Typography>
                  </Stack>
                ) : (
                  <LoadingButton
                    disabled={!!requestId}
                    loading={currentOperationAction === OperationActions.REFUND && isOperationActionLoading}
                    onClick={() => setShowRefundModal(true)}
                    size="small"
                    variant="outlined"
                  >
                    Refund
                  </LoadingButton>
                ))}
              {isShowSuspendButton && checkPermissions(userPermissions, [Permissions.PAYMENTS_REFUND]) && (
                <LoadingButton
                  disabled={!!requestId}
                  loading={currentOperationAction === OperationActions.SUSPEND && isOperationActionLoading}
                  onClick={() => setShowSuspensionModal(true)}
                  size="small"
                  variant="outlined"
                >
                  Suspend
                </LoadingButton>
              )}
              {isShowUnblockButton && checkPermissions(userPermissions, [Permissions.PAYMENTS_SUSPEND_UNBLOCK]) && (
                <Stack alignItems="center" border={`1px solid ${colors.divider}`} borderRadius={2} direction="row" gap={1} p={1}>
                  <IconButton
                    onClick={(event) => {
                      setControlledOperationAction(OperationActions.SUSPEND)
                      setMemberInfoAnchorElement(event.currentTarget)
                      setCoordinates({ x: event.clientX, y: event.clientY })
                    }}
                    size="small"
                    sx={{ maxHeight: 24, p: 0 }}
                    disableRipple
                  >
                    {isEmptyOperationHistory ? (
                      <AvatarMUI
                        sx={{
                          width: 24,
                          backgroundColor: memberInfoAnchorElement ? colors.standardInputLine : colors.actionDisabled,
                          height: 24,
                        }}
                      >
                        <SystemIcon sx={{ color: colors.white, fontSize: 16 }} />
                      </AvatarMUI>
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: memberInfoAnchorElement ? colors.standardInputLine : colors.actionDisabled,
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                        }}
                        iconSize="16px"
                      />
                    )}
                  </IconButton>
                  <LoadingButton
                    disabled={idDisabledUnblockButton}
                    loading={currentOperationAction === OperationActions.UNSUSPEND && isOperationActionLoading}
                    onClick={() => setShowSuspensionModal(true)}
                    size="small"
                    variant="outlined"
                  >
                    Unblock
                  </LoadingButton>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
        <TransactionsTable row={operationData} />
      </Stack>
      <SuspensionConfirmModal
        onDataRefresh={() => {
          dispatch(fetchOperationDetails(operationData.id))
          onDataRefresh?.()
        }}
        isOperationSuspended={isOperationSuspended}
        onClose={() => setShowSuspensionModal(false)}
        open={showSuspensionModal}
        operationId={operationData.id as string}
      />

      <RefundModal
        onDataRefresh={() => {
          dispatch(fetchOperationDetails(operationData.id))
          onDataRefresh?.()
        }}
        currency={operationData.currency}
        isInAppOperation={isInAppOperation}
        onClose={() => setShowRefundModal(false)}
        open={showRefundModal}
        operationId={operationData.id as string}
        refundReasons={refundReasons}
        total={operationData.total}
      />
    </Stack>
  )
}
