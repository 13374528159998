export const MULTIPLE_TITLE_SHOW_IDS = ['user_id', 'profile_id', 'owner_id']
export const KEEP_OPTIONS_SHOW_IDS = ['user_id', 'profile_id', 'owner_id', 'sales_tax_moderation_passions', 'sales_tax_code']
export const PRICE_IDS = ['price', 'total']
export const TOOLTIP_MESSAGES = {
  donation: 'Not applicable for Donation, Tip, Goal, Paid Lifestyle',
  offerWish: 'Not applicable for Offer or Wish',
  offlineEvent: 'Not applicable for Offline Event',
  privateWish: 'Not applicable for Private Wish',
}
export const PASSIONS_COLUMN_ID = 'sales_tax_moderation_passions'
export const COMPLAINTS_MODERATOR_COLUMN_ID = 'complaints_moderator_id'
export const HIVE_MODERATOR_COLUMN_ID = 'hive_moderator_id'

export const DEFAULT_MODERATOR = 'Hive'
