import './index.scss'

import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Link, Tab } from '@mui/material'
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import isEqual from 'lodash/isEqual'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router-dom'

import withAuth from '@admin/components/Authorization/withAuth'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import ManageLinkedCollectionsModal from '@admin/components/ManageLinkedCollections/ManageLinkedCollectionsModal'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import Divider from '@admin/components/shared/Divider/Divider'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import Modal from '@admin/components/shared/Modal/Modal'
import AddBannerModal from '@admin/components/WorldManagement/Banners/BannersModal/AddBanner/AddBannerModal'
import ArchiveBannersModal from '@admin/components/WorldManagement/Banners/BannersModal/ArchiveBanners/ArchiveBannersModal'
import ExpiredBannerModal from '@admin/components/WorldManagement/Banners/BannersModal/ExpiredBanner/ExpiredBannerModal'
import CohortModal from '@admin/components/WorldManagement/Cohorts/CohortsModal/CohortModal'
import DeleteCohortsModal from '@admin/components/WorldManagement/Cohorts/CohortsModal/DeleteCohorts/DeleteCohortsModal'
import AddCollectionModal from '@admin/components/WorldManagement/Collections/CollectionsModal/AddCollection/AddCollectionModal'
import ArchiveCollectionsModal from '@admin/components/WorldManagement/Collections/CollectionsModal/ArchiveCollections/ArchiveCollectionsModal'
import PublishCollectionsModal from '@admin/components/WorldManagement/Collections/CollectionsModal/PublishCollections/PublishCollectionsModal'
import UnpublishCollectionModal from '@admin/components/WorldManagement/Collections/CollectionsModal/UnpublishCollection/UnpublishCollectionModal'
import ContextMenu from '@admin/components/WorldManagement/ContextMenu/ContextMenu'
import CustomToolbar from '@admin/components/WorldManagement/CustomToolbar/CustomToolbar'
import WorldTabs from '@admin/components/WorldManagement/types/WorldTabs'
import { ErrorBoundaryErrors } from '@admin/containers/ErrorPage/ErrorPage'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { clearDetails, fetchCohort, ICohort, selectCohortDetails } from '@admin/store/cohortDetailsSlice'
import { ICollection } from '@admin/store/collectionDetailsSlice'
import { fetchGenders, selectGenders } from '@admin/store/commonSlice'
import { clearFilters, reverseFilters, selectFiltersCurrentData, selectFiltersData } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  clearErrorMessage,
  fetchAllCohorts,
  fetchArchiveBanner,
  fetchDefaultCollection,
  fetchExpireBanner,
  fetchWorld,
  selectAllCohorts,
  selectTotalCount,
  selectWorld,
  selectWorldColumns,
  selectWorldStatus,
  TCohortsTableData,
} from '@admin/store/worldManagementSlice'
import { stringCapitalize } from '@admin/utils//stringCapitalize'

import type { TAny } from '@yzzy/types'

type TCohortModal =
  | {
      action: 'add'
      itemId: null
    }
  | {
      action: 'edit'
      itemId: string
    }

const initialRowCount = 10

function WorldManagement() {
  const { tabId } = useParams()
  const isCorrectPage = useMemo(() => tabId && Object.values(WorldTabs).includes(tabId as WorldTabs), [tabId])
  const [tabValue, setTabValue] = useState<WorldTabs>(tabId ? (tabId as WorldTabs) : WorldTabs.COLLECTIONS)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [addNewBanner, setAddNewBanner] = useState(false)
  const [cohortModal, setCohortModal] = useState<null | TCohortModal>(null)
  const [deleteCohorts, setDeleteCohorts] = useState<null | TCohortsTableData | TCohortsTableData[]>(null)
  const [archiveBanners, setArchiveBanners] = useState<IBanner | IBanner[] | null>(null)
  const [expiredBanner, setExpiredBanner] = useState<IBanner | null>(null)
  const [openLinkedCollections, setOpenLinkedCollections] = useState<IBanner | null>(null)
  const [addNewCollection, setAddNewCollection] = useState(false)
  const [archiveCollections, setArchiveCollections] = useState<ICollection | ICollection[] | null>(null)
  const [publishCollections, setPublishCollections] = useState<ICollection | ICollection[] | null>(null)
  const [unpublishCollections, setUnpublishCollections] = useState<ICollection | null>(null)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: initialRowCount,
  })
  const apiReference = useGridApiRef()

  // const [sortingDrawer, setSortingDrawer] = useState(false);
  const mounted = useRef(true)
  // const [searchValue, setSearchValue] = useState<string>('');
  const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const filtersData = useAppSelector(selectFiltersData)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const columns = useAppSelector(selectWorldColumns)
  const worldData = useAppSelector(selectWorld)
  const status = useAppSelector(selectWorldStatus)
  const totalCount = useAppSelector(selectTotalCount)
  const genders = useAppSelector(selectGenders)
  const cohortDetails = useAppSelector(selectCohortDetails)
  const allCohorts = useAppSelector(selectAllCohorts)

  // const filtersLength = useMemo(() => {
  //   return Object.values(filtersData).filter((value) => value !== null).length
  // }, [filtersData])

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const handleTabChange = useCallback((event: ChangeEvent<{}>, value: WorldTabs) => {
    setTabValue(value)
  }, [])

  const customCellRenderCollections: TAny = {
    name: (props: GridRenderCellParams) => (
      <div className="worldCell worldCell-content">
        <div className="worldCell-primary">{props.row?.title}</div>
        {props.row?.id !== 'DEFAULT' && (
          <div className="worldCell-secondary">
            {props.row?.creatorName} {props.row?.creatorLastName}
          </div>
        )}
      </div>
    ),
    action: (props: GridRenderCellParams) => {
      if (props.row?.title === 'ALL') {
        return <div className="worldCell" />
      }

      return (
        <div className="worldCell worldCell-action">
          <ContextMenu
            item={props.row}
            setArchiveCollections={(collection: ICollection) => setArchiveCollections(collection)}
            setPublishCollections={(collection: ICollection) => setPublishCollections(collection)}
            setUnpublishCollections={(collection: ICollection) => setUnpublishCollections(collection)}
          />
        </div>
      )
    },
    cohort: (props: GridRenderCellParams) => <div className="worldCell">{props.row?.cohortTitle}</div>,
    publicationDate: (props: GridRenderCellParams) => {
      if (props.row?.id === 'DEFAULT') {
        return <div className="worldCell" />
      }

      return (
        <div
          className={`worldCell worldCell-wrap
          ${!props.row?.publishDateTime && !props.row?.expiredDateTime && 'worldCell-status_expired'}`}
        >
          {props.row?.publishDateTime && props.row?.expiredDateTime
            ? `${new Date(props.row?.publishDateTime).toLocaleString('en-AU').toUpperCase()}-
          ${new Date(props.row?.expiredDateTime).toLocaleString('en-AU').toUpperCase()}`
            : 'Not specified'}
        </div>
      )
    },
    status: (props: GridRenderCellParams) => (
      <div className={`worldCell worldCell-status worldCell-status_${props.row?.status.toLowerCase()}`}>{stringCapitalize(props.row?.status)}</div>
    ),
    updatedAt: (props: GridRenderCellParams) => (
      <div className="worldCell">{props.row?.updatedAt ? new Date(props.row?.updatedAt).toLocaleDateString('en-AU') : ''}</div>
    ),
  }

  const customCellRenderBanners: TAny = {
    name: (props: GridRenderCellParams) => (
      <Link
        className="worldCell"
        href={`/ui/world-management/banners/${props.row?.id}`}
        onClick={(event: TAny) => event.stopPropagation()}
        underline="none"
      >
        <AvatarItem
          primaryText={props.row?.name}
          src={props.row?.cover.originalLink}
          subText={`by ${props.row?.creatorName} ${props.row?.creatorLastName}`}
        />
      </Link>
    ),
    action: (props: GridRenderCellParams) => (
      <div className="worldCell worldCell-action">
        <ContextMenu
          item={props.row}
          setArchiveBanners={(banner: IBanner) => setArchiveBanners(banner)}
          setExpiredBanners={(banner: IBanner) => setExpiredBanner(banner)}
          setLinkedCollections={(banner: IBanner) => setOpenLinkedCollections(banner)}
        />
      </div>
    ),
    expiredDate: (props: GridRenderCellParams) => (
      <div className={`worldCell ${!props.row?.expiredDate && 'worldCell-status_expired'}`}>
        {props.row?.expiredDate ? new Date(props.row?.expiredDate).toLocaleString('en-AU').toUpperCase() : 'Not specified'}
      </div>
    ),
    status: (props: GridRenderCellParams) => (
      <div className={`worldCell worldCell-status worldCell-status_${props.row?.status.toLowerCase()}`}>{stringCapitalize(props.row?.status)}</div>
    ),
    updated: (props: GridRenderCellParams) => <div className="worldCell">{new Date(props.row?.updatedAt).toLocaleDateString('en-AU')}</div>,
  }

  const customCellRenderCohorts: TAny = {
    name: (props: GridRenderCellParams) => (
      <div className="worldCell worldCell-content">
        <div className="worldCell-primary">{props.row?.title}</div>
        {props.row?.creatorName && props.row?.creatorLastName && (
          <div className="worldCell-secondary">
            {props.row?.creatorName} {props.row?.creatorLastName}
          </div>
        )}
      </div>
    ),
    action: (props: GridRenderCellParams) => (
      <div className="worldCell worldCell-action">
        <ContextMenu
          item={props.row}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setDeleteCohorts={(cohort: ICohort) => setDeleteCohorts(cohort)}
          setEditCohorts={(itemId: string) => setCohortModal({ action: 'edit', itemId })}
        />
      </div>
    ),
    creationDate: (props: GridRenderCellParams) => <div className={`worldCell`}>{new Date(props.row?.createdAt).toLocaleDateString('en-AU')}</div>,
    linkedCollection: (props: GridRenderCellParams) => (
      <div className={`worldCell worldCell-status ${!props.row?.hasCompilation ? 'worldCell-gray' : ''}`}>
        {props.row?.hasCompilation ? 'Yes' : 'No'}
      </div>
    ),
    status: (props: GridRenderCellParams) => (
      <div className={`worldCell worldCell-status worldCell-status_${stringCapitalize(props.row?.status)}`}>{props.row?.status.toLowerCase()}</div>
    ),
  }

  const rows: TAny = useMemo(() => {
    switch (tabValue) {
      case WorldTabs.ARCHIVE: {
        return worldData.archive.map((item: TAny, index: number) => ({
          ...item,
          id: index,
        }))
      }
      case WorldTabs.BANNERS: {
        return worldData.banners.map((item: TAny) => ({ ...item, id: item.id }))
      }
      case WorldTabs.COHORTS: {
        return worldData.cohorts?.map((item: TAny) => ({ ...item, id: item.id })) || []
      }
      case WorldTabs.COLLECTIONS: {
        return worldData.collections?.map((item: TAny) => ({ ...item, id: item.id })) || []
      }
    }
  }, [worldData, tabValue])

  const cols: GridColDef[] = useMemo(() => {
    switch (tabValue) {
      case WorldTabs.ARCHIVE: {
        return columns.archive.map((item: TAny) => ({
          width: item.width,
          field: item.columnId,
          flex: item.flex,
          headerClassName: item.headerClassName,
          headerName: item.columnName,
          renderCell: customCellRenderBanners[item.columnId] ?? null,
        }))
      }
      case WorldTabs.BANNERS: {
        return columns.banners.map((item: TAny) => ({
          width: item.width,
          field: item.columnId,
          flex: item.flex,
          headerClassName: item.headerClassName,
          headerName: item.columnName,
          renderCell: customCellRenderBanners[item.columnId] ?? null,
        }))
      }
      case WorldTabs.COHORTS: {
        return columns.cohorts.map((item: TAny) => ({
          width: item.width,
          field: item.columnId,
          flex: item.flex,
          headerName: item.columnName,
          renderCell: customCellRenderCohorts[item.columnId] ?? null,
        }))
      }
      case WorldTabs.COLLECTIONS: {
        return columns.collections.map((item: TAny) => ({
          width: item.width,
          field: item.columnId,
          flex: item.flex,
          headerName: item.columnName,
          renderCell: customCellRenderCollections[item.columnId] ?? null,
          sortable: item.sortable,
        }))
      }
    }
  }, [columns, tabValue])

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/world-management/collections`, { replace: false })

      return
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'))
    }
  }, [])

  const initialFetchData = useCallback(async () => {
    Promise.all([dispatch(fetchGenders()), dispatch(fetchDefaultCollection()), dispatch(fetchAllCohorts())]).then((result) => {
      result.forEach((value: TAny) => {
        if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
          // showBoundary( new Error(value.error.message));
        }
      })
    })
  }, [])

  useEffect(() => {
    initialFetchData()
  }, [])

  const fetchData = useCallback(async (page: number, pageSize: number, tabValue: WorldTabs) => {
    await dispatch(fetchWorld({ dataType: tabValue, page, pageSize }))
  }, [])

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        void fetchData(0, newPaginationModel.pageSize, tabValue)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      void fetchData(newPaginationModel.page, newPaginationModel.pageSize, tabValue)

      return newPaginationModel
    })
  }

  const handleClickCollectionRow = (parameters: GridRowParams<TAny>) => {
    navigate(`/ui/world-management/collections/${parameters.row?.id}`)
  }

  useEffect(() => {
    dispatch(clearFilters())
    setPaginationModel((previousState) => ({ page: 0, pageSize: previousState.pageSize }))
    navigate(`/ui/world-management/${tabValue}`, { replace: false })
    // Promise.all([
    fetchData(0, paginationModel.pageSize, tabValue)
    // ]).then(result => {
    //   result.forEach((value: TAny) => {
    //     if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
    //       showBoundary( new Error(value.error.message));
    //     }
    //   });
    // })

    return () => {
      mounted.current = true
    }
  }, [tabValue])

  useEffect(() => {
    if (cohortModal?.itemId) {
      dispatch(fetchCohort(cohortModal.itemId))
    }
  }, [cohortModal])

  const [errorMessage, setErrorMessage] = useState('')

  const archiveBannerFunction = useCallback(
    async (items: IBanner | IBanner[]) => {
      try {
        setErrorMessage('')
        const isArray = Array.isArray(items)
        const data = isArray ? items.map((item) => item.id) : [items.id]
        const result = await dispatch(fetchArchiveBanner(data))

        if (result.meta.requestStatus === 'fulfilled') {
          if (isArray) {
            setArchiveBanners(null)
            enqueueSnackbar(`${items.length} banners have been archived`, { variant: 'success' as VariantType })
          } else {
            setArchiveBanners(null)
            enqueueSnackbar(`${items.name} banner has been archived`, { variant: 'success' as VariantType })
          }
        } else if (result.meta.requestStatus === 'rejected') {
          if (isArray) setErrorMessage('Archiving error. Try later')
        } else {
          setArchiveBanners(null)
          enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType })
        }
      } catch {
        if (Array.isArray(items)) {
          setErrorMessage('Archiving error. Try later')
        } else {
          setArchiveBanners(null)
          enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType })
        }
      }
    },
    [dispatch],
  )

  const expireBannerFunction = useCallback(
    async (item: IBanner) => {
      const result = await dispatch(fetchExpireBanner([item.id]))

      if (result.meta.requestStatus === 'fulfilled') {
        setExpiredBanner(null)
        enqueueSnackbar(`${item.name} banner has been set as expired`, { variant: 'success' as VariantType })
      } else if (result.meta.requestStatus === 'rejected') {
        setExpiredBanner(null)
        enqueueSnackbar(`Changing error, try again later`, { variant: 'error' as VariantType })
      }
    },
    [dispatch],
  )

  return (
    <div className="worldManagement-container">
      <div className="worldManagement-header">Tim World Management</div>
      <TabContext value={tabValue}>
        <TabList className="worldManagement-tabs" onChange={handleTabChange}>
          <Tab label="Collections" className="worldManagement-tab" value={WorldTabs.COLLECTIONS} />
          <Tab label="Banners" className="worldManagement-tab" value={WorldTabs.BANNERS} />
          <Tab label="Cohorts" className="worldManagement-tab" value={WorldTabs.COHORTS} />
          <Divider orientation="vertical" flexItem />
          <Tab label="Archive" className="worldManagement-tab" value={WorldTabs.ARCHIVE} />
        </TabList>
        <TabPanel value={WorldTabs.COLLECTIONS}>
          <div className="worldManagement-table-container">
            <div className="worldManagement-table-buttons">
              {/*todo second release*/}
              {/*<Button*/}
              {/*  startIcon={<FilterIcon />}*/}
              {/*  className="worldManagement-table-button"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={() => setFilterDrawer(true)}*/}
              {/*>*/}
              {/*  Filter ({filtersLength})*/}
              {/*</Button>*/}
              <div />
              <Button className="worldManagement-table-button" onClick={() => setAddNewCollection(true)} variant="contained">
                Add collection
              </Button>
            </div>
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooterSelectedRowCount={true}
                  pinnedRows={{
                    top: worldData.defaultCollection ? [worldData.defaultCollection] : [],
                  }}
                  slots={{
                    footer: CustomFooter,
                    toolbar: () => {
                      const selectedRows = apiReference.current.getSelectedRows()

                      return CustomToolbar({
                        selectedRows,
                        setArchiveCollections: (items: ICollection[]) => setArchiveCollections(items),
                        setPublishCollections: (items: ICollection[]) => setPublishCollections(items),
                      })
                    },
                  }}
                  apiRef={apiReference}
                  checkboxSelection={true}
                  columnHeaderHeight={64}
                  columns={cols}
                  disableColumnMenu={true}
                  disableColumnResize={true}
                  loading={status === 'loading'}
                  onPaginationModelChange={handlePaginationModelChange}
                  onRowClick={(parameters) => handleClickCollectionRow(parameters)}
                  pagination={true}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  rowCount={totalCount.collectionElements}
                  rowHeight={72}
                  rows={rows}
                  disableRowSelectionOnClick
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={WorldTabs.BANNERS}>
          <div className="worldManagement-table-container">
            <div className="worldManagement-table-buttons">
              {/*todo second release*/}
              {/*<Button*/}
              {/*  startIcon={<FilterIcon />}*/}
              {/*  className="worldManagement-table-button"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={() => setFilterDrawer(true)}*/}
              {/*>*/}
              {/*  Filter ({filtersLength})*/}
              {/*</Button>*/}
              <div />
              <Button className="worldManagement-table-button" onClick={() => setAddNewBanner(true)} variant="contained">
                Add banner
              </Button>
            </div>
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooterSelectedRowCount={true}
                  slots={{
                    footer: CustomFooter,
                    toolbar: () => {
                      const selectedRows = apiReference.current.getSelectedRows()

                      return CustomToolbar({
                        selectedRows,
                        setArchiveBanners: (items: IBanner[]) => setArchiveBanners(items),
                      })
                    },
                  }}
                  apiRef={apiReference}
                  checkboxSelection={true}
                  columnHeaderHeight={64}
                  columns={cols}
                  disableColumnMenu={true}
                  disableColumnResize={true}
                  loading={false}
                  onPaginationModelChange={handlePaginationModelChange}
                  pagination={true}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  rowCount={totalCount.bannerElements}
                  rowHeight={68}
                  rows={rows}
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={WorldTabs.COHORTS}>
          <div className="worldManagement-table-container">
            <div className="worldManagement-table-buttons">
              {/*todo second release*/}
              {/*<Button*/}
              {/*  startIcon={<FilterIcon />}*/}
              {/*  className="worldManagement-table-button"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={() => setFilterDrawer(true)}*/}
              {/*>*/}
              {/*  Filter ({filtersLength})*/}
              {/*</Button>*/}
              <div />
              <Button className="worldManagement-table-button" onClick={() => setCohortModal({ action: 'add', itemId: null })} variant="contained">
                Add cohort
              </Button>
            </div>
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooterSelectedRowCount={true}
                  slots={{
                    footer: CustomFooter,
                    toolbar: () => {
                      const selectedRows = apiReference.current.getSelectedRows()

                      return CustomToolbar({
                        selectedRows,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setDeleteCohorts: (items: ICohort[]) => setDeleteCohorts(items),
                      })
                    },
                  }}
                  apiRef={apiReference}
                  checkboxSelection={true}
                  columnHeaderHeight={64}
                  columns={cols}
                  disableColumnMenu={true}
                  disableColumnResize={true}
                  loading={status === 'loading'}
                  onPaginationModelChange={handlePaginationModelChange}
                  pagination={true}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  rowCount={totalCount.cohortElements}
                  rowHeight={68}
                  rows={rows}
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={WorldTabs.ARCHIVE}>
          {/* todo добавить таблицу архива */}
          <div>Archive</div>
        </TabPanel>
      </TabContext>
      <CloseFilterDrawerConfirmModal
        closeModal={() => cancelCloseFilterSidebar()}
        onClose={() => onFilterSidebarClose(true)}
        open={filterDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          onApply={() => {
            onFilterSidebarClose(true)
            // dispatch(fetchData({ pageSize: rowCount, type: tabValue }));
          }}
          onClose={onFilterSidebarClose}
          type={tabValue}
        />
      </Drawer>
      {/*<Drawer anchor='right' open={sortingDrawer} onClose={() => setSortingDrawer(false)}>*/}
      {/*  <SortingDrawer*/}
      {/*    type={tabValue}*/}
      {/*    isHidePresets*/}
      {/*    onClose={() => setSortingDrawer(false)}*/}
      {/*    onClos={() => {*/}
      {/*      setSortingDrawer(false);*/}
      {/*      dispatch(fetchData({ pageSize: rowCount, type: tabValue }));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Drawer>*/}

      <Modal open={addNewBanner}>
        <AddBannerModal onClose={() => setAddNewBanner(false)} />
      </Modal>
      <Modal customstyle={{ minHeight: 208 }} open={!!archiveBanners}>
        <ArchiveBannersModal
          archive={(items) => {
            archiveBannerFunction(items)
          }}
          onClose={() => {
            setArchiveBanners(null)
            setErrorMessage('')
          }}
          errorMessage={errorMessage}
          items={archiveBanners!}
        />
      </Modal>
      <Modal open={!!expiredBanner}>
        <ExpiredBannerModal expire={(item) => expireBannerFunction(item)} item={expiredBanner!} onClose={() => setExpiredBanner(null)} />
      </Modal>
      {openLinkedCollections && (
        <ManageLinkedCollectionsModal
          entityId={openLinkedCollections.id}
          entityType="BANNER"
          onClose={() => setOpenLinkedCollections(null)}
          open={!!openLinkedCollections}
        />
      )}
      <Modal customstyle={{ minHeight: 180 }} open={!!deleteCohorts}>
        <DeleteCohortsModal items={deleteCohorts!} onClose={() => setDeleteCohorts(null)} status={status} />
      </Modal>
      {cohortModal && (cohortDetails || cohortModal.action === 'add') && (
        <Modal customstyle={{ minHeight: 180 }} open={!!cohortModal}>
          <CohortModal
            onClose={() => {
              setCohortModal(null)
              dispatch(clearErrorMessage())
              dispatch(clearDetails())
            }}
            action={cohortModal.action}
            genders={genders}
            item={cohortModal.action === 'edit' ? cohortDetails : null}
          />
        </Modal>
      )}
      <Modal open={addNewCollection}>
        <AddCollectionModal
          onClose={() => {
            setAddNewCollection(false)
            dispatch(clearErrorMessage())
          }}
          cohorts={allCohorts}
        />
      </Modal>
      <Modal customstyle={{ minHeight: 208 }} open={!!archiveCollections}>
        <ArchiveCollectionsModal items={archiveCollections!} onClose={() => setArchiveCollections(null)} status={status} />
      </Modal>
      <Modal customstyle={{ minHeight: 212 }} open={!!publishCollections}>
        <PublishCollectionsModal items={publishCollections!} onClose={() => setPublishCollections(null)} status={status} />
      </Modal>
      <Modal customstyle={{ minHeight: 180 }} open={!!unpublishCollections}>
        <UnpublishCollectionModal item={unpublishCollections!} onClose={() => setUnpublishCollections(null)} />
      </Modal>
    </div>
  )
}

export default withAuth(WorldManagement)
