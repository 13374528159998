import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useForm } from 'react-hook-form'

import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Modal from '@admin/components/shared/Modal/Modal'
import Select from '@admin/components/shared/Select/Select'
import TextField from '@admin/components/shared/TextField/TextField'
import { EditMemberSchema, type TEditMemberSchema } from '@admin/containers/TimMembers/utils/getValidationSchema'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchEditMember } from '@admin/store/membersSlice'
import { selectProfile } from '@admin/store/profileSlice'
import { TStatus } from '@admin/types/commonTypes'
import { IMemberInfo } from '@admin/types/Member'

import type { TAny } from '@yzzy/types'

interface IEditMemberModalProps {
  editMember: IMemberInfo
  onClose: () => void
  open: boolean
  possibleRoles: TAny[]
  refresh: () => void
}

const EditMemberModal = ({ editMember, onClose, open, possibleRoles, refresh }: IEditMemberModalProps) => {
  const currentMember = useAppSelector(selectProfile)
  const dispatch = useAppDispatch()

  const {
    formState: { isValid, errors, isDirty },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm<TEditMemberSchema>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      roleId: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(EditMemberSchema),
  })

  const [editMemberCreationStatus, setEditMemberCreationStatus] = useState<TStatus>('idle')
  const [isCanceling, setIsCanceling] = useState<boolean>(false)

  const handleCancel = useCallback(() => {
    if (isDirty) {
      setIsCanceling(true)
    } else {
      onClose()
    }
  }, [isDirty, onClose])

  const handleCloseConfirmCancel = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsCanceling(false)
      onClose()
    } else {
      setIsCanceling(false)
    }
  }, [])

  const onSubmit = async (data: TAny) => {
    setEditMemberCreationStatus('idle')
    trigger()
    const inputData = {
      ...data,
      id: editMember.id,
    }
    const result = await dispatch(fetchEditMember(inputData))

    if (result.meta.requestStatus === 'fulfilled') {
      refresh()
      onClose()
      enqueueSnackbar(`${data?.firstName + ' ' + data?.lastName}'s changes have been saved`, {
        variant: 'success' as VariantType,
      })
    } else if (result.meta.requestStatus === 'rejected') {
      setEditMemberCreationStatus('failed')
    }
  }

  useEffect(() => {
    setValue('roleId', editMember.role.id)
    setValue('email', editMember.email)
    setValue('firstName', editMember.firstName)
    setValue('lastName', editMember.lastName)
  }, [editMember, setValue])

  const currentUserSelected = useMemo(() => {
    return editMember.email === currentMember.email
  }, [editMember, currentMember])

  return (
    <>
      <Modal customstyle={{ maxWidth: 444, minHeight: 380 }} onClose={onClose} open={open}>
        <div className="saveNewMemberModal">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="saveNewMemberModal--header">Edit member</div>
            </div>
            <div className="saveNewMemberModal--content">
              <TextField
                {...register('firstName')}
                label="First name"
                name="firstName"
                className="saveNewMemberModal--textfield"
                defaultValue=""
                error={!!errors.firstName}
                helperText={errors.firstName && (errors.firstName?.message as string)}
                inputProps={{ maxLength: 58 }}
                size="small"
                variant="outlined"
              />
              <TextField
                {...register('lastName')}
                label="Last name"
                name="lastName"
                className="saveNewMemberModal--textfield"
                defaultValue=""
                error={!!errors.lastName}
                helperText={errors.lastName && (errors.lastName?.message as string)}
                inputProps={{ maxLength: 58 }}
                size="small"
                variant="outlined"
              />

              <TextField
                {...register('email')}
                label="Email"
                name="email"
                className="saveNewMemberModal--textfield"
                defaultValue=""
                disabled={true}
                error={!!errors.email}
                helperText={errors.email && (errors.email?.message as string)}
                inputProps={{ maxLength: 255 }}
                size="small"
                variant="outlined"
              />
              {possibleRoles.length > 0 && editMember && (
                <Select
                  {...register('roleId')}
                  name="roleId"
                  onChange={(event) => {
                    const newValue = event.target.value as string

                    setValue('roleId', newValue, { shouldValidate: true, shouldDirty: true })
                    trigger('roleId')
                  }}
                  className="saveNewMemberModal--textfield"
                  defaultValue={editMember.role.id}
                  disabled={currentUserSelected}
                  error={!!errors.roleId}
                  options={possibleRoles}
                  placeholder="Role"
                  size="small"
                />
              )}
            </div>
            {editMemberCreationStatus === 'failed' && <Alert severity="error">Saving error, try again later</Alert>}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '28px' }}>
              <Button onClick={handleCancel} style={{ marginRight: '16px' }}>
                Cancel
              </Button>
              <Button disabled={!isValid || !isDirty} type="submit" variant="contained">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Dialog fullWidth maxWidth="xs" open={isCanceling}>
        <DialogTitle variant="h6">Cancel editing member</DialogTitle>
        <DialogContent>
          <DialogContentText color="text.primary" variant="body1">
            Changes won't be applied
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmCancel(false)}>Don't cancel</Button>
          <Button onClick={() => handleCloseConfirmCancel(true)} type="submit" variant="contained">
            Cancel editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditMemberModal
