import { FC, useEffect } from 'react'

import { Outlet } from 'react-router-dom'

import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Stack from '@admin/components/shared/Stack/Stack'
import { fetchPermissions, selectIsAuth, selectUserPermissionsStatus } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'

const LoaderFallback: FC = () => {
  const dispatch = useAppDispatch()
  const userPermissionsStatus = useAppSelector(selectUserPermissionsStatus)
  const isAuth = useAppSelector(selectIsAuth)

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchPermissions())
    }
  }, [isAuth])

  if (userPermissionsStatus === 'loading' && isAuth) {
    return (
      <Stack alignItems="center" height="100vh">
        <>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="logo_gradient" gradientTransform="rotate(135)">
                <stop offset="0%" stopColor="#83A4F8" />
                <stop offset="48%" stopColor="#608AF7" />
                <stop offset="100%" stopColor="#4C72D5" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress size={64} sx={{ margin: 'auto', 'svg circle': { stroke: 'url(#logo_gradient)' } }} thickness={4} />
        </>
      </Stack>
    )
  }

  return <Outlet />
}

export default LoaderFallback
