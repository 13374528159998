import './index.scss'

import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from '@mui/material'
import { enqueueSnackbar, VariantType } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBack from '@admin/assets/img/ArrowBack'
import CheckCircleIcon from '@admin/assets/img/CheckCircleIcon'
import CheckIcon from '@admin/assets/img/CheckIcon'
import ChevronRightIcon from '@admin/assets/img/ChevronRightIcon'
import PlusIcon from '@admin/assets/img/PlusIcon'
import RestoreIcon from '@admin/assets/img/RestoreIcon'
import StopIcon from '@admin/assets/img/StopIcon'
import WarningIcon from '@admin/assets/img/WarningIcon'
import Autocomplete from '@admin/components/shared/Autocomplete/Autocomplete'
import Breadcrumbs from '@admin/components/shared/Breadcrumbs/Breadcrumbs'
import Button from '@admin/components/shared/Button/Button'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Modal from '@admin/components/shared/Modal/Modal'
import TextField from '@admin/components/shared/TextField/TextField'
import CollectionBoard from '@admin/components/WorldManagement/Collections/CollectionDetails/CollectionBoard/CollectionBoard'
import DeleteEntities from '@admin/components/WorldManagement/Collections/CollectionDetails/DeleteEntities/DeleteEntities'
import changeOrderAndStatus from '@admin/components/WorldManagement/Collections/CollectionDetails/utils/changeOrderAndStatus'
import ArchiveCollectionsModal from '@admin/components/WorldManagement/Collections/CollectionsModal/ArchiveCollections/ArchiveCollectionsModal'
import PublishCollectionsModal from '@admin/components/WorldManagement/Collections/CollectionsModal/PublishCollections/PublishCollectionsModal'
import UnpublishCollectionModal from '@admin/components/WorldManagement/Collections/CollectionsModal/UnpublishCollection/UnpublishCollectionModal'
import getValidationSchema from '@admin/components/WorldManagement/Collections/CollectionsModal/utils/getValidationSchema'
import ContextMenu from '@admin/components/WorldManagement/ContextMenu/ContextMenu'
import { ICohort } from '@admin/store/cohortDetailsSlice'
import {
  fetchEditCollection,
  ICollection,
  ICollectionDetails,
  IOrderedEvent,
  IOrderedHero,
  IOrderedOffer,
  IOrderedWish,
  TOrderedBanner,
  TOrderedEntity,
} from '@admin/store/collectionDetailsSlice'
import { useAppDispatch } from '@admin/store/hooks'

export interface IDeletedEntities {
  entities: string[]
  entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'
  prevQuantity: number
}

interface IProps {
  allCohorts: ICohort[]
  collectionDetails: ICollectionDetails
  scrollTop: number
  setIsOpenClosingModal: Dispatch<SetStateAction<boolean>>
}

const initialEntitiesOrder = (entity: TOrderedEntity[]) => {
  const unavailable: [] | TOrderedEntity[] = entity.filter(({ entityStatus }) => entityStatus === 'UNAVAILABLE')
  const available: [] | TOrderedEntity[] = entity.filter(({ entityStatus }) => entityStatus !== 'UNAVAILABLE')
  const orderedArray = [...available].sort((item1, item2) => item1.order - item2.order)

  return [...orderedArray, ...unavailable]
}

const CollectionDetailsForm = ({ allCohorts, collectionDetails, scrollTop, setIsOpenClosingModal }: IProps) => {
  const [cohortValue, setCohortValue] = useState(collectionDetails.cohortTitle ?? '')
  // eslint-disable-next-line sonarjs/no-dead-store,@typescript-eslint/no-unused-vars,sonarjs/no-unused-vars
  const [activeLink, setActiveLink] = useState<string>('banners')
  const [calculatedInfoWidth, setCalculatedInfoWidth] = useState(0)
  const [archiveCollections, setArchiveCollections] = useState<ICollection | ICollection[] | null>(null)
  const [publishCollections, setPublishCollections] = useState<ICollection | ICollection[] | null>(null)
  const [unpublishCollections, setUnpublishCollections] = useState<ICollection | null>(null)
  const [isDeleteEntities, setIsDeleteEntities] = useState<IDeletedEntities | null>(null)

  /* Ширина у блоков энтити одинаковая, поэтому нужно получить только один для расчета ширины */
  const referenceMaxWidth = useRef<HTMLDivElement | null>(null)

  const { collectionId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const disableEditing = useMemo(() => collectionId === 'DEFAULT', [collectionId])
  const disableReordering = useMemo(() => collectionId !== 'DEFAULT' && collectionDetails.status === 'LIVE', [collectionId, collectionDetails])

  const schema = useMemo(() => getValidationSchema('edit'), [])

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
  } = useForm<ICollectionDetails>({
    defaultValues: {
      ...collectionDetails,
      banners: initialEntitiesOrder(collectionDetails.banners),
      cohortId: collectionDetails.cohortId,
      events: initialEntitiesOrder(collectionDetails.events),
      heroes: initialEntitiesOrder(collectionDetails.heroes),
      offers: initialEntitiesOrder(collectionDetails.offers),
      wishes: initialEntitiesOrder(collectionDetails.wishes),
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (cohortValue) {
      const id = allCohorts.find((cohort) => cohortValue === cohort.title)?.id || ''

      setValue('cohortId', id, { shouldDirty: true })
    }
  }, [collectionDetails, allCohorts, cohortValue])

  useEffect(() => {
    const init = () => {
      setCalculatedInfoWidth(referenceMaxWidth.current?.clientWidth as number)
    }

    window.addEventListener('resize', init)
    init()

    return () => window.removeEventListener('resize', init)
  }, [referenceMaxWidth.current, collectionDetails])

  useEffect(() => {
    const section = document.querySelectorAll('section')

    section.forEach((sec) => {
      const offset = sec.offsetTop
      const height = sec.offsetHeight
      const id = sec.querySelector('.collectionDetails-content-info')?.getAttribute('id')

      if (scrollTop >= offset && scrollTop < offset + height) {
        setActiveLink(id!)
      }
    })
  }, [scrollTop])

  const handleDeleteEntities = (deletedEntities: string[], entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH') => {
    switch (entityType) {
      case 'BANNER':
        {
          const entities = getValues('banners')
          const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId))

          setValue('banners', changeOrderAndStatus(restEntities, entityType) as TOrderedBanner[], { shouldDirty: true })
        }
        break
      case 'HERO':
        {
          const entities = getValues('heroes')
          const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId))

          setValue('heroes', changeOrderAndStatus(restEntities, entityType) as IOrderedHero[], { shouldDirty: true })
        }
        break
      case 'OFFER':
        {
          const entities = getValues('offers')
          const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId))

          setValue('offers', changeOrderAndStatus(restEntities, entityType) as IOrderedOffer[], { shouldDirty: true })
        }
        break
      case 'WISH':
        {
          const entities = getValues('wishes')
          const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId))

          setValue('wishes', changeOrderAndStatus(restEntities, entityType) as IOrderedWish[], { shouldDirty: true })
        }
        break
      case 'EVENT':
        {
          const entities = getValues('events')
          const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId))

          setValue('events', changeOrderAndStatus(restEntities, entityType) as IOrderedEvent[], { shouldDirty: true })
        }
        break
    }
  }

  const onSubmit = async (data: ICollectionDetails) => {
    void trigger()

    try {
      const result = await dispatch(fetchEditCollection(data))

      if (result.meta.requestStatus === 'fulfilled') {
        reset({ ...data }, { keepDirty: false, keepValues: true })
        enqueueSnackbar('Changes have been saved', { variant: 'success' as VariantType })

        return
      }
      result.meta.requestStatus === 'rejected' && enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType })
    } catch {
      enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType })
    }
  }

  return (
    <form className="collectionDetails-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="collectionDetails-breadcrumbs">
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <div className="collectionDetails-header-text">
            <IconButton
              onClick={() => {
                if (isDirty) {
                  setIsOpenClosingModal(true)

                  return
                }
                navigate('/ui/world-management/collections')
              }}
            >
              <ArrowBack />
            </IconButton>
            Tim World Management
          </div>
          <div className="collectionDetails-header-text">Collections</div>
          <div className="collectionDetails-header-text collectionDetails-header-text_main">
            {collectionDetails?.title}
            {isDirty && <span className="collectionDetails-header-text"> (not saved)</span>}
          </div>
        </Breadcrumbs>
        <div className="collectionDetails-header-actions">
          {(collectionDetails.id === 'DEFAULT' || collectionDetails.status === 'INCOMPLETE' || collectionDetails.status === 'DEACTIVATED') && (
            <Button className="collectionDetails-header-actions-button" color="primary" disabled={!isDirty} type="submit" variant="contained">
              Save
            </Button>
          )}
          {collectionDetails.status === 'ARCHIEVED' && (
            <Button
              onClick={() => {
                // todo добавить действие на восстановление из архива
              }}
              className="collectionDetails-header-actions-button"
              color="primary"
              startIcon={<RestoreIcon />}
              variant="contained"
            >
              Restore
            </Button>
          )}
          {collectionDetails.status === 'READY' ||
            (collectionDetails.status === 'SCHEDULED' && (
              <Button
                className="collectionDetails-header-actions-button"
                color="primary"
                onClick={() => setPublishCollections(collectionDetails as ICollection)}
                startIcon={<CheckIcon />}
                variant="contained"
              >
                Publish
              </Button>
            ))}
          {collectionDetails.status === 'LIVE' && collectionDetails.id !== 'DEFAULT' && (
            <Button
              className="collectionDetails-header-actions-button"
              color="warning"
              onClick={() => setUnpublishCollections(collectionDetails as ICollection)}
              startIcon={<StopIcon />}
              variant="contained"
            >
              Unpublish
            </Button>
          )}
          {collectionDetails.id !== 'DEFAULT' && (
            <ContextMenu
              item={collectionDetails}
              setArchiveCollections={(collection: ICollection) => setArchiveCollections(collection)}
              setPublishCollections={(collection: ICollection) => setPublishCollections(collection)}
              setUnpublishCollections={(collection: ICollection) => setUnpublishCollections(collection)}
            />
          )}
        </div>
      </div>
      <div className="collectionDetails-container">
        <div>
          {/* Пока что скрываем степпер-навигацию по странице, чтоб было больше места для элементов.
              Возможно, в будущем нужно будет его вернуть, поэтому не удаляю. (также раскомментить в стилях) */}

          {/*<nav className="navigation">*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#banners" underline="none" onClick={() => setActiveLink('banners')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'banners' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {bannersData.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Banners*/}
          {/*    </Link>*/}

          {/*    <Divider orientation="vertical" sx={{ height: 20 }} />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#hero" underline="none" onClick={() => setActiveLink('hero')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'hero' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {heroesData?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Heroes*/}
          {/*    </Link>*/}
          {/*    <Divider orientation="vertical" sx={{ height: 20 }}  />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#offers" underline="none" onClick={() => setActiveLink('offers')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'offers' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {collectionDetails.offers?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Offers*/}
          {/*    </Link>*/}
          {/*    <Divider orientation="vertical" sx={{ height: 20 }}  />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#wishes" underline="none" onClick={() => setActiveLink('wishes')}>*/}
          {/*       <span className={`navigation-item-icon ${activeLink === 'wishes' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*         {collectionDetails.wishes?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*       </span>*/}
          {/*      Wishes*/}
          {/*    </Link>*/}
          {/*    <Divider orientation="vertical" sx={{ height: 20 }} />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#events" underline="none" onClick={() => setActiveLink('events')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'events' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {collectionDetails.events?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Events*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</nav>*/}
        </div>
        <div className="mainSection">
          <div className={disableReordering ? 'collectionDetails-header collectionDetails-header_disabled' : 'collectionDetails-header'}>
            <div className="collectionDetails-header-title">Settings</div>
            <div className="collectionDetails-header-lastModified">Updated: {new Date(collectionDetails.updatedAt).toLocaleDateString()}</div>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                disabled={disableEditing}
                {...register('title')}
                label="Collection name"
                name="title"
                defaultValue={getValues('title')}
                error={!!errors.title}
                onBlur={() => trigger('title')}
                size="small"
              />
              {/* todo сюда добавится сообщение об ошибке в названии, если такое уже существует
                  см пример в создании коллекции. там работает
               */}
              {errors.title && <FormHelperText error>{errors.title?.message as string}</FormHelperText>}
            </span>
            <Controller
              name="cohortId"
              render={() => (
                <Autocomplete
                  fullWidth={true}
                  label="Cohort"
                  inputprops={{
                    error: !!errors.cohortId,
                  }}
                  onChange={(event, value) => {
                    setCohortValue(value ? value : null)
                    void trigger('cohortId')
                  }}
                  onInputChange={(event, value) => {
                    setCohortValue(value)
                  }}
                  clearIcon={null}
                  disabled={disableEditing}
                  getOptionLabel={(option) => option || ''}
                  inputValue={cohortValue ?? ''}
                  isOptionEqualToValue={(option, value) => option === value}
                  onBlur={() => trigger('cohortId')}
                  options={allCohorts.map(({ title }) => title) || []}
                  size="small"
                  value={cohortValue}
                />
              )}
              control={control}
            />
          </div>

          {/* BANNERS */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Banners
                <span className="collectionDetails-content-titleCount"> ({getValues('banners').length}/5) </span>
                <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                  <CheckCircleIcon />
                </span>
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('banners')?.length ? (
                  <Link href="/ui/world-management/banners" target="_blank">
                    <Button startIcon={<PlusIcon />} variant="outlined">
                      Add Banner
                    </Button>
                  </Link>
                ) : (
                  <span className="collectionDetails-content-title-actionText">
                    Find more{' '}
                    <Link href="/ui/world-management/banners" target="_blank">
                      Banners
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div id="banners" className="collectionDetails-content-info" ref={referenceMaxWidth}>
              {!getValues('banners')?.length ? (
                <span className="collectionDetails-content-info-empty">No banners added yet.</span>
              ) : (
                <CollectionBoard
                  parentsWidth={calculatedInfoWidth}
                  widthEntity={331}
                  onSortEntities={(entities: TOrderedEntity[]) => {
                    setValue('banners', entities as TOrderedBanner[], { shouldDirty: true })
                  }}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeleteEntities({ entities, entityType: 'BANNER', prevQuantity: getValues('banners')?.length })
                  }
                  collectionId={collectionDetails.id}
                  entityType={'BANNER'}
                  heightEntity={248}
                  isCanDrag={!disableReordering}
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  items={getValues('banners') || []}
                />
              )}
            </div>
          </section>

          {/* HEROES */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Tim Hero
                <span className="collectionDetails-content-titleCount"> ({getValues('heroes')?.length || 0}/15) </span>
                {getValues('heroes')?.length < 15 ? (
                  <span className="collectionDetails-content-titleIcon">
                    <WarningIcon />
                  </span>
                ) : (
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                )}
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('heroes')?.length ? (
                  <Link href="/ui/users" target="_blank">
                    <Button startIcon={<PlusIcon />} variant="outlined">
                      Add profile
                    </Button>
                  </Link>
                ) : (
                  <span className="collectionDetails-content-title-actionText">
                    Find more{' '}
                    <Link href="/ui/users" target="_blank">
                      Profiles
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div id="hero" className="collectionDetails-content-info">
              {!getValues('heroes')?.length ? (
                <span className="collectionDetails-content-info-empty">No profiles added yet.</span>
              ) : (
                <CollectionBoard
                  parentsWidth={calculatedInfoWidth}
                  widthEntity={200}
                  onSortEntities={(entities: TOrderedEntity[]) => {
                    setValue('heroes', entities as IOrderedHero[], { shouldDirty: true })
                  }}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeleteEntities({ entities, entityType: 'HERO', prevQuantity: getValues('heroes')?.length })
                  }
                  collectionId={collectionDetails.id}
                  entityType={'HERO'}
                  heightEntity={280}
                  isCanDrag={!disableReordering}
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  items={getValues('heroes') || []}
                />
              )}
            </div>
          </section>

          {/* OFFERS */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Top Offers
                <span className="collectionDetails-content-titleCount"> ({getValues('offers')?.length || 0}/15) </span>
                {getValues('offers')?.length < 15 ? (
                  <span className="collectionDetails-content-titleIcon">
                    <WarningIcon />
                  </span>
                ) : (
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                )}
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('offers')?.length ? (
                  // todo когда в контент дисплее будет навигация к конкретной сущности, поменять на конкретную.
                  <Link href="/ui/content-display" target="_blank">
                    <Button startIcon={<PlusIcon />} variant="outlined">
                      Add offer
                    </Button>
                  </Link>
                ) : (
                  <span className="collectionDetails-content-title-actionText">
                    Find more{' '}
                    <Link href="/ui/content-display" target="_blank">
                      Offers
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div id="offers" className="collectionDetails-content-info">
              {!getValues('offers')?.length ? (
                <span className="collectionDetails-content-info-empty">No offers added yet.</span>
              ) : (
                <CollectionBoard
                  parentsWidth={calculatedInfoWidth}
                  widthEntity={200}
                  onSortEntities={(entities: TOrderedEntity[]) => {
                    setValue('offers', entities as IOrderedOffer[], { shouldDirty: true })
                  }}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeleteEntities({ entities, entityType: 'OFFER', prevQuantity: getValues('offers')?.length })
                  }
                  collectionId={collectionDetails.id}
                  entityType={'OFFER'}
                  heightEntity={300}
                  isCanDrag={!disableReordering}
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  items={getValues('offers') || []}
                />
              )}
            </div>
          </section>

          {/* WISHES */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Top Wishes
                <span className="collectionDetails-content-titleCount"> ({getValues('wishes')?.length || 0}/15) </span>
                {getValues('wishes')?.length < 15 ? (
                  <span className="collectionDetails-content-titleIcon">
                    <WarningIcon />
                  </span>
                ) : (
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                )}
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('wishes')?.length ? (
                  // todo когда в контент дисплее будет навигация к конкретной сущности, поменять на конкретную.
                  <Link href="/ui/content-display" target="_blank">
                    <Button startIcon={<PlusIcon />} variant="outlined">
                      Add wish
                    </Button>
                  </Link>
                ) : (
                  <span className="collectionDetails-content-title-actionText">
                    Find more{' '}
                    <Link href="/ui/content-display" target="_blank">
                      Wishes
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div id="wishes" className="collectionDetails-content-info">
              {!getValues('wishes')?.length ? (
                <span className="collectionDetails-content-info-empty">No wishes added yet.</span>
              ) : (
                <CollectionBoard
                  parentsWidth={calculatedInfoWidth}
                  widthEntity={200}
                  onSortEntities={(entities: TOrderedEntity[]) => {
                    setValue('wishes', entities as IOrderedWish[], { shouldDirty: true })
                  }}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeleteEntities({ entities, entityType: 'WISH', prevQuantity: getValues('wishes')?.length })
                  }
                  collectionId={collectionDetails.id}
                  entityType={'WISH'}
                  heightEntity={280}
                  isCanDrag={!disableReordering}
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  items={getValues('wishes') || []}
                />
              )}
            </div>
          </section>

          {/* EVENTS */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Top Events
                <span className="collectionDetails-content-titleCount"> ({getValues('events')?.length || 0}/15) </span>
                {getValues('events')?.length < 15 ? (
                  <span className="collectionDetails-content-titleIcon">
                    <WarningIcon />
                  </span>
                ) : (
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                )}
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('events')?.length ? (
                  // todo когда в контент дисплее будет навигация к конкретной сущности, поменять на конкретную.
                  <Link href="/ui/content-display" target="_blank">
                    <Button startIcon={<PlusIcon />} variant="outlined">
                      Add Event
                    </Button>
                  </Link>
                ) : (
                  <span className="collectionDetails-content-title-actionText">
                    Find more{' '}
                    <Link href="/ui/content-display" target="_blank">
                      Events
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div id="events" className="collectionDetails-content-info">
              {!getValues('events')?.length ? (
                <span className="collectionDetails-content-info-empty">No events added yet.</span>
              ) : (
                <CollectionBoard
                  parentsWidth={calculatedInfoWidth}
                  widthEntity={322}
                  onSortEntities={(entities: TOrderedEntity[]) => {
                    setValue('events', entities as IOrderedEvent[], { shouldDirty: true })
                  }}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeleteEntities({ entities, entityType: 'EVENT', prevQuantity: getValues('events')?.length })
                  }
                  collectionId={collectionDetails.id}
                  entityType={'EVENT'}
                  heightEntity={176}
                  isCanDrag={!disableReordering}
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  items={getValues('events') || []}
                />
              )}
            </div>
          </section>
        </div>
      </div>
      {isDeleteEntities && (
        <Modal customstyle={{ minHeight: 180 }} open={!!isDeleteEntities}>
          <DeleteEntities
            onClose={() => {
              setIsDeleteEntities(null)
            }}
            deleteEntities={handleDeleteEntities}
            entityType={isDeleteEntities.entityType}
            items={isDeleteEntities.entities}
            willUnpublishWarning={isDeleteEntities.entityType !== 'BANNER' && isDeleteEntities.prevQuantity - isDeleteEntities.entities.length < 15}
          />
        </Modal>
      )}
      {!!archiveCollections && (
        <Modal customstyle={{ minHeight: 208 }} open={!!archiveCollections}>
          <ArchiveCollectionsModal items={archiveCollections!} onClose={() => setArchiveCollections(null)} status={'idle'} />
        </Modal>
      )}
      {!!publishCollections && (
        <Modal customstyle={{ minHeight: 212 }} open={!!publishCollections}>
          <PublishCollectionsModal items={publishCollections!} onClose={() => setPublishCollections(null)} status={'idle'} />
        </Modal>
      )}
      {!!unpublishCollections && (
        <Modal customstyle={{ minHeight: 180 }} open={!!unpublishCollections}>
          <UnpublishCollectionModal item={unpublishCollections!} onClose={() => setUnpublishCollections(null)} />
        </Modal>
      )}
    </form>
  )
}

export default CollectionDetailsForm
