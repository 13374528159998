import './index.scss'

import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

import Alert from '@admin/components/shared/Alert/Alert'
import Autocomplete from '@admin/components/shared/Autocomplete/Autocomplete'
import Button from '@admin/components/shared/Button/Button'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import TextField from '@admin/components/shared/TextField/TextField'
import getValidationSchema from '@admin/components/WorldManagement/Collections/CollectionsModal/utils/getValidationSchema'
import { ICohort } from '@admin/store/cohortDetailsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { CommonErrorMessages, WorldText } from '@admin/store/types/ErrorMessages'
import { fetchAddCollection, selectErrorText } from '@admin/store/worldManagementSlice'

import type { TAny } from '@yzzy/types'

interface IProps {
  cohorts: ICohort[]
  onClose(): void
}

const AddCollectionModal = ({ cohorts, onClose }: IProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [cohortValue, setCohortValue] = useState<null | string>('')
  const [showFieldError, setShowFieldError] = useState({ isShow: false, message: '' })

  const dispatch = useAppDispatch()

  const statusText = useAppSelector(selectErrorText)

  const schema = useMemo(() => getValidationSchema('add'), [])
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    switch (statusText) {
      case CommonErrorMessages.UNEXPECTED_ERROR:
        setErrorMessage('Collection creation error, try again later')
        break
      case WorldText.DUPLICATE_TITLE:
        setShowFieldError({ isShow: true, message: 'Collection with the same name already exists ' })
        break
      default:
        setShowFieldError({ isShow: false, message: '' })
    }
  }, [statusText])

  const onSubmit = async (data: TAny) => {
    void trigger()

    try {
      const result = await dispatch(fetchAddCollection(data))

      if (result.meta.requestStatus === 'fulfilled') {
        onClose()
        enqueueSnackbar(`“${data.title}” collection has been created`, { variant: 'success' as VariantType })
      }
    } catch {
      setErrorMessage('Collection creation error, try again later')
    }
  }

  return (
    <form className="addCollectionModal" onSubmit={handleSubmit(onSubmit)}>
      <div className="addCollectionModal-header">Add new collection</div>
      <div className="addCollectionModal-content">
        <div>
          <TextField
            {...register('title')}
            fullWidth={true}
            label="Title*"
            name="title"
            defaultValue={''}
            error={!!errors.title || showFieldError.isShow}
            onBlur={() => trigger('title')}
            size="small"
          />
          {(errors.title || showFieldError.isShow) && (
            <FormHelperText error>{showFieldError.isShow ? showFieldError.message : (errors.title?.message as string)}</FormHelperText>
          )}
        </div>
        <Controller
          name="cohortId"
          render={() => (
            <Autocomplete
              fullWidth={true}
              label="Cohort*"
              inputprops={{
                error: !!errors.cohortId,
              }}
              onChange={(_event, value) => {
                setValue('cohortId', value ? value.id : null)
                setCohortValue(value ? value.title : null)
                void trigger('cohortId')
              }}
              onInputChange={(_event, value: null | string) => {
                setCohortValue(value)
              }}
              clearIcon={null}
              getOptionLabel={(option) => option.title || ''}
              inputValue={cohortValue ?? ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onBlur={() => trigger('cohortId')}
              options={[...cohorts]}
              size="small"
            />
          )}
          control={control}
        />
      </div>
      <div className="addCollectionModal-footer">
        {errorMessage && errorMessage !== '' && !showFieldError.isShow && <Alert severity="error">{errorMessage}</Alert>}
        <div className="addCollectionModal-footer-buttons">
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button color="primary" disabled={!isValid} type="submit" variant="contained">
            Add collection
          </Button>
        </div>
      </div>
    </form>
  )
}

export default AddCollectionModal
