import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import Autocomplete, { renderOptions } from '@admin/components/shared/Autocomplete/Autocomplete'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchSalesTaxCodesList, selectSalesTaxCodesList } from '@admin/store/slices/Settings/dictionariesSlice'
import { ITaxCode } from '@admin/store/slices/Settings/interface'
import { ITaxModerationCard, TaxModerationSolution } from '@admin/store/slices/TaxModeration/interface'
import { fetchChangeTaxModerationSolution } from '@admin/store/slices/TaxModeration/taxModerationSlice'
import { ModalAction } from '@admin/types/commonTypes'

import { ConfirmCancelModal } from './ConfirmCancelModal'
import getValidationSchema from './utils/getValidationSchema'

interface IChangeTaxCodeModalProps {
  currentSelectedSalesTax?: ITaxModerationCard | null
  onClose: (isSucceed: boolean) => void
  open: boolean
  shouldKeepTaxCode?: boolean
  withApproveOption?: boolean
}

export const ChangeTaxCodeModal = ({ currentSelectedSalesTax, onClose, open, shouldKeepTaxCode, withApproveOption }: IChangeTaxCodeModalProps) => {
  const dispatch = useAppDispatch()
  const schema = useMemo(() => getValidationSchema(), [])
  const { id: salesTaxModerationId } = useParams()
  const salesTaxCodesList = useAppSelector(selectSalesTaxCodesList)

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)

  /** Default tax code from backend. Not presented in **salesTaxCodesList** **/
  const URESOLVED_TAX_CODE = {
    id: 'URESOLVED',
    category: 'URESOLVED',
    code: 'URESOLVED',
    description: 'URESOLVED',
  }

  const presetValue = shouldKeepTaxCode
    ? {
        values: {
          salesTaxCode: currentSelectedSalesTax?.salesTaxCode as ITaxCode,
        },
      }
    : {}

  const {
    control,
    formState: { isValid, errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    ...presetValue,
    defaultValues: {
      salesTaxCode: {
        id: '',
        category: '',
        code: '',
        description: '',
      } as ITaxCode,
    },
  })

  const handleClose = (isSucceed: boolean) => {
    reset()
    onClose(isSucceed)
  }

  const onSubmit = async (data: { salesTaxCode: ITaxCode }, withApprove: boolean) => {
    trigger()

    const currentSelectedSalesTaxId = currentSelectedSalesTax ? currentSelectedSalesTax.id : null

    const formattedData = {
      newSalesTaxCodeId: data.salesTaxCode.id ? data.salesTaxCode.id : null,
      salesTaxModerationId: salesTaxModerationId ? salesTaxModerationId : currentSelectedSalesTaxId,
      solution: withApprove ? TaxModerationSolution.APPROVED : TaxModerationSolution.MODIFIED,
    }

    try {
      const result = await dispatch(fetchChangeTaxModerationSolution(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose(true)

        if (withApprove) {
          enqueueSnackbar(`Approved`, {
            variant: 'success' as VariantType,
          })
        } else {
          enqueueSnackbar(`Changes saved`, {
            variant: 'success' as VariantType,
          })
        }
      } else if (result.payload.meta) {
        const { title } = result.payload

        enqueueSnackbar(`An error occurred during card resolution: ${title}`, {
          variant: 'error' as VariantType,
        })
      } else {
        enqueueSnackbar('An error occurred during card resolution', {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar('An error occurred during card resolution', {
        variant: 'error' as VariantType,
      })
    }
  }

  const handleCancelClick = () => {
    if (isDirty) {
      setIsOpenConfirmCancelModal(true)
    } else {
      handleClose(false)
    }
  }

  const noApproveButtonText = withApproveOption ? 'Only change code' : 'Change'
  const isSalesTaxCode = watch('salesTaxCode.id') === currentSelectedSalesTax?.salesTaxCode.id
  const isNoApproveDisabled = !isValid || !isDirty || isSalesTaxCode

  const approveButtonText = currentSelectedSalesTax?.solution === TaxModerationSolution.MODIFIED ? 'Approve' : 'Change and approve'
  const isApproveDisabled = currentSelectedSalesTax?.solution === TaxModerationSolution.MODIFIED ? !isValid : !isValid || !isDirty || isSalesTaxCode

  useEffect(() => {
    dispatch(fetchSalesTaxCodesList())
  }, [])

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle variant="h6">Change to another tax code</DialogTitle>
        <DialogContent>
          <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} sx={{ paddingTop: 2 }} container>
            <SimpleGrid xs={12} item>
              <Controller
                name="salesTaxCode.category"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Sales tax category"
                      inputprops={{
                        error: !!errors.salesTaxCode?.category,
                      }}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true })
                          setValue('salesTaxCode.code', '', { shouldDirty: true })
                          setValue('salesTaxCode.id', '')
                          setValue('salesTaxCode.description', '')

                          return
                        }
                        setValue('salesTaxCode', value, { shouldDirty: true })
                        trigger('salesTaxCode')
                      }}
                      value={
                        field.value
                          ? (salesTaxCodesList.find((option) => {
                              return field.value === option.category
                            }) ?? URESOLVED_TAX_CODE)
                          : null
                      }
                      clearIcon={null}
                      getOptionLabel={({ category }) => category}
                      onBlur={() => trigger('salesTaxCode')}
                      options={salesTaxCodesList || []}
                      renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.category, inputValue)}
                      size="small"
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.category}>{errors.salesTaxCode?.category?.message as string}</FormHelperText>
                  </FormControl>
                )}
                control={control}
              />
            </SimpleGrid>

            <SimpleGrid xs={12} item>
              <Controller
                name="salesTaxCode.code"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Sales tax code"
                      inputprops={{
                        error: !!errors.salesTaxCode?.code,
                      }}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true })
                          setValue('salesTaxCode.code', '', { shouldDirty: true })
                          setValue('salesTaxCode.id', '')
                          setValue('salesTaxCode.description', '')

                          return
                        }
                        setValue('salesTaxCode', value, { shouldDirty: true })
                        trigger('salesTaxCode')
                      }}
                      value={
                        field.value
                          ? (salesTaxCodesList.find((option) => {
                              return field.value === option.code
                            }) ?? URESOLVED_TAX_CODE)
                          : null
                      }
                      clearIcon={null}
                      getOptionLabel={({ code }) => code}
                      onBlur={() => trigger('salesTaxCode')}
                      options={salesTaxCodesList || []}
                      renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.code, inputValue)}
                      size="small"
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.code}>{errors.salesTaxCode?.code?.message as string}</FormHelperText>
                  </FormControl>
                )}
                control={control}
              />
            </SimpleGrid>
            {watch('salesTaxCode.description') && (
              <SimpleGrid gap={1} xs={12} item>
                <Typography variant="subtitle1">Description</Typography>
                <Typography variant="body2">{watch('salesTaxCode.description')}</Typography>
              </SimpleGrid>
            )}
          </SimpleGrid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelClick} variant="text">
            Cancel
          </Button>
          <LoadingButton
            name="no_approve_btn"
            color="primary"
            disabled={isNoApproveDisabled}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onClick={handleSubmit((data) => onSubmit(data, false))}
            variant={withApproveOption ? 'outlined' : 'contained'}
          >
            {noApproveButtonText}
          </LoadingButton>
          {withApproveOption && (
            <LoadingButton
              name="approve_btn"
              color="primary"
              disabled={isApproveDisabled}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onClick={handleSubmit((data) => onSubmit(data, true))}
              variant="contained"
            >
              {approveButtonText}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmCancelModal
        action={ModalAction.EDIT}
        actionName="tax code"
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        onClose={() => handleClose(false)}
        open={isOpenConfirmCancelModal}
      />
    </>
  )
}
