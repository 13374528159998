import dayjs from 'dayjs'
import Carousel from 'react-material-ui-carousel'

import { Badge } from '@yzzy/ui'

import DashLine from '@admin/assets/img/DashLine'
import ZoomInIcon from '@admin/assets/img/ZoomInIcon'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import colors from '@admin/theme/constants/colors'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

import type { TAny } from '@yzzy/types'

const WIDTH_ENTITY = 322
const HEIGHT_ENTITY = 174
const DEFAULT_MEDIA_URL = 'https://www.svgrepo.com/show/33921/sea-turtle.svg'
const DEFAULT_LOCATION = 'New York'

interface ICardInfoProps {
  entityStatus: string
  format: 'OFFLINE' | 'ONLINE'
  locationInfo: {
    locationCity?: string
    locationFullAddress?: string
  }
  price: number
  priceOption: string
  startedAt: string
  title: string
  userInfoDto?: {
    avatarUrl?: string
    firstName?: string
    lastName?: string
  }
}

const CardInfo = ({ title, entityStatus, format, locationInfo, price, priceOption, startedAt, userInfoDto = {} }: ICardInfoProps) => {
  const isCancelled = entityStatus === 'CANCELLED'
  const isExpired = entityStatus === 'EXPIRED'
  const { avatarUrl, firstName, lastName } = userInfoDto

  const getLocation = () => locationInfo?.locationCity || locationInfo?.locationFullAddress || DEFAULT_LOCATION

  return (
    <div className="eventCard--data">
      <Stack className="eventCard--status" direction="row" gap="8px">
        {isCancelled && <Badge type="CANCELLED" />}
        {isExpired && <Badge type="EXPIRED" />}
        <Badge disabled={isExpired} type={format} />
      </Stack>
      <div className={`eventCard--name ${isExpired ? 'eventCard--name__disabled' : ''}`}>{title}</div>
      {userInfoDto && (
        <div className="eventCard--avatar">
          <AvatarItem
            levelAura="NONE"
            primaryText={firstName && lastName ? `${firstName} ${lastName}` : 'Unknown user'}
            sizes="small"
            src={avatarUrl}
            subText="Creator"
          />
        </div>
      )}
      <div className={`eventCard--location ${isExpired ? 'eventCard--location__disabled' : ''}`}>
        {dayjs(startedAt).format('MMM DD')} | {getLocation()}
      </div>
      <div className={`eventCard--price ${isExpired ? 'eventCard--price__disabled' : ''}`}>
        price: <span>{priceOption === 'FREE' ? 'free' : `${currencyFormatter(price, 'en-US', 'USD')}`}</span>
      </div>
    </div>
  )
}

const EventCard = (props: TAny) => {
  const { title, categoryName, entityStatus, format, locationInfo, orderedMediaList, price, priceOption, startedAt } = props.data
  const { userInfoDto } = props.data

  const isExpired = entityStatus === 'EXPIRED'

  return orderedMediaList.length > 1 ? (
    <Carousel
      activeIndicatorIconButtonProps={{ style: { color: colors.primary } }}
      animation="slide"
      autoPlay={false}
      className="eventCard--Carousel"
      height={300}
      indicatorContainerProps={{ style: { gap: '12px' } }}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: colors.actionDisabled } }}
      navButtonsAlwaysInvisible={true}
      swipe={false}
    >
      {orderedMediaList.map((element: TAny, index: number) => (
        <Stack key={element.order} gap={2} m="0 16px">
          {element.order === 0 && (
            <Typography color="text.primary" variant="caption">
              {categoryName}
            </Typography>
          )}
          <div className="carouselEntity">
            <div
              onClick={() => {
                props.showMedia(
                  element.mediaUrl ? element.mediaUrl : DEFAULT_MEDIA_URL,
                  element,
                  element.mediaType === 'VIDEO' ? 'video' : 'Image',
                  props.data.orderedMediaList ?? [],
                )
              }}
              style={{
                width: element.order === 0 ? '100%' : 200,
                height: element.order === 0 ? '100%' : 300,
              }}
              className="overlay"
            >
              <ZoomInIcon />
              VIEW MEDIA
            </div>
            <div className={element.order === 0 ? 'eventCard--container' : ''}>
              {element.order === 0 && (
                <CardInfo
                  title={title}
                  entityStatus={entityStatus}
                  format={format}
                  locationInfo={locationInfo}
                  price={price}
                  priceOption={priceOption}
                  startedAt={startedAt}
                  userInfoDto={userInfoDto}
                />
              )}
              <div
                className={`${element.order === 0 ? 'eventCard--image' : 'eventCard--background'}${isExpired ? ' eventCard--image__disabled' : ''}`}
              >
                <img
                  width={element.order === 0 ? WIDTH_ENTITY : 200}
                  alt="Event media"
                  height={element.order === 0 ? HEIGHT_ENTITY : 300}
                  src={element.mediaPreviewUrl || DEFAULT_MEDIA_URL}
                />
              </div>
              {element.order === 0 && (
                <div className="eventCard--dashLine">
                  <DashLine />
                </div>
              )}
            </div>
          </div>
        </Stack>
      ))}
    </Carousel>
  ) : (
    <Stack gap={2}>
      <Typography color="text.primary" variant="caption">
        {categoryName}
      </Typography>
      <div className="carouselEntity">
        <div
          onClick={() => {
            const mediaItem = props.data.orderedMediaList[0] || {
              mediaType: 'Image',
              mediaUrl: DEFAULT_MEDIA_URL,
            }

            props.showMedia(mediaItem.mediaUrl, mediaItem, mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image', props.data.orderedMediaList)
          }}
          className="overlay"
        >
          <ZoomInIcon />
          VIEW MEDIA
        </div>
        <div className="eventCard--container">
          <CardInfo
            title={title}
            entityStatus={entityStatus}
            format={format}
            locationInfo={locationInfo}
            price={price}
            priceOption={priceOption}
            startedAt={startedAt}
            userInfoDto={userInfoDto}
          />
          <div className={`eventCard--image ${isExpired ? 'eventCard--image__disabled' : ''}`}>
            <img alt="Event media" src={orderedMediaList[0]?.mediaPreviewUrl || DEFAULT_MEDIA_URL} />
          </div>
          <div className="eventCard--dashLine">
            <DashLine />
          </div>
        </div>
      </div>
    </Stack>
  )
}

export default EventCard
