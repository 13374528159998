import './index.scss'

import { ChangeEvent, useMemo, useState } from 'react'

import { TabList, TabPanel } from '@mui/lab'
import TabContext from '@mui/lab/TabContext/TabContext'
import { Avatar, Tab } from '@mui/material'
import { intervalToDuration } from 'date-fns'
import startCase from 'lodash/startCase'
import { createSearchParams, Link } from 'react-router-dom'

import CalendarIcon from '@admin/assets/img/CalendarIcon'
import EarnPendingWalletIcon from '@admin/assets/img/EarnPendingWalletIcon'
import ErrorIcon from '@admin/assets/img/ErrorIcon'
import FollowersIcon from '@admin/assets/img/FollowersIcon'
import GenderIcon from '@admin/assets/img/GenderIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import LikeIcon from '@admin/assets/img/LikeIcon'
import LinkIcon from '@admin/assets/img/LinkIcon'
import LocationIcon from '@admin/assets/img/LocationIcon'
import MessageIcon from '@admin/assets/img/MessageIcon'
import PhoneIcon from '@admin/assets/img/PhoneIcon'
import RestrictIcon from '@admin/assets/img/RestrictIcon'
import RestrictInProgressIcon from '@admin/assets/img/RestrictInProgressIcon'
import StarIcon from '@admin/assets/img/StarIcon'
import SubscriptionsIcon from '@admin/assets/img/SubscriptionsIcon'
import UserIcon from '@admin/assets/img/UserIcon'
import UsernameIcon from '@admin/assets/img/UsernameIcon'
import UserNoAvatarPreview from '@admin/assets/img/UserNoAvatarPreview.svg'
import VerifiedIcon from '@admin/assets/img/VerifiedIcon'
import VerifiedMonochromeIcon from '@admin/assets/img/VerifiedMonochromeIcon'
import VerifyBlockedIcon from '@admin/assets/img/VerifyBlockedIcon'
import VerifyLockedIcon from '@admin/assets/img/VerifyLockedIcon'
import VerifyPendingIcon from '@admin/assets/img/VerifyPendingIcon'
import VerifyReviewIcon from '@admin/assets/img/VerifyReviewIcon'
import WalletIcon from '@admin/assets/img/WalletIcon'
import WithdrawPendingWalletIcon from '@admin/assets/img/WithdrawPendingWalletIcon'
import WithdrawWalletIcon from '@admin/assets/img/WithdrawWalletIcon'
import Chip from '@admin/components/shared/Chip/Chip'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LinkMUI from '@admin/components/shared/Link/Link'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemAvatar from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemAvatar/ListItemAvatar'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import UserProfileDataBlock from '@admin/components/UserProfile/UserProfileDataBlock'
import { useAppSelector } from '@admin/store/hooks'
import { selectUserProfile } from '@admin/store/userProfileSlice'
import colors from '@admin/theme/constants/colors'
import { getAuraColor } from '@admin/utils//getAuraColor'

import type { TAny } from '@yzzy/types'

import Tooltip from '../shared/Tooltip/Tooltip'

const UserProfile = () => {
  const data = useAppSelector(selectUserProfile)

  const [tabValue, setTabValue] = useState('0')

  const handleTabChange = (event: ChangeEvent<{}>, value: string) => {
    setTabValue(value)
  }

  const localeOptions: TAny = { day: 'numeric', month: 'long', year: 'numeric' }

  const walletStatus = useMemo(() => {
    switch (data.paymentInfo.walletStatusCode) {
      case 'TPO_000':
        return { icon: <ErrorIcon />, statusText: 'No wallet' }
      case 'TPO_001':
        return { icon: <VerifyLockedIcon />, statusText: 'Blocked' }
      case 'TPO_002':
        return { icon: <VerifiedIcon />, statusText: 'Verified' }
      case 'TPO_003':
      case 'TPO_011':
        return { icon: <RestrictIcon />, statusText: 'Restricted' }
      case 'TPO_004':
        return { icon: <RestrictInProgressIcon />, statusText: 'Restricted soon' }
      case 'TPO_005':
        return { icon: <VerifyReviewIcon />, statusText: 'Under review' }
      case 'TPO_006':
        return { icon: <VerifiedMonochromeIcon />, statusText: 'Not verified' }
      case 'TPO_007':
      case 'TPO_008':
      case 'TPO_009':
      case 'TPO_010':
        return { icon: <VerifyBlockedIcon />, statusText: 'Not verified' }
      case 'TPO_014':
        return { icon: <VerifyPendingIcon />, statusText: 'Pending' }
      default:
        return { icon: <VerifyPendingIcon />, statusText: 'Pending' }
    }
  }, [data.paymentInfo.walletStatusCode])

  return (
    <>
      <div className="user-profile">
        <div className="user-profile__avatar">
          <Avatar src={data.main.avatarUrl || UserNoAvatarPreview} sx={{ width: 222, borderRadius: 4, height: 222, position: 'relative' }} />
          <div className="user-profile__avatar-name">
            <Typography color="text.primary" variant="body1">
              {data.personal.firstName + ' ' + data.personal.lastName}
            </Typography>
            {/*<Link href="/users" sx={data.main.status !== 'ACTIVE' ? { pointerEvents: 'none' } : {}}>*/}
            <Tooltip title="View profile" placement="top-end" followCursor>
              <IconButton size="small" disabled>
                <LinkIcon color="disabled" sx={{ opacity: 0.4 }} />
              </IconButton>
            </Tooltip>
            {/*</Link>*/}
          </div>
          {data.main.levelAura && (
            <div className="user-profile__avatar-aura" style={{ background: getAuraColor(data.main.levelAura).background }}>
              <Tooltip title="Aura level" placement="top-end" followCursor>
                <Typography color={colors.white} variant="subtitle2">
                  {data.main.levelAura && startCase(data.main.levelAura.toLowerCase())}
                </Typography>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="main">
          <div className="main-group">
            <Typography mb={2} variant="h6">
              System info
            </Typography>
            <UserProfileDataBlock primary="Username" secondary={data.main.username} withCopy />
            <UserProfileDataBlock primary="ID" secondary={data.main.userId} withCopy />
            {/*<UserProfileInfoBlock withCopy primary="Registration date" secondary={(data.personal.registrationDate || new Date()).toLocaleDateString('en-GB', localeOptions)} />*/}
          </div>
          <div className="main-group">
            <Typography mb={2} variant="h6">
              Violations
            </Typography>
            <UserProfileDataBlock primary="Profile" secondary={data.main.profileViolationsCount} />
            <UserProfileDataBlock primary="Content" secondary={data.main.contentViolationsCount} />
            <LinkMUI
              to={{
                pathname: `/ui/moderation/confirmed-violations`,
                search: `?${createSearchParams({
                  userId: data.main.userId,
                  userName: data.main.username,
                })}`,
              }}
              component={Link}
              sx={{ cursor: 'pointer' }}
              target="_blank"
              underline="none"
            >
              Show details
            </LinkMUI>
          </div>
          <div className="main-group">
            <Typography mb={2} variant="h6">
              Active paid content
            </Typography>
            <Stack alignItems="center" direction="row">
              <UserProfileDataBlock primary="Offers" secondary={data.main.activePaidContent?.offersCount} />
              <UserProfileDataBlock primary="Wishes" secondary={data.main.activePaidContent?.wishesCount} />
            </Stack>
            <Stack alignItems="center" direction="row">
              <UserProfileDataBlock primary="Lifestyle" secondary={data.main.activePaidContent?.lifestylesCount} />
              <UserProfileDataBlock primary="Events" secondary={data.main.activePaidContent?.eventsCount} />
            </Stack>
          </div>
          {/* <div className="main-group">
            <Typography variant="h6" mb={2}>
              Latest activity
            </Typography>
            <UserProfileDataBlock
              primary="Date"
              secondary={(data.personal?.latestActivityDate || new Date()).toLocaleDateString('en-GB', localeOptions)}
            />
            <UserProfileDataBlock
              primary="Location"
              secondary={data.personal?.latestActivityLocation || `Lisbon, Spain`}
            />
            <LinkMUI
              component={Link}
              underline="hover"
              sx={{ cursor: 'pointer' }}
              to={{
                pathname: `/users`,
              }}
            >
              Show history
            </LinkMUI>
          </div> */}
        </div>
      </div>
      <TabContext value={tabValue}>
        <TabList className="users-tabs" onChange={handleTabChange}>
          <Tab label="Personal" value="0" />
          <Tab label="Financial data" value="1" disabled />
        </TabList>
        <TabPanel sx={{ padding: 0 }} value="0">
          <Stack className="personal" gap={4}>
            <Stack>
              <Typography pb={1} variant="h6">
                Personal data
              </Typography>
              <Stack direction="row" gap="24px">
                <Stack width={0} flex="1 1">
                  <UserProfileDataBlock icon={<UserIcon />} primary="Name" secondary={data.personal.firstName + ' ' + data.personal.lastName} />
                  <UserProfileDataBlock icon={<UsernameIcon />} primary="Username" secondary={data.personal.username} />
                  <UserProfileDataBlock
                    secondary={
                      <>
                        {new Date(data.personal.birthDate).toLocaleDateString('en-GB', localeOptions)} (
                        {
                          intervalToDuration({
                            end: new Date(),
                            start: data.personal.birthDate === '' ? new Date() : new Date(data.personal.birthDate),
                          }).years
                        }{' '}
                        y.o.)
                      </>
                    }
                    icon={<CalendarIcon />}
                    primary="Date of birth"
                  />
                </Stack>
                <Stack width={0} flex="1 1">
                  <UserProfileDataBlock icon={<GenderIcon />} primary="Pronoun" secondary={data.personal.gender} />
                  <UserProfileDataBlock icon={<LikeIcon />} primary="Hustle" secondary={data.personal.occupation || 'Not specified'} />
                  <UserProfileDataBlock icon={<LocationIcon />} primary="Location" secondary={data.personal.location || 'Not specified'} />
                </Stack>
                <Stack width={0} flex="1 1">
                  <UserProfileDataBlock
                    hasEllipsis={false}
                    icon={<InfoIcon />}
                    primary="What im about"
                    secondary={data.personal?.aboutMe || <br />}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Stack direction="row" gap="24px">
                <Stack width={0} flex="1 1">
                  <Typography pb={1} variant="h6">
                    Connections
                  </Typography>
                </Stack>
                <Stack width={0} flex="1 1">
                  <Typography pb={1} variant="h6">
                    Contacts
                  </Typography>
                </Stack>
                <Stack width={0} flex="1 1" />
              </Stack>
              <Stack direction="row" gap="24px">
                <Stack width={0} flex="1 1">
                  <UserProfileDataBlock icon={<FollowersIcon />} primary="Followers" secondary={data.personal.followersCount || 0} />
                  <UserProfileDataBlock icon={<SubscriptionsIcon />} primary="Subscriptions" secondary={data.personal.subscriptionsCount || 0} />
                </Stack>
                <Stack width={0} flex="1 1">
                  <UserProfileDataBlock icon={<PhoneIcon />} primary="Phone" secondary={data.personal.phoneNumber} withCopy />
                  <UserProfileDataBlock icon={<MessageIcon />} primary="Email" secondary={data.personal.email} withCopy />
                </Stack>
                <Stack width={0} flex="1 1">
                  {/*<UserProfileDataBlock*/}
                  {/*  primary="Facebook"*/}
                  {/*  secondary={data.personal?.facebookId || 'Not linked'}*/}
                  {/*  icon={<FacebookIcon />}*/}
                  {/*/>*/}
                  {/*<UserProfileDataBlock*/}
                  {/*  primary="Instagram"*/}
                  {/*  secondary={data.personal.instagramId}*/}
                  {/*  icon={<InstagramIcon />}*/}
                  {/*/>*/}
                </Stack>
              </Stack>
            </Stack>
            <Stack className="passions" gap={1}>
              <Typography mb={1} variant="h6">
                Passions
              </Typography>
              <SimpleGrid alignContent="stretch" flexDirection="row" minHeight="100%" spacing={2} wrap="wrap" container>
                {data.personal?.passions?.map((item: string, index: number) => (
                  <SimpleGrid key={index} xs={3} item>
                    <ListItem disablePadding>
                      <ListItemAvatar sx={{ minWidth: 'auto', mr: 2 }}>
                        <Avatar sx={{ width: 24, bgcolor: 'transparent', height: 24 }}>
                          <StarIcon color="disabled" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography color="text.secondary" variant="body2">
                            {item}
                          </Typography>
                        }
                        disableTypography
                      />
                    </ListItem>
                  </SimpleGrid>
                ))}
              </SimpleGrid>
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value="1">
          <div className="personal">
            <div className="personalData--headerBlock gapped">
              <Typography color="text.primary" variant="h6">
                Payout wallet
              </Typography>
              <Chip
                label={walletStatus.statusText?.toUpperCase()}
                className={`userProfile--walletStatus${walletStatus.statusText === 'No wallet' ? ' no-wallet' : ''}`}
                icon={walletStatus.icon}
              />
            </div>
            <div className="personalData--infoContainer">
              <div className="personalData--infoBlock">
                <UserProfileDataBlock icon={<WalletIcon />} primary={`$${data.paymentInfo.balance}`} secondary="Balance" />
              </div>
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<WithdrawWalletIcon />}
                  primary={`$${data.paymentInfo.balanceAvailableWithdrawal}`}
                  secondary="Available to withdrawal"
                />
              </div>
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<WithdrawPendingWalletIcon />}
                  primary={`$${data.paymentInfo.balancePendingWithdrawal}`}
                  secondary="Pending (withdrawn)"
                />
              </div>
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<EarnPendingWalletIcon />}
                  primary={`$${data.paymentInfo.balancePendingEarned}`}
                  secondary="Pending (earned)"
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </>
  )
}

export default UserProfile
