import { useCallback, useEffect, useRef, useState } from 'react'

import { AnimatePresence, motion } from 'motion/react'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useErrorBoundary } from 'react-error-boundary'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import ArrowLeft from '@admin/assets/img/ArrowLeft'
import ArrowRight from '@admin/assets/img/ArrowRight'
import Button from '@admin/components/shared/Button/Button'
import Stack from '@admin/components/shared/Stack/Stack'
import { TaxModerationRoutes } from '@admin/routes/enum'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { TaxModerationSolution } from '@admin/store/slices/TaxModeration/interface'
import {
  fetchChangeTaxModerationSolution,
  fetchTaxModerationCard,
  fetchTaxModerationCards,
  removeApprovedCardId,
  selectTaxModerationCardDetails,
  selectTaxModerationCardIds,
  selectTaxModerationStatus,
  setTaxModerationStatus,
} from '@admin/store/slices/TaxModeration/taxModerationSlice'
import { LocalStorageKeys, Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderTaxModerationCard } from '../components'
import { taxModerationCardSolutions, taxModerationCardStatuses, TaxModerationListType } from '../constants'
import { ApprovalConfirmModal, ChangeTaxCodeModal } from '../modals'

export const TaxModerationCarousel = () => {
  const dispatch = useAppDispatch()
  const { id: cardId, listType } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const userPermissions = useAppSelector(selectUserPermissions)
  const taxModerationCardDetails = useAppSelector(selectTaxModerationCardDetails)
  const allIds = useAppSelector(selectTaxModerationCardIds)
  const status = useAppSelector(selectTaxModerationStatus)
  const isLoading = status === 'loading'

  const localData = localStorage.getItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP)
  const salesTaxApprovalConfirmSkip = localData !== null ? !!JSON.parse(localData) : false

  const [isOpenSalesTaxApprovalModal, setIsOpenSalesTaxApprovalModal] = useState<boolean>(false)
  const [isOpenChangeTaxCodeModal, setIsOpenChangeTaxCodeModal] = useState<boolean>(false)
  const [isNewTaxCode, setIsNewTaxCode] = useState<boolean>(false)
  const direction = useRef(0)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const { showBoundary } = useErrorBoundary()

  const paginateCard = (newDirection: number) => {
    direction.current = newDirection
  }

  const initialFetchData = useCallback(async () => {
    try {
      dispatch(setTaxModerationStatus('loading'))
      cardId && (await dispatch(fetchTaxModerationCard(cardId)).unwrap())
    } catch (error) {
      showBoundary(error)
    }
  }, [dispatch, cardId])

  const handleCloseTaxCodeModal = (isSucceed: boolean) => {
    setIsOpenChangeTaxCodeModal(false)

    setIsNewTaxCode(isSucceed)
    if (listType) {
      dispatch(
        fetchTaxModerationCards({
          listType: listType.toUpperCase() as TaxModerationListType,
          page: 0,
          pageSize: 25,
        }),
      )
    }
    setTimeout(() => {
      setIsNewTaxCode(false)
    }, 2000)
  }

  const handleApproveButtonClick = () => {
    if (!salesTaxApprovalConfirmSkip) {
      setIsOpenSalesTaxApprovalModal(true)
    } else void handleCardApproval()
  }

  const handleNavigateToNextCard = (withApprove?: boolean) => {
    if (cardId) {
      const currentIndex = allIds.indexOf(cardId)
      const nextIndex = currentIndex === allIds.length - 1 ? 0 : currentIndex + 1
      const nextId = allIds[nextIndex]

      if (withApprove) {
        dispatch(removeApprovedCardId(cardId))
        dispatch(
          fetchTaxModerationCards({
            listType: listType as TaxModerationListType,
            page: 0,
            pageSize: 25,
          }),
        ).then(({ payload }: TAny) => {
          if (payload?.allIds?.length === 0 && listType?.toUpperCase() === TaxModerationListType.UNRESOLVED) {
            navigate(TaxModerationRoutes.TAX_MODERATION_ALL_RESOLVED)
          }
        })
      }
      navigate(`/ui/tax-moderation/${listType}/details/${nextId}`)
      paginateCard(1)
    }
  }

  const handleNavigateToPreviousCard = () => {
    if (cardId && allIds.length) {
      const currentIndex = allIds.indexOf(cardId)
      const previousIndex = currentIndex === 0 ? allIds.length - 1 : currentIndex - 1
      const previousId = allIds[previousIndex]

      navigate(`/ui/tax-moderation/${listType}/details/${previousId}`)
      paginateCard(-1)
    }
  }

  const handleCardApproval = async () => {
    setIsOpenSalesTaxApprovalModal(false)

    const formattedData = {
      newSalesTaxCodeId: null,
      salesTaxModerationId: cardId ? cardId : null,
      solution: TaxModerationSolution.APPROVED,
    }

    try {
      const result = await dispatch(fetchChangeTaxModerationSolution(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Approved`, {
          autoHideDuration: 1000,
          variant: 'success' as VariantType,
        })

        if (cardId) {
          setTimeout(() => {
            handleNavigateToNextCard(true)
          }, 1000)
        }
      } else if (result.payload.meta) {
        const { title } = result.payload

        enqueueSnackbar(`An error occurred during card resolution: ${title}`, {
          variant: 'error' as VariantType,
        })
      } else {
        enqueueSnackbar('An error occurred during card resolution', {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar('An error occurred during card resolution', {
        variant: 'error' as VariantType,
      })
    }
  }

  const currentSolution = taxModerationCardSolutions.find(
    (element) => taxModerationCardDetails?.solution?.toLowerCase() === element.solution?.toLowerCase(),
  )
  const currentStatus = taxModerationCardStatuses.find((element) => taxModerationCardDetails?.status?.toLowerCase() === element.status?.toLowerCase())

  const isDisabledChangeButton =
    taxModerationCardDetails?.solution === TaxModerationSolution.APPROVED
      ? !checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_EDIT_SOLVED_DECISION])
      : !checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_UNRESOLVED_TAX_CHANGE_CODE])
  const isDisabledApproveButton =
    taxModerationCardDetails?.solution !== TaxModerationSolution.APPROVED &&
    checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_UNRESOLVED_APPROVE])
  const isDisabledScrollButton = allIds.length <= 1

  const variants = {
    center: {
      opacity: 1,
      x: 0,
    },
    enter: () => {
      return {
        opacity: 0,
        x: direction.current > 0 ? windowWidth : -windowWidth,
      }
    },
    exit: () => {
      return {
        opacity: 0,
        x: direction.current < 0 ? windowWidth : -windowWidth,
      }
    },
  }

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    initialFetchData()
  }, [location.pathname])

  return (
    <>
      <Stack className="tax-moderation-carousel" gap={2} height={1} mt={2}>
        <Stack className="tax-moderation-carousel__content" gap={2}>
          <AnimatePresence initial={false} mode="popLayout">
            <motion.div
              key={location.pathname}
              transition={{
                opacity: { duration: 0.5 },
                x: { duration: 1 },
              }}
              animate="center"
              exit="exit"
              initial="enter"
              variants={variants}
              layout
            >
              <RenderTaxModerationCard
                currentSolution={currentSolution}
                currentStatus={currentStatus}
                handleApproveButtonClick={handleApproveButtonClick}
                isDisabledApproveButton={isDisabledApproveButton}
                isDisabledChangeButton={isDisabledChangeButton}
                isLoading={isLoading}
                isNewTaxCode={isNewTaxCode}
                setIsOpenChangeTaxCodeModal={setIsOpenChangeTaxCodeModal}
                taxModerationCardDetails={taxModerationCardDetails}
              />
            </motion.div>
          </AnimatePresence>

          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Button color="primary" disabled={isDisabledScrollButton} onClick={handleNavigateToPreviousCard} size="medium" startIcon={<ArrowLeft />}>
              Previous card
            </Button>
            <Button
              color="primary"
              disabled={isDisabledScrollButton}
              endIcon={<ArrowRight />}
              onClick={() => handleNavigateToNextCard(false)}
              size="medium"
            >
              Next card
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <ApprovalConfirmModal
        onCardApproval={() => handleCardApproval()}
        onClose={() => setIsOpenSalesTaxApprovalModal(false)}
        open={isOpenSalesTaxApprovalModal}
      />
      <ChangeTaxCodeModal
        currentSelectedSalesTax={taxModerationCardDetails}
        onClose={(isSucceed) => handleCloseTaxCodeModal(isSucceed)}
        open={isOpenChangeTaxCodeModal}
        shouldKeepTaxCode
      />
    </>
  )
}
