import { ReactNode, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { selectIsAuth } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'

interface IProtectedRouteProps {
  children: ReactNode
}

export const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const navigate = useNavigate()
  const isAuth = useAppSelector(selectIsAuth)

  useEffect(() => {
    if (!isAuth) {
      navigate('/ui/login')
    }
  }, [isAuth, navigate])

  return children
}
