import './index.scss'

import { CSSProperties, useState } from 'react'

import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'

interface IImageCropProps {
  aspect: number
  image: string
  onCropComplete?: (croppedArea: Area, croppedAreaPixels: Area) => void
  onZoomChange?: (zoomNumber: number) => void
  style: {
    containerStyle?: CSSProperties
    cropAreaStyle?: CSSProperties
    mediaStyle?: CSSProperties
  }
  zoom?: number
}
const ImageCrop = ({ aspect, image, onCropComplete, onZoomChange, style, zoom, ...props }: IImageCropProps) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })

  return (
    <div className="crop-container">
      <Cropper
        aspect={aspect}
        crop={crop}
        image={image}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        style={style}
        zoom={zoom}
        {...props}
      />
    </div>
  )
}

export default ImageCrop
