import { Box, css, Stack, styled } from '@mui/material'

export const SContainer = styled(Stack)(
  ({ theme }) => css`
    .MuiDataGrid-root {
      background-color: ${theme.palette.common.white};
      border: none;
      border-radius: 8px;

      .MuiDataGrid-columnHeaders {
        background-color: ${theme.palette.common.white};
        padding: 0 8px 0 8px;
        border: none;

        .MuiDataGrid-columnSeparator {
          color: ${theme.palette.common.white};
        }

        .MuiDataGrid-columnHeader {
          padding: 0 16px;
          outline: none;
          border: none;

          &.username-header {
            padding-left: 76px;

            &--no-aura {
              padding-left: 68px;
            }
          }

          &.banned-header {
            padding-left: 60px;
          }
        }
      }

      .MuiDataGrid-cell {
        min-height: 48px;
        padding: 0 16px;
        margin: 0;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:focus,
        &:focus-within {
          outline: none;
        }

        .age {
          color: ${theme.palette.gray[400]};
        }
      }

      .MuiDataGrid-row {
        padding: 8px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(191, 198, 226, 0.16);
        }
      }

      .MuiDataGrid-overlay {
        pointer-events: all;
      }
    }
  `,
)

export const SAge = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.gray[400]};
  `,
)

export const SEmailContainer = styled(Box)(
  () => css`
    min-width: 0;
    white-space: wrap;
  `,
)

export const SEmail = styled(Box)(
  () => css`
    text-overflow: ellipsis;
    overflow: hidden;
  `,
)
