import './index.scss'

import TabContext from '@mui/lab/TabContext/TabContext'
import TabList from '@mui/lab/TabList/TabList'
import Tab from '@mui/material/Tab/Tab'
import { useDocumentTitle } from '@uidotdev/usehooks'
import find from 'lodash/find'
import { NavLink, NavLinkProps, Outlet } from 'react-router-dom'

import Typography from '@admin/components/shared/Typography/Typography'
import useRouteMatch from '@admin/hooks/useRouteMatch'
import { PaymentsSettingsRoutes } from '@admin/routes/enum'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

enum PaymentsSettingsTabs {
  IN_APP_TIERS = 'In App Tiers',
  PAYMENT_FEES = 'Payment Fees',
  TARIFFS = 'Tariffs',
  TAXES = 'Taxes',
}

type TSettingsTabsCollectionType = {
  label: PaymentsSettingsTabs
  className: string
  component: React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>
  disabled: boolean
  to: PaymentsSettingsRoutes
  value: PaymentsSettingsRoutes
}

const PaymentsSettings = () => {
  const userPermissions = useAppSelector(selectUserPermissions)

  const routeMatch = useRouteMatch(Object.values(PaymentsSettingsRoutes))
  const selectedTab =
    (routeMatch?.pattern?.path as string) === PaymentsSettingsRoutes.TARIFFS_DETAILS
      ? PaymentsSettingsRoutes.TARIFFS
      : (routeMatch?.pattern?.path as string) || PaymentsSettingsRoutes.TARIFFS

  const settingsTabsCollection: TSettingsTabsCollectionType[] = [
    {
      label: PaymentsSettingsTabs.TARIFFS,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_PAGE_TARIFF_GROUPS]),
      to: PaymentsSettingsRoutes.TARIFFS,
      value: PaymentsSettingsRoutes.TARIFFS,
    },
    {
      label: PaymentsSettingsTabs.PAYMENT_FEES,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_PAYMENT_FEES]),
      to: PaymentsSettingsRoutes.PAYMENT_FEES,
      value: PaymentsSettingsRoutes.PAYMENT_FEES,
    },
    {
      label: PaymentsSettingsTabs.IN_APP_TIERS,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_IN_APP_TIERS]),
      to: PaymentsSettingsRoutes.IN_APP_TIERS,
      value: PaymentsSettingsRoutes.IN_APP_TIERS,
    },
    {
      label: PaymentsSettingsTabs.TAXES,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TAXES_CODES, Permissions.SETTINGS_VIEW_TAXES_RULES]),
      to: PaymentsSettingsRoutes.TAXES,
      value: PaymentsSettingsRoutes.TAXES,
    },
  ]

  useDocumentTitle(`Settings - ${(find(settingsTabsCollection, { to: selectedTab }) as TSettingsTabsCollectionType | undefined)?.label}`)

  return (
    <div className="settings-container">
      <Typography variant="h6">Settings</Typography>
      <TabContext value={selectedTab}>
        <TabList className="settings-tabs">
          {settingsTabsCollection.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              className={tab.className}
              component={tab.component}
              disabled={tab?.disabled}
              to={tab.to}
              value={tab.value}
            />
          ))}
        </TabList>
      </TabContext>
      <Outlet />
    </div>
  )
}

export default PaymentsSettings
