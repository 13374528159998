import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import filter from 'lodash/filter'
import some from 'lodash/some'
import { enqueueSnackbar, VariantType } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

import Alert from '@admin/components/shared/Alert/Alert'
import Autocomplete, { renderOptions } from '@admin/components/shared/Autocomplete/Autocomplete'
import Button from '@admin/components/shared/Button/Button'
import NumericFormatCustom from '@admin/components/shared/CurrencyFormatCustom/NumericFormatCustom'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import TextField from '@admin/components/shared/TextField/TextField'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchPaymentsMethods,
  fetchProviders,
  fetchTransactionTypes,
  selectPaymentMethods,
  selectProviders,
  selectTransactionTypes,
} from '@admin/store/slices/Settings/dictionariesSlice'
import { IPaymentFee, IPaymentMethod, IProvider, ITransactionType } from '@admin/store/slices/Settings/interface'
import { fetchAddPaymentFee, fetchFeesConditions, selectAddingFeeStatus, selectFeesConditions } from '@admin/store/slices/Settings/paymentFeesSlice'
import { STRIPE_MAX_LIMIT } from '@admin/yzzy-constants'

import type { TAny } from '@yzzy/types'

import { ConfirmCancelModal } from './ConfirmCancelModal'
import getValidationSchema from './utils/getValidationSchema'

interface IAddFeeModalProps {
  onClose: (isAddedNewFee?: boolean) => void
  open: boolean
}

export const AddFeeModal = ({ onClose, open }: IAddFeeModalProps) => {
  const dispatch = useAppDispatch()
  const schema = useMemo(() => getValidationSchema(), [])

  const providers = useAppSelector(selectProviders)
  const paymentMethods = useAppSelector(selectPaymentMethods)
  const transactionTypes = useAppSelector(selectTransactionTypes)
  const feeConditions = useAppSelector(selectFeesConditions)
  const addingFeeStatus = useAppSelector(selectAddingFeeStatus)

  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | null>(null)
  const [selectedTransactionType, setSelectedTransactionType] = useState<ITransactionType | null>(null)
  const [isSelectedFeeFixedField, setIsSelectedFeeFixedField] = useState(false)
  const [isSelectedFeePercentageField, setIsSelectedFeePercentageField] = useState(false)

  const filteredTransactionTypes = useMemo(() => {
    return filter(transactionTypes, (type) => {
      return some(feeConditions, (condition) => {
        return condition.providerId === selectedProvider?.providerId && condition.transactionType === type.transactionTypeId
      })
    })
  }, [transactionTypes, feeConditions, selectedProvider])

  const filteredPaymentMethods = useMemo(() => {
    return filter(paymentMethods, (method) => {
      return some(feeConditions, (condition) => {
        return (
          condition.providerId === selectedProvider?.providerId &&
          condition.transactionType === selectedTransactionType?.transactionTypeId &&
          condition.paymentMethodId === method.paymentMethodId
        )
      })
    })
  }, [paymentMethods, feeConditions, selectedProvider, selectedTransactionType])

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      providerId: '',
      paymentFeeFixed: '',
      paymentFeePercentage: '',
      paymentMethodId: '',
      transactionType: '',
    },
    resolver: yupResolver(schema),
  })

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)

  const handleClose = (isAddedNewFee?: boolean) => {
    reset()
    setSelectedProvider(null)
    setSelectedPaymentMethod(null)
    setSelectedTransactionType(null)
    onClose(isAddedNewFee)
  }

  const onSubmit = async (
    data: Pick<IPaymentFee, 'paymentFeeFixed' | 'paymentFeePercentage' | 'paymentMethodId' | 'providerId' | 'transactionType'>,
  ) => {
    void trigger()

    try {
      const result = await dispatch(fetchAddPaymentFee(data))

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose(true)
        enqueueSnackbar('Payment fee has been added', {
          variant: 'success' as VariantType,
        })
      } else if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('An error occurred while adding the fee, try again again later', { variant: 'error' as VariantType })
      }
    } catch {
      enqueueSnackbar('An error occurred while adding the fee, try again again later', { variant: 'error' as VariantType })
    }
  }

  useEffect(() => {
    dispatch(fetchFeesConditions())
    dispatch(fetchProviders())
    dispatch(fetchPaymentsMethods())
    dispatch(fetchTransactionTypes())
  }, [dispatch])

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} disableScrollLock>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Add new fee</DialogTitle>
          <DialogContent>
            <Alert severity="info" sx={{ mb: 2 }}>
              All fields are required. Changing one of the parameters resets values in other fields.{' '}
            </Alert>
            <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} container>
              <SimpleGrid xs={12} item>
                <Controller
                  name="providerId"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        label="Provider"
                        inputprops={{
                          error: !!errors.providerId,
                        }}
                        onChange={(_, value) => {
                          setValue('providerId', value ? value.providerId : null, { shouldDirty: true })
                          setSelectedProvider(value)
                          void trigger('providerId')
                          resetField('transactionType')
                          setSelectedTransactionType(null)
                          resetField('paymentMethodId')
                          setSelectedPaymentMethod(null)
                        }}
                        clearIcon={null}
                        getOptionLabel={({ displayName }) => displayName}
                        isOptionEqualToValue={(option, value) => option.providerId === value.providerId}
                        onBlur={() => trigger('providerId')}
                        options={providers || []}
                        renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.displayName, inputValue)}
                        size="small"
                        value={selectedProvider}
                      />
                      <FormHelperText error={!!errors.providerId}>{errors.providerId?.message as string}</FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <Controller
                  name="transactionType"
                  render={() => (
                    <FormControl fullWidth>
                      <Tooltip title={!selectedProvider && 'You need to choose a Provider first'} placement="top-end" followCursor>
                        <Autocomplete
                          label="Transaction type"
                          inputprops={{
                            error: !!errors.transactionType,
                          }}
                          onChange={(_, value) => {
                            setValue('transactionType', value ? value.transactionTypeId : null, { shouldDirty: true })
                            setSelectedTransactionType(value)
                            void trigger('transactionType')
                            resetField('paymentMethodId')
                            setSelectedPaymentMethod(null)
                          }}
                          clearIcon={null}
                          disabled={!selectedProvider}
                          getOptionLabel={({ displayName }) => displayName}
                          isOptionEqualToValue={(option, value) => option.transactionTypeId === value.transactionTypeId}
                          onBlur={() => trigger('transactionType')}
                          options={filteredTransactionTypes || []}
                          renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.displayName, inputValue)}
                          size="small"
                          value={selectedTransactionType}
                        />
                      </Tooltip>
                      <FormHelperText error={!!errors.transactionType}>{errors.transactionType?.message as string}</FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <Controller
                  name="paymentMethodId"
                  render={() => (
                    <FormControl fullWidth>
                      <Tooltip title={!selectedTransactionType && 'You need to choose Transaction type first'} placement="top-end" followCursor>
                        <Autocomplete
                          label="Payment method"
                          inputprops={{
                            error: !!errors.paymentMethodId,
                          }}
                          onChange={(_, value) => {
                            setValue('paymentMethodId', value ? value.paymentMethodId : null, { shouldDirty: true })
                            setSelectedPaymentMethod(value)
                            void trigger('paymentMethodId')
                          }}
                          clearIcon={null}
                          disabled={!selectedTransactionType}
                          getOptionLabel={({ displayName }) => displayName}
                          isOptionEqualToValue={(option, value) => option.paymentMethodId === value.paymentMethodId}
                          onBlur={() => trigger('paymentMethodId')}
                          options={filteredPaymentMethods || []}
                          renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.displayName, inputValue)}
                          size="small"
                          value={selectedPaymentMethod}
                        />
                      </Tooltip>
                      <FormHelperText error={!!errors.paymentMethodId}>{errors.paymentMethodId?.message as string}</FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('paymentFeePercentage')}
                  fullWidth
                  label="Payment fee percentage"
                  name="paymentFeePercentage"
                  InputProps={{
                    inputComponent: NumericFormatCustom as TAny,
                    inputProps: {
                      decimalScale: 1,
                      max: 100,
                    },
                    startAdornment:
                      watch('paymentFeePercentage') || isSelectedFeePercentageField ? (
                        <InputAdornment position="start">
                          <Typography color="action.disabled" variant="body1">
                            %
                          </Typography>
                        </InputAdornment>
                      ) : undefined,
                  }}
                  onBlur={() => {
                    setIsSelectedFeePercentageField(false)
                    trigger('paymentFeePercentage')
                  }}
                  error={!!errors.paymentFeePercentage}
                  helperText={errors.paymentFeePercentage && (errors.paymentFeePercentage?.message as string)}
                  onFocus={() => setIsSelectedFeePercentageField(true)}
                  placeholder={isSelectedFeePercentageField ? '0' : undefined}
                  size="small"
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('paymentFeeFixed')}
                  fullWidth
                  label="Payment fee fixed"
                  name="paymentFeeFixed"
                  InputProps={{
                    inputComponent: NumericFormatCustom as TAny,
                    inputProps: {
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      max: STRIPE_MAX_LIMIT,
                    },
                    startAdornment:
                      watch('paymentFeeFixed') || isSelectedFeeFixedField ? (
                        <InputAdornment color="text.primary" position="start">
                          <Typography color="action.disabled" variant="body1">
                            $
                          </Typography>
                        </InputAdornment>
                      ) : undefined,
                  }}
                  onBlur={() => {
                    setIsSelectedFeeFixedField(false)
                    trigger('paymentFeeFixed')
                  }}
                  error={!!errors.paymentFeeFixed}
                  helperText={errors.paymentFeeFixed && (errors.paymentFeeFixed?.message as string)}
                  onFocus={() => setIsSelectedFeeFixedField(true)}
                  placeholder={isSelectedFeeFixedField ? '0.00' : undefined}
                  size="small"
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose()
              }}
              color="primary"
              variant="text"
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              loading={addingFeeStatus === 'loading'}
              type="submit"
              // disabled={!isValid || !isDirty}
              variant="contained"
            >
              Add fee
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal closeModal={() => setIsOpenConfirmCancelModal(false)} onClose={handleClose} open={isOpenConfirmCancelModal} />
    </>
  )
}
