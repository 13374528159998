import './index.scss'

import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import ContextIcon from '@admin/assets/img/ContextIcon'
import DeleteIcon from '@admin/assets/img/DeleteIcon'
import DragIndicator3 from '@admin/assets/img/DragIndicator3.png'
import ExtendIcon from '@admin/assets/img/ExtendIcon'
import WorldSendIcon from '@admin/assets/img/WorldSendIcon'
import ManageLinkedCollectionsModal from '@admin/components/ManageLinkedCollections/ManageLinkedCollectionsModal'
import UserProfileModal from '@admin/components/Moderation/UserProfileModal/UserProfileModal'
import Card from '@admin/components/shared/Card/Card'
import CardActionArea from '@admin/components/shared/Card/CardActionArea'
import CardContent from '@admin/components/shared/Card/CardContent'
import CardMedia from '@admin/components/shared/Card/CardMedia'
import Checkbox from '@admin/components/shared/Checkbox/Checkbox'
import Divider from '@admin/components/shared/Divider/Divider'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import BannerCard from '@admin/components/WorldManagement/Collections/CollectionDetails/CollectionEntity/BannerCard'
import HeroCard from '@admin/components/WorldManagement/Collections/CollectionDetails/CollectionEntity/HeroCard'
import { IOrderedHero, TOrderedBanner, TOrderedEntity } from '@admin/store/collectionDetailsSlice'
import { stringCapitalize } from '@admin/utils//stringCapitalize'

interface IProps {
  collectionId: string
  entity: TOrderedEntity
  entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'
  heightEntity: number
  isCanDrag: boolean
  isDefaultCollection: boolean
  isSelectedEntity: boolean
  setDeleteEntity(entity: string[]): void
  toggleSelectedEntity(entity: TOrderedEntity, isCheck: boolean): void
  widthEntity: number
}

const CollectionEntity = ({
  widthEntity,
  collectionId,
  entity,
  entityType,
  heightEntity,
  isCanDrag,
  isDefaultCollection = false,
  isSelectedEntity,
  setDeleteEntity,
  toggleSelectedEntity,
}: IProps) => {
  let card
  const { userId } = useParams()

  const [anchorElementContextMenu, setAnchorElementContextMenu] = useState<HTMLElement | null>(null)
  const [openLinkedCollections, setOpenLinkedCollections] = useState<null | TOrderedEntity>(null)
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false)

  const openContextMenu = Boolean(anchorElementContextMenu)
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementContextMenu(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElementContextMenu(null)
  }

  const viewDetails = () => {
    switch (entityType) {
      case 'BANNER':
        window.open(`/ui/world-management/banners/${entity.entityId}`, '_blank')
        break
      case 'EVENT':
        // todo сюда добавить модалочку эвента
        break
      case 'HERO':
        navigate(`${entity.entityId}`)
        setIsShowUserProfileModal(true)
        break
      case 'OFFER':
        // todo сюда добавить модалочку оффера
        break
      case 'WISH':
        // todo сюда добавить модалочку виша
        break
    }
  }

  useEffect(() => {
    if (userId) {
      setIsShowUserProfileModal(true)
    }
  }, [])

  switch (entityType) {
    case 'BANNER': {
      card = <BannerCard width={widthEntity} entity={entity as TOrderedBanner} height={heightEntity} />
      break
    }
    case 'HERO': {
      card = <HeroCard width={widthEntity} entity={entity as IOrderedHero} height={heightEntity} />
      break
    }
    // todo сюда добавить отображение остальных карточек
    default: {
      card = (
        <Card>
          <CardActionArea>
            <CardMedia
              width={widthEntity}
              alt="green iguana"
              component="img"
              height={heightEntity}
              image={('cover' in entity && entity.cover?.originalLink) || ''}
            />
            <CardContent />
          </CardActionArea>
        </Card>
      )
    }
  }

  return (
    <div
      className={`collectionEntity ${entity.entityStatus === 'UNAVAILABLE' ? 'collectionEntity_unavailable' : ''} ${isSelectedEntity ? 'collectionEntity_selected' : ''}`}
    >
      {entity.entityStatus !== 'ACTIVE' && (
        <div className="entityStatusOverlay">
          <div className={`entityStatusOverlay-label entityStatusOverlay-label_${entity.entityStatus.toLowerCase()}`}>
            {entityType === 'BANNER' && entity.entityStatus === 'UNAVAILABLE' ? 'Expired' : stringCapitalize(entity.entityStatus)}
          </div>
        </div>
      )}
      <div className="entityContainer" style={{ width: widthEntity, height: heightEntity + 38 }}>
        <div className="entityContainer-actions">
          <FormControlLabel
            label=""
            control={<Checkbox checked={isSelectedEntity} onChange={(_event, checked) => toggleSelectedEntity(entity, checked)} />}
          />
          <div className="entityContextMenu">
            <IconButton
              id="entityAction-button"
              aria-controls={openContextMenu ? 'entity-menu' : undefined}
              aria-expanded={openContextMenu ? 'true' : undefined}
              aria-haspopup="true"
              className="entityAction"
              onClick={handleClick}
            >
              <ContextIcon />
            </IconButton>
            <Menu
              id="entity-menu"
              aria-labelledby="entityAction-button"
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
              anchorEl={anchorElementContextMenu}
              className="entityContextMenu-menu"
              onClose={handleClose}
              open={openContextMenu}
            >
              <MenuItem
                onClick={() => {
                  handleClose()
                  viewDetails()
                }}
              >
                <ExtendIcon />
                View details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose()
                  setOpenLinkedCollections(entity)
                }}
              >
                <WorldSendIcon />
                Manage linked collections
              </MenuItem>
              <Divider flexItem />
              <MenuItem
                onClick={() => {
                  handleClose()
                  setDeleteEntity([entity.entityId])
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="overlay" style={{ width: widthEntity, height: heightEntity }}>
          {isCanDrag && (
            <>
              <img alt="drag" src={DragIndicator3} />
              drag to change order
            </>
          )}
        </div>
        {card}
        {isDefaultCollection && <div className="entityContainer-additionalInfo">Added {new Date(entity.createdAt).toLocaleDateString()}</div>}
      </div>
      {openLinkedCollections && (
        <ManageLinkedCollectionsModal
          entityId={openLinkedCollections.entityId}
          entityType={entityType}
          onClose={() => setOpenLinkedCollections(null)}
          open={!!openLinkedCollections}
          shouldDisabledCollection={collectionId}
        />
      )}
      {isShowUserProfileModal && (
        <UserProfileModal
          onClose={() => {
            setIsShowUserProfileModal(false)
            navigate(`/ui/world-management/collections/${collectionId}`)
          }}
          open={isShowUserProfileModal}
        />
      )}
    </div>
  )
}

export default CollectionEntity
