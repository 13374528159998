import './index.scss'

import { TabContext, TabList } from '@mui/lab'
import { Tab } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import find from 'lodash/find'
import { NavLink, NavLinkProps, Outlet } from 'react-router-dom'

import Divider from '@admin/components/shared/Divider/Divider'
import Typography from '@admin/components/shared/Typography/Typography'
import useRouteMatch from '@admin/hooks/useRouteMatch'
import { PaymentsRoutes } from '@admin/routes/enum'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

enum PaymentsTabs {
  BULK_PAYOUTS = 'Bulk Payouts',
  FINANCE_DATA = 'Finance Data',
  OPERATIONS = 'Operations',
  USER_FINANCE_DATA = 'User Finance Data',
}

type TPaymentsTabsCollectionType = {
  label: PaymentsTabs
  className: string
  component: React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>
  disabled: boolean
  to: PaymentsRoutes
  value: PaymentsRoutes
}

const Payments = () => {
  const userPermissions = useAppSelector(selectUserPermissions)

  const routeMatch = useRouteMatch(Object.values(PaymentsRoutes))
  const selectedTab = (routeMatch?.pattern?.path as string) || PaymentsRoutes.OPERATIONS

  const paymentsTabsCollection: TPaymentsTabsCollectionType[] = [
    {
      label: PaymentsTabs.OPERATIONS,
      className: 'payments-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.PAYMENTS_VIEW_OPERATIONS]),
      to: PaymentsRoutes.OPERATIONS,
      value: PaymentsRoutes.OPERATIONS,
    },
    {
      label: PaymentsTabs.BULK_PAYOUTS,
      className: 'payments-tabs__tab',
      component: NavLink,
      // disabled: !checkPermissions(userPermissions, [Permissions.PAYMENTS_VIEW_PAGE_BULK_PAYOUTS]),
      disabled: true,
      to: PaymentsRoutes.BULK_PAYOUTS,
      value: PaymentsRoutes.BULK_PAYOUTS,
    },
    {
      label: PaymentsTabs.FINANCE_DATA,
      className: 'payments-tabs__tab',
      component: NavLink,
      disabled: true,
      to: PaymentsRoutes.FINANCE_DATA,
      value: PaymentsRoutes.FINANCE_DATA,
    },
    {
      label: PaymentsTabs.USER_FINANCE_DATA,
      className: 'payments-tabs__tab',
      component: NavLink,
      disabled: true,
      to: PaymentsRoutes.USER_FINANCE_DATA,
      value: PaymentsRoutes.USER_FINANCE_DATA,
    },
  ]

  useDocumentTitle(`Payments - ${(find(paymentsTabsCollection, { to: selectedTab }) as TPaymentsTabsCollectionType | undefined)?.label}`)

  return (
    <div className="payments-container">
      <Typography variant="h6">Payments</Typography>
      <TabContext value={selectedTab}>
        <TabList className="payments-tabs">
          {paymentsTabsCollection.slice(0, 2).map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              className={tab.className}
              component={tab.component}
              disabled={tab.disabled}
              to={tab.to}
              value={tab.value}
            />
          ))}
          <Divider className="payments-tabs__divider" orientation="vertical" flexItem />
          {paymentsTabsCollection.slice(2).map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              className={tab.className}
              component={tab.component}
              disabled={tab.disabled}
              to={tab.to}
              value={tab.value}
            />
          ))}
        </TabList>
      </TabContext>
      <Outlet />
    </div>
  )
}

export default Payments
