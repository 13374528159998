import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import type { components, operations } from '@admin/shared/api/@types/generated/tim-profile-profile-api'

const getWaitScreenUsers = async (parameters: operations['getAwaitingUsers']['parameters']['query']) =>
  axios.get<components['schemas']['AdminAwaitingBetaUsersResponse']>('/moderation/tim-profile-profile-api/api/v2/waiting-list', {
    params: parameters,
  })

export const useGetWaitScreenUsers = (page: number, size: number) =>
  useQuery({ queryFn: () => getWaitScreenUsers({ page, size }), queryKey: ['wait-screen-users', page, size] })
