import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AnimatePresence, motion } from 'motion/react'
import { useErrorBoundary } from 'react-error-boundary'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ArrowBack from '@admin/assets/img/ArrowBack'
import ArrowRight from '@admin/assets/img/ArrowRight'
import ChevronRightIcon from '@admin/assets/img/ChevronRightIcon'
import Breadcrumbs from '@admin/components/shared/Breadcrumbs/Breadcrumbs'
import Button from '@admin/components/shared/Button/Button'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LinkMUI from '@admin/components/shared/Link/Link'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { ConsumptionRequestsRoutes } from '@admin/routes/enum'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { selectConsumptionRequestCasesIds } from '@admin/store/slices/ConsumptionRequests/consumptionRequestsSlice'
import { fetchOperationDetails } from '@admin/store/slices/Payments/operationsSlice'

import { RenderConsumptionRequest } from '../components'
import { ConfirmCancelModal } from '../modals'
import getValidationSchema from './utils/getValidationSchema'

type TFormValues = {
  appleConsumptionRequestData: {
    accountTenure: string
    consumptionStatus: string
    deliveryStatus: string
    lifetimeDollarsPurchased: string
    lifetimeDollarsRefunded: string
    playTime: string
    userStatus: string
  }
  comment: string
  consumptionRequestType: string
  reason: string
}

export const ConsumptionRequestsCarousel = () => {
  const dispatch = useAppDispatch()
  const { id: requestId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParameters] = useSearchParams()
  const operationId = searchParameters.get('operationId')
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState<boolean>(false)
  const isBackButtonClickedReference = useRef<boolean>(false)
  const { showBoundary } = useErrorBoundary()
  // const userPermissions = useAppSelector(selectUserPermissions);
  const allIds = useAppSelector(selectConsumptionRequestCasesIds)
  // const status = useAppSelector(selectConsumptionRequestCasesStatus);
  // let isLoading = status === 'loading';

  const direction = useRef(0)
  const paginateCard = (newDirection: number) => {
    direction.current = newDirection
  }

  const initialFetchData = useCallback(async () => {
    try {
      if (operationId) {
        await dispatch(fetchOperationDetails(operationId)).unwrap()
      }
    } catch (error) {
      showBoundary(error)
    }
  }, [dispatch, operationId])

  //TODO сейчас в allIds - массив operationId
  const getNextRequestId = (currentId: string) => {
    const currentIndex = allIds.indexOf(currentId)
    const nextIndex = currentIndex === allIds.length - 1 ? 0 : currentIndex + 1

    return allIds[nextIndex]
  }

  const handleCloseConfirmModal = () => {
    reset()
    if (requestId) {
      const nextId = getNextRequestId(requestId)

      navigate(`/ui/consumption-requests/details/${nextId}`)
    }

    // additional navigation when pressing the back button.
    if (isBackButtonClickedReference.current) {
      navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS)
      isBackButtonClickedReference.current = false
    }
  }

  const handleNavigateToNextRequest = async () => {
    if (!requestId) return

    if (!isValid && !isDirty) {
      const nextId = getNextRequestId(requestId)

      navigate(`/ui/consumption-requests/details/${nextId}`)
      paginateCard(1)
    }

    if (isDirty) {
      // await trigger();
      setIsOpenConfirmCancelModal(true)
    }
  }

  const handleNavigateToBack = () => {
    isBackButtonClickedReference.current = true

    if (!isDirty) {
      navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS)
    }

    if (isDirty) {
      // await trigger();
      setIsOpenConfirmCancelModal(true)
    }
  }

  // const isDisabledScrollButton = !(allIds.length > 1);

  const variants = {
    center: {
      opacity: 1,
      x: 0,
    },
    enter: () => {
      return {
        opacity: 0,
        x: direction.current > 0 ? 1000 : -1000,
      }
    },
    exit: () => {
      return {
        opacity: 0,
        x: direction.current < 0 ? 1000 : -1000,
      }
    },
  }

  useEffect(() => {
    initialFetchData()
  }, [location.pathname])

  const schema = useMemo(() => getValidationSchema(), [])

  const {
    formState: { isValid, errors, isDirty },
    getFieldState,
    getValues,
    register,
    reset,
    setError,
    setValue,
    trigger,
  } = useForm<TFormValues>({
    defaultValues: {
      appleConsumptionRequestData: {
        accountTenure: '',
        consumptionStatus: '',
        deliveryStatus: '',
        lifetimeDollarsPurchased: '',
        lifetimeDollarsRefunded: '',
        playTime: '',
        userStatus: '',
      },
      comment: '',
      consumptionRequestType: 'APPLE',
      reason: '',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(schema),
  })

  return (
    <Stack className="consumption-requests-carousel" gap={2} mt={2}>
      <div className="consumption-requests-carousel__breadcrumbs">
        <div onClick={handleNavigateToBack}>
          <IconButton color="default" size="small">
            <ArrowBack />
          </IconButton>
        </div>
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <LinkMUI color="text.secondary" component={Link} to={ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS} underline="hover" variant="h6">
            Table
          </LinkMUI>
          <Typography color="text.primary" variant="h6">
            {`Solving request / Case ID: ${requestId} `}
          </Typography>
        </Breadcrumbs>
      </div>

      <Stack className="consumption-requests-carousel__content" gap={2}>
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key={location.pathname}
            transition={{
              opacity: { duration: 0.2 },
              x: { damping: 30, stiffness: 300, type: 'spring' },
            }}
            animate="center"
            exit="exit"
            initial="enter"
            variants={variants}
          >
            <RenderConsumptionRequest
              useFormMethods={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formState: { isValid, errors, isDirty },
                getFieldState,
                getValues,
                register,
                reset,
                setError,
                setValue,
                trigger,
              }}
              onNavigateToNextRequest={handleNavigateToNextRequest}
            />
          </motion.div>
        </AnimatePresence>

        <Stack alignItems="center" direction="row" justifyContent="flex-end">
          <Button
            color="primary"
            endIcon={<ArrowRight />}
            onClick={handleNavigateToNextRequest}
            size="medium"
            disabled
            // disabled={isDisabledScrollButton}
          >
            Next request
          </Button>
        </Stack>
      </Stack>
      <ConfirmCancelModal closeModal={() => setIsOpenConfirmCancelModal(false)} onClose={handleCloseConfirmModal} open={isOpenConfirmCancelModal} />
    </Stack>
  )
}
