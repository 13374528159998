import { ChangeEvent, forwardRef, Key, ReactNode, useEffect, useState } from 'react'

import { Fade, Paper, Popper, useAutocomplete } from '@mui/material'
import { useDebounce } from '@uidotdev/usehooks'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import isEmpty from 'lodash/isEmpty'
import { useNavigate, useParams } from 'react-router-dom'

import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Divider from '@admin/components/shared/Divider/Divider'
import List from '@admin/components/shared/List/List'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemButton from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemButton/ListItemButton'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SearchField from '@admin/components/shared/SearchField/SearchField'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { TaxModerationRoutes } from '@admin/routes/enum'
import { clearFilters, removeFilter, selectFiltersCurrentData, setCustomFilterValue } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { ITaxModerationSearchItem } from '@admin/store/slices/TaxModeration/interface'
import {
  clearSearchValue,
  clearTaxModerationSearch,
  fetchTaxModerationSearch,
  selectSearchValue,
  selectTaxModerationSearchAllResults,
  selectTaxModerationSearchResults,
  setSearchValue,
} from '@admin/store/slices/TaxModeration/taxModerationSlice'

import type { TAny } from '@yzzy/types'

interface ITaxModerationSearchProps {
  clearFilter?: () => Promise<void>
  listType: string
  refreshData?: () => Promise<void>
}

export const TaxModerationSearch = ({ clearFilter, listType, refreshData }: ITaxModerationSearchProps) => {
  const dispatch = useAppDispatch()
  const { id: cardId } = useParams()
  const navigate = useNavigate()

  const SEARCH_FILTER = {
    title: 'Sales tax moderation search by entity name',
    columnId: 'entity_name',
    element: {
      titles: [''],
      value: '',
    },
    type: 'EQUALS',
    valueType: 'STRING',
  }

  const SEARCH_FILTER_ALL = {
    title: 'Sales tax moderation search by part of entity name',
    columnId: 'sales_tax_moderation_search_by_part_of_entity_name',
    customImplementation: true,
    element: {
      titles: [''],
      value: '',
    },
    type: 'EQUALS',
    valueType: 'STRING',
  }

  const searchValue = useAppSelector(selectSearchValue)
  const taxModerationSearchResults = useAppSelector(selectTaxModerationSearchResults)
  const taxModerationSearchAllResults = useAppSelector(selectTaxModerationSearchAllResults)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isSearchError, setIsSearchError] = useState(false)
  const debouncedSearchValue = useDebounce(searchValue.trim(), 300)

  const { getInputProps, getListboxProps, getOptionProps, getRootProps, inputValue } = useAutocomplete({
    id: 'search-autocomplete',
    disableCloseOnSelect: true,
    getOptionLabel: (option) => option.sameTitle,
    inputValue: searchValue,
    options: taxModerationSearchResults ? taxModerationSearchResults : [],
  })

  const hasFiltersToClear =
    refreshData && (!isEmpty(filtersCurrentData.sales_tax_moderation_search_by_part_of_entity_name) || !isEmpty(filtersCurrentData.entity_name))

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    dispatch(setSearchValue(inputValue))

    if ((event.target.value.length === 0 || inputValue.length < 3) && taxModerationSearchResults.length !== 0) {
      dispatch(clearTaxModerationSearch())
    }

    if (inputValue.length === 0 && hasFiltersToClear) {
      dispatch(clearTaxModerationSearch())
      dispatch(removeFilter({ columnId: 'sales_tax_moderation_search_by_part_of_entity_name' }))
      dispatch(removeFilter({ columnId: 'entity_name' }))
      void refreshData()
    }
  }

  const handleClearEvent = () => {
    dispatch(clearSearchValue())
    dispatch(clearTaxModerationSearch())

    if (hasFiltersToClear) {
      dispatch(removeFilter({ columnId: 'sales_tax_moderation_search_by_part_of_entity_name' }))
      dispatch(removeFilter({ columnId: 'entity_name' }))
      void refreshData()
    }
  }

  const handleSearchEvent = async () => {
    setIsLoadingSearch(true)

    try {
      const result = await dispatch(
        fetchTaxModerationSearch({
          listType: listType,
          searchValue: debouncedSearchValue,
        }),
      )

      if (result.meta.requestStatus === 'fulfilled') {
        setIsSearchError(false)
        setIsLoadingSearch(false)
      } else if (result.meta.requestStatus === 'rejected') {
        setIsSearchError(true)
        setIsLoadingSearch(false)
      }
    } catch {
      setIsSearchError(true)
      setIsLoadingSearch(false)
    }
  }

  const handleListItemButtonClick = (option: ITaxModerationSearchItem) => {
    setSearchValue(option.sameTitle)
    setAnchorElement(null)

    SEARCH_FILTER.element.value = option.sameTitle
    SEARCH_FILTER.element.titles = option.ids.map((id) => id)
    dispatch(clearFilters())
    dispatch(setCustomFilterValue(SEARCH_FILTER))

    if (cardId) {
      navigate(TaxModerationRoutes.TAX_MODERATION)
    }
    if (refreshData) {
      void refreshData()
    }
  }

  const handleAllResultsClick = () => {
    setSearchValue(searchValue)
    setAnchorElement(null)

    if (taxModerationSearchAllResults.length) {
      SEARCH_FILTER_ALL.element.value = searchValue
      SEARCH_FILTER_ALL.element.titles = taxModerationSearchAllResults
    }
    dispatch(clearFilters())
    dispatch(setCustomFilterValue(SEARCH_FILTER_ALL))

    if (cardId) {
      navigate(TaxModerationRoutes.TAX_MODERATION)
    }
    if (refreshData) {
      void refreshData()
    }
  }

  const renderOptions = (option: ITaxModerationSearchItem, index: number): ReactNode => {
    const matches = match(option.sameTitle, inputValue, { insideWords: true })
    const parts = parse(option.sameTitle, matches)

    return (
      <ListItem {...getOptionProps({ index, option })} key={`${option.sameTitle}-${index}`} disablePadding>
        <ListItemButton onClick={() => handleListItemButtonClick(option)}>
          <ListItemText
            primary={
              <Typography
                sx={{
                  wordBreak: 'break-word',
                }}
                variant="body2"
              >
                {parts.map((part, index: Key) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                {option.ids.length > 1 ? (
                  <span key={index} style={{ fontWeight: 700 }}>
                    {` (${option.ids.length})`}
                  </span>
                ) : (
                  ''
                )}
              </Typography>
            }
            disableTypography
          />
        </ListItemButton>
      </ListItem>
    )
  }

  const renderContent = () => {
    if (isSearchError) {
      return (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      wordBreak: 'break-all',
                    }}
                    component="p"
                    variant="body2Bold"
                  >
                    {inputValue}{' '}
                    {taxModerationSearchAllResults.length && taxModerationSearchAllResults.length > 0
                      ? `(${taxModerationSearchAllResults.length})`
                      : ''}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" component="p" variant="caption">
                    All results
                  </Typography>
                }
                sx={{ m: 0 }}
                disableTypography
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />

          <Stack alignItems="center" pt={1} spacing={1}>
            <Typography color="error" variant="body2">
              Loading results error
            </Typography>
            <LoadingButton
              color="primary"
              loading={isLoadingSearch}
              loadingPosition="start"
              onClick={handleSearchEvent}
              startIcon={null}
              sx={{ minWidth: 105, p: 0 }}
              variant="text"
            >
              Retry
            </LoadingButton>
          </Stack>
        </>
      )
    }

    if (isLoadingSearch) {
      return (
        <Stack alignItems="center" p="8px 0">
          <CircularProgress color="primary" size={24} sx={{ margin: 'auto' }} />
        </Stack>
      )
    }

    if (inputValue.length > 2 && taxModerationSearchResults && taxModerationSearchResults.length > 0) {
      return (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      wordBreak: 'break-all',
                    }}
                    component="p"
                    variant="body2Bold"
                  >
                    {`${inputValue} (${taxModerationSearchAllResults.length})`}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" component="p" variant="caption">
                    All results
                  </Typography>
                }
                sx={{ m: 0 }}
                disableTypography
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />

          {taxModerationSearchResults.map((result, index) => renderOptions(result, index))}
        </>
      )
    }

    if (inputValue.length < 3) {
      return (
        <Typography color="text.primary" p="8px 0" textAlign="center" variant="body2">
          Enter at least 3 symbols
        </Typography>
      )
    }

    return (
      <Stack alignItems="center" gap={1} p="8px 0">
        <Typography color="text.primary" variant="body2">
          There are no matches.
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Try a different query.
        </Typography>
      </Stack>
    )
  }

  const RenderList = forwardRef<TAny, {}>((_, reference) => {
    return (
      <List {...getListboxProps()} ref={reference}>
        {renderContent()}
      </List>
    )
  })

  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      void handleSearchEvent()
    }
  }, [debouncedSearchValue.length, listType])

  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      void handleSearchEvent()
    }
  }, [debouncedSearchValue])

  return (
    <div id="tax-moderation-search" {...getRootProps()}>
      <SearchField
        customWidth="476px"
        label="Search by name"
        clearEvent={handleClearEvent}
        inputProps={{ ...getInputProps() }}
        maxlength={50}
        onBlur={() => setAnchorElement(null)}
        onChange={handleChangeSearch}
        onClick={(event) => setAnchorElement(event.currentTarget)}
        searchEvent={handleSearchEvent}
        searchValue={inputValue}
      />

      <Popper
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        placement="bottom-start"
        sx={{ width: anchorElement?.offsetWidth || 476, borderRadius: '4px', zIndex: 1200 }}
        disablePortal
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <Paper elevation={4} sx={{ backgroundColor: 'background.paper', maxHeight: '393px', overflow: 'auto' }}>
              <RenderList />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}
