// todo: fixed yup schema
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { createFilterOptions } from '@mui/material'
import isObject from 'lodash/isObject'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import PlusIcon from '@admin/assets/img/PlusIcon'
import Autocomplete, { renderOptions } from '@admin/components/shared/Autocomplete/Autocomplete'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import Checkbox from '@admin/components/shared/Checkbox/Checkbox'
import Chip from '@admin/components/shared/Chip/Chip'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchCertainties,
  fetchEntityTypes,
  fetchEventCategories,
  fetchFormats,
  fetchOnlineFormatTypes,
  fetchPassions,
  fetchSalesTaxCodes,
  selectCertainties,
  selectEntityTypes,
  selectEventCategories,
  selectFormats,
  selectOnlineFormatTypes,
  selectPassions,
  selectSalesTaxCodes,
} from '@admin/store/slices/Settings/dictionariesSlice'
import {
  EntityType,
  EventFormat,
  EventOnlineFormatType,
  ITaxCode,
  ITaxRule,
  TaxesStatuses,
  TaxRuleCertainty,
  TDataGridRowItem,
} from '@admin/store/slices/Settings/interface'
import { fetchAddTaxRule, fetchEditTaxRule, fetchTaxRules, selectTaxesStatus } from '@admin/store/slices/Settings/taxesSlice'
import colors from '@admin/theme/constants/colors'
import { IDropdownItem, IGroupedDropdownItem, ModalAction } from '@admin/types/commonTypes'

import { SalesTaxGroup } from '../constants'
import { ConfirmCancelModal } from './ConfirmCancelModal'
import getTransformedFormData from './utils/getTransformedFormData'
import getValidationSchema from './utils/getValidationSchema'

interface ITaxRuleModalProps {
  action: ModalAction
  item: null | TDataGridRowItem
  onClose: (result?: ITaxRule) => void
  open: boolean
  page: number
  pageSize: number
}

export const TaxRuleModal = ({ action, item, onClose, open, page, pageSize }: ITaxRuleModalProps) => {
  const dispatch = useAppDispatch()
  const isDefaultRule = item?.status === TaxesStatuses.DEFAULT
  const salesTaxRuleId = item?.salesTaxRuleId

  const schema = useMemo(() => getValidationSchema(action, SalesTaxGroup.RULES, isDefaultRule), [action, isDefaultRule])

  const entityTypes = useAppSelector(selectEntityTypes)
  const formats = useAppSelector(selectFormats)
  const onlineFormatTypes = useAppSelector(selectOnlineFormatTypes)
  const eventCategories = useAppSelector(selectEventCategories)
  const passions = useAppSelector(selectPassions)
  const salesTaxCodes = useAppSelector(selectSalesTaxCodes)
  const certainties = useAppSelector(selectCertainties)
  const status = useAppSelector(selectTaxesStatus)
  const isLoading = status === 'loading'

  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false)
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,sonarjs/no-unused-vars
  const [_, setInputValuePassions] = useState('')

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    resetField,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      certainty: '' as TaxRuleCertainty,
      entityType: '' as EntityType,
      eventCategories: [],
      eventOnlineFormatType: '' as EventOnlineFormatType,
      format: '' as EventFormat,
      isDefaultSalesTaxRule: false,
      passions: [[]] as (IGroupedDropdownItem[] | string[] | void)[],
      salesTaxCode: {
        category: '',
        code: '',
        salesTaxCodeId: '',
      } as ITaxCode,
    },
    resolver: yupResolver(schema),
  })

  const {
    append: passionAppend,
    fields: passionFields,
    remove: passionRemove,
  } = useFieldArray({
    name: 'passions',
    control,
  })

  const sortedPassions = useMemo(() => sortBy(passions, 'group'), [passions])
  const filter = createFilterOptions()

  const watchAllFields = watch()

  const isDisabledFormat = watchAllFields.entityType === EntityType.DONATION
  const isDisabledOnlineFormatType =
    (watchAllFields.entityType === EntityType.EVENT && watchAllFields.format === EventFormat.OFFLINE) ||
    watchAllFields.entityType === EntityType.OFFER ||
    watchAllFields.entityType === EntityType.WISH ||
    watchAllFields.entityType === EntityType.PRIVATE_WISH ||
    watchAllFields.entityType === EntityType.DONATION ||
    watchAllFields.isDefaultSalesTaxRule
  const isDisabledEventCategories =
    watchAllFields.entityType === EntityType.OFFER ||
    watchAllFields.entityType === EntityType.WISH ||
    watchAllFields.entityType === EntityType.PRIVATE_WISH ||
    watchAllFields.entityType === EntityType.DONATION ||
    watchAllFields.isDefaultSalesTaxRule
  const isDisabledPassions =
    watchAllFields.entityType === EntityType.PRIVATE_WISH || watchAllFields.entityType === EntityType.DONATION || watchAllFields.isDefaultSalesTaxRule
  const isAllCategoriesSelected = watchAllFields.eventCategories.length === eventCategories.length

  const handleCloseModal = (result?: ITaxRule) => {
    reset({
      certainty: '' as TaxRuleCertainty,
      entityType: '' as EntityType,
      eventCategories: [],
      eventOnlineFormatType: '' as EventOnlineFormatType,
      format: '' as EventFormat,
      isDefaultSalesTaxRule: false,
      passions: [[]],
      salesTaxCode: {
        category: '',
        code: '',
        salesTaxCodeId: '',
      } as ITaxCode,
    })
    onClose(result)
  }
  // eslint-disable-next-line sonarjs/use-type-alias
  const onSubmit = async (data: Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'>) => {
    void trigger()

    const formattedData: Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'> = {
      certainty: data.certainty || null,
      entityType: data.entityType || null,
      eventCategories: data.eventCategories?.map((item) => (isObject(item) ? item.value : item)) || undefined,
      eventOnlineFormatType: data.eventOnlineFormatType || undefined,
      format: data.format || undefined,
      isDefaultSalesTaxRule: data.isDefaultSalesTaxRule ?? isDefaultRule,
      passions: data.passions?.map((innerArray) => innerArray?.map((item) => (isObject(item) ? item.value : item))) || undefined,
      salesTaxCode: data.salesTaxCode,
    }

    try {
      const result =
        action === ModalAction.ADD
          ? await dispatch(fetchAddTaxRule(formattedData))
          : await dispatch(
              fetchEditTaxRule({
                ...formattedData,
                salesTaxRuleId: salesTaxRuleId ? salesTaxRuleId : '',
              }),
            )

      if (result.meta.requestStatus === 'fulfilled') {
        handleCloseModal(result.payload)
        enqueueSnackbar(
          `${watchAllFields.isDefaultSalesTaxRule ? 'Default rule fee' : 'Rule'} has been  ${action === ModalAction.ADD ? 'added' : 'edited'}`,
          {
            variant: 'success' as VariantType,
          },
        )
        void dispatch(fetchTaxRules({ page, pageSize }))
      } else if (result.payload.meta) {
        const { title, meta } = result.payload
        const metaKeys = Object.keys(meta) as Array<keyof Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'>>

        if (metaKeys.length) {
          metaKeys.forEach((key) => setError(key, { message: meta[key] }))
        } else {
          enqueueSnackbar(`An error occurred while ${action === ModalAction.ADD ? 'adding' : 'saving'} the rule: ${title}`, {
            variant: 'error' as VariantType,
          })
        }
      } else {
        enqueueSnackbar(`An error occurred while ${action === ModalAction.ADD ? 'adding' : 'saving'} the rule`, {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar(`An error occurred while ${action === ModalAction.ADD ? 'adding' : 'saving'} the rule`, {
        variant: 'error' as VariantType,
      })
    }
  }

  useEffect(() => {
    if (isDisabledOnlineFormatType) {
      setValue('eventOnlineFormatType', '' as EventOnlineFormatType)
    }
    if (isDisabledEventCategories) {
      setValue('eventCategories', [])
    }
    if (isDisabledPassions) {
      setValue('passions', [[]])
    }
    if (isDisabledFormat) {
      setValue('format', '' as EventFormat)
    }
  }, [isDisabledFormat, isDisabledEventCategories, isDisabledOnlineFormatType, isDisabledPassions, resetField])

  useEffect(() => {
    if (action === ModalAction.EDIT && open && item) {
      const transformedObject = getTransformedFormData(item)

      reset({
        certainty: transformedObject.certainty,
        entityType: transformedObject.entityType,
        eventCategories: transformedObject.eventCategories as [],
        eventOnlineFormatType: transformedObject.eventOnlineFormatType,
        format: transformedObject.format,
        isDefaultSalesTaxRule: isDefaultRule,
        passions: transformedObject.passions as [],
        salesTaxCode: transformedObject.salesTaxCode,
      })
    } else {
      return
    }
  }, [open, action, item])

  useEffect(() => {
    dispatch(fetchEntityTypes())
    dispatch(fetchFormats())
    dispatch(fetchOnlineFormatTypes())
    dispatch(fetchEventCategories())
    dispatch(fetchPassions())
    dispatch(fetchSalesTaxCodes())
    dispatch(fetchCertainties())
  }, [dispatch])

  useLayoutEffect(() => {
    const item = document.querySelector('.MuiDialogContent-root')

    setIsScrollVisible(item?.clientHeight !== item?.scrollHeight)
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog fullWidth maxWidth="sm" open={open} disablePortal>
        <DialogTitle variant="h6">
          {action === ModalAction.ADD ? 'Add new' : 'Edit'} {isDefaultRule && 'default'} rule for sales tax
        </DialogTitle>
        <DialogContentText color="text.primary" ml={3} mr={3} variant="body1">
          All fields are required except for the fields: Type, YZZY category, Passions. But filling in optional fields is desirable
        </DialogContentText>
        <DialogContent className={isScrollVisible ? 'scrollable' : ''}>
          <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} tabIndex={-1} container>
            {isDefaultRule && action === ModalAction.EDIT ? (
              <SimpleGrid xs={12} item>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Entity
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="subtitle1">
                        {startCase(watchAllFields.entityType?.toLowerCase())}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Format
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="subtitle1">
                        {startCase(watchAllFields.format?.toLowerCase()) || '—'}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
              </SimpleGrid>
            ) : (
              <>
                <SimpleGrid xs={12} item>
                  <Controller
                    name="entityType"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          label="Entity"
                          inputprops={{
                            error: !!errors.entityType,
                          }}
                          onChange={(_, value, reason) => {
                            if (reason === 'clear') {
                              field.onChange('')

                              return
                            }
                            field.onChange(value.value)
                            void trigger('entityType')
                          }}
                          value={
                            field.value
                              ? (entityTypes.find((option) => {
                                  return field.value === option.value
                                }) ?? null)
                              : null
                          }
                          clearIcon={null}
                          getOptionLabel={({ titles }) => titles[0]}
                          options={entityTypes || []}
                          renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.titles[0], inputValue)}
                          size="small"
                          // onBlur={() => trigger('entityType')}
                        />
                        <FormHelperText error={!!errors.entityType}>{errors.entityType?.message as string}</FormHelperText>
                      </FormControl>
                    )}
                    control={control}
                  />
                </SimpleGrid>
                <SimpleGrid xs={12} item>
                  <Tooltip title={isDisabledFormat ? 'Not needed for the selected entity' : ''} placement="top" followCursor>
                    <Controller
                      name="format"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <Autocomplete
                            label="Format"
                            inputprops={{
                              error: !!errors.format,
                            }}
                            onChange={(_, value, reason) => {
                              if (reason === 'clear') {
                                field.onChange('')

                                return
                              }
                              field.onChange(value.value)
                              void trigger('format')
                            }}
                            value={
                              field.value
                                ? (formats.find((option) => {
                                    return field.value === option.value
                                  }) ?? null)
                                : null
                            }
                            clearIcon={null}
                            disabled={isDisabledFormat}
                            getOptionLabel={({ titles }) => titles[0]}
                            options={formats || []}
                            renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.titles[0], inputValue)}
                            size="small"
                            // onBlur={() => trigger('format')}
                          />
                          <FormHelperText error={!!errors.format}>{errors.format?.message as string}</FormHelperText>
                        </FormControl>
                      )}
                      control={control}
                    />
                  </Tooltip>
                </SimpleGrid>
                <SimpleGrid xs={12} item>
                  <Controller
                    name="isDefaultSalesTaxRule"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <FormControlLabel
                          label="Default rule"
                          control={
                            <Checkbox
                              checked={field.value}
                              inputRef={field.ref}
                              onChange={(event) => field.onChange(event.target.checked)}
                              disableRipple
                            />
                          }
                          sx={{ pl: 1 }}
                        />
                        <FormHelperText error={!!errors.isDefaultSalesTaxRule}>{errors.isDefaultSalesTaxRule?.message as string}</FormHelperText>
                      </FormControl>
                    )}
                    control={control}
                  />
                </SimpleGrid>

                <SimpleGrid xs={12} item>
                  <Tooltip
                    title={
                      isDisabledOnlineFormatType
                        ? watchAllFields.isDefaultSalesTaxRule
                          ? 'Not needed for default rule'
                          : `Not needed for selected ${
                              watchAllFields.entityType === EntityType.EVENT && watchAllFields.format === EventFormat.OFFLINE ? 'format' : 'entity'
                            }`
                        : ''
                    }
                    placement="top"
                    followCursor
                  >
                    <Controller
                      name="eventOnlineFormatType"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <Autocomplete
                            fullWidth
                            label="Type"
                            inputprops={{
                              error: !!errors.eventOnlineFormatType,
                            }}
                            onChange={(_, value, reason) => {
                              if (reason === 'clear') {
                                field.onChange('')

                                return
                              }
                              field.onChange(value.value)
                              void trigger('eventOnlineFormatType')
                            }}
                            value={
                              field.value
                                ? (onlineFormatTypes.find((option) => {
                                    return field.value === option.value
                                  }) ?? null)
                                : null
                            }
                            clearIcon={null}
                            disabled={isDisabledOnlineFormatType}
                            getOptionLabel={({ titles }) => titles[0]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            options={onlineFormatTypes || []}
                            renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.titles[0], inputValue)}
                            size="small"
                            // onBlur={() => trigger('eventOnlineFormatType')}
                          />
                          <FormHelperText error={!!errors.eventOnlineFormatType}>{errors.eventOnlineFormatType?.message as string}</FormHelperText>
                        </FormControl>
                      )}
                      control={control}
                    />
                  </Tooltip>
                </SimpleGrid>
                <SimpleGrid xs={12} item>
                  <Tooltip
                    title={
                      isDisabledEventCategories
                        ? watchAllFields.isDefaultSalesTaxRule
                          ? 'Not needed for default rule'
                          : 'Not needed for the selected entity'
                        : ''
                    }
                    placement="top"
                    followCursor
                  >
                    <Controller
                      name="eventCategories"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <Autocomplete
                            label="YZZY category"
                            filterOptions={(options, parameters) => {
                              const filtered = filter(options, parameters)

                              return options.length
                                ? [
                                    {
                                      titles: ['All category'],
                                      value: 'all',
                                    },
                                    ...filtered,
                                  ]
                                : []
                            }}
                            inputprops={{
                              error: !!errors.eventCategories,
                            }}
                            onChange={(_, value) => {
                              if (value.find((item: IDropdownItem) => item.value === 'all')) {
                                setValue('eventCategories', value.length - 1 === eventCategories.length ? [] : (eventCategories as []), {
                                  shouldDirty: true,
                                })
                              } else {
                                setValue('eventCategories', value, { shouldDirty: true })
                              }
                              void trigger('eventCategories')
                            }}
                            renderOption={(parameters, option, { inputValue, selected }) =>
                              renderOptions(parameters, option.titles[0], inputValue, selected, true, isAllCategoriesSelected)
                            }
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Fragment key={index}>
                                  {tagValue.length && index !== 0 && (
                                    <Typography sx={{ padding: '0 8px' }} variant="inputText">
                                      OR
                                    </Typography>
                                  )}
                                  <Chip label={option.titles[0]} size="small" variant="filled" {...getTagProps({ index })} />
                                </Fragment>
                              ))
                            }
                            clearIcon={null}
                            disabled={isDisabledEventCategories}
                            getOptionLabel={({ titles }) => titles[0]}
                            isOptionEqualToValue={(option, value) => option.value === value.value && option.titles[0] === value.titles[0]}
                            ListboxProps={{ style: { maxHeight: 300 } }}
                            options={eventCategories || []}
                            size="small"
                            value={field.value}
                            disableCloseOnSelect
                            multiple
                            // onBlur={() => trigger('eventCategories')}
                          />
                          <FormHelperText disabled={isDisabledEventCategories} error={!!errors.eventCategories}>
                            {errors.eventCategories ? (errors.eventCategories?.message as string) : 'All options will be with the OR operator'}
                          </FormHelperText>
                        </FormControl>
                      )}
                      control={control}
                    />
                  </Tooltip>
                </SimpleGrid>

                <SimpleGrid xs={12} item>
                  <Tooltip
                    title={
                      isDisabledPassions
                        ? watchAllFields.isDefaultSalesTaxRule
                          ? 'Not needed for default rule'
                          : 'Not needed for the selected entity'
                        : ''
                    }
                    placement="top"
                    followCursor
                  >
                    <Box
                      alignItems="flex-start"
                      alignSelf="stretch"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      sx={{ border: `1px solid ${colors.divider}`, borderRadius: 2, p: 1 }}
                    >
                      {passionFields.map((item, index) => {
                        return (
                          <Fragment key={item.id}>
                            {passionFields.length && index !== 0 && <Typography variant="body1">OR</Typography>}
                            <Stack alignItems="flex-start" alignSelf="stretch" direction="row" spacing={1}>
                              <Controller
                                name={`passions.${index}` as const}
                                render={({ field }) => {
                                  return (
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        label="Passions"
                                        onChange={(_, value) => {
                                          field.onChange(value.map((item: IGroupedDropdownItem) => item))
                                        }}
                                        onInputChange={(_, newInputValue) => {
                                          setInputValuePassions(newInputValue)
                                        }}
                                        renderOption={(parameters, option, { inputValue, selected }) =>
                                          renderOptions(parameters, option.titles[0], inputValue, selected, true)
                                        }
                                        renderTags={(tagValue, getTagProps) => {
                                          return tagValue.map((option, index) => {
                                            return <Chip label={option.titles[0]} size="small" variant="filled" {...getTagProps({ index })} />
                                          })
                                        }}
                                        clearIcon={null}
                                        disabled={isDisabledPassions}
                                        getOptionDisabled={() => (field.value ? field.value?.length >= 3 : false)}
                                        getOptionLabel={({ titles }) => titles[0]}
                                        groupBy={(option) => option.group}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        options={sortedPassions || []}
                                        size="small"
                                        value={field.value}
                                        disableCloseOnSelect
                                        multiple
                                        // onBlur={() => trigger('passions')}
                                      />
                                      <FormHelperText disabled={isDisabledPassions}>{'Max 3 passions'}</FormHelperText>
                                    </FormControl>
                                  )
                                }}
                                control={control}
                              />
                              {!isDisabledPassions && watchAllFields.passions.length > 1 && (
                                <IconButton disabled={watchAllFields.passions.length === 1} onClick={() => passionRemove(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Fragment>
                        )
                      })}
                      <Button
                        color="primary"
                        disabled={isDisabledPassions}
                        onClick={() => passionAppend([[]])}
                        size="small"
                        startIcon={<PlusIcon sx={{ fontSize: 16 }} />}
                        variant="text"
                      >
                        or other passions
                      </Button>
                    </Box>
                  </Tooltip>
                </SimpleGrid>
              </>
            )}

            <SimpleGrid xs={12} item>
              <Controller
                name="salesTaxCode.category"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Sales tax category"
                      inputprops={{
                        error: !!errors.salesTaxCode?.category,
                      }}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true })
                          setValue('salesTaxCode.code', '', { shouldDirty: true })
                          setValue('salesTaxCode.salesTaxCodeId', '', { shouldDirty: true })

                          return
                        }
                        setValue('salesTaxCode.category', value?.group as string, { shouldDirty: true })
                        setValue('salesTaxCode.code', value?.titles[0] as string, { shouldDirty: true })
                        setValue('salesTaxCode.salesTaxCodeId', value?.value as string, { shouldDirty: true })
                        void trigger('salesTaxCode')
                      }}
                      value={
                        field.value
                          ? (salesTaxCodes.find((option) => {
                              return field.value === option.group
                            }) ?? null)
                          : null
                      }
                      clearIcon={null}
                      getOptionLabel={({ group }) => group}
                      options={salesTaxCodes || []}
                      renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.group, inputValue)}
                      size="small"
                      // onBlur={() => trigger('salesTaxCode')}
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.category}>{errors.salesTaxCode?.category?.message as string}</FormHelperText>
                  </FormControl>
                )}
                control={control}
              />
            </SimpleGrid>

            <SimpleGrid xs={12} item>
              <Controller
                name="salesTaxCode.code"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Sales tax code"
                      inputprops={{
                        error: !!errors.salesTaxCode?.code,
                      }}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true })
                          setValue('salesTaxCode.code', '', { shouldDirty: true })
                          setValue('salesTaxCode.salesTaxCodeId', '', { shouldDirty: true })

                          return
                        }
                        setValue('salesTaxCode.category', value?.group as string, { shouldDirty: true })
                        setValue('salesTaxCode.code', value?.titles[0] as string, { shouldDirty: true })
                        setValue('salesTaxCode.salesTaxCodeId', value?.value as string, { shouldDirty: true })
                        void trigger('salesTaxCode')
                      }}
                      value={
                        field.value
                          ? (salesTaxCodes.find((option) => {
                              return field.value === option.titles[0]
                            }) ?? null)
                          : null
                      }
                      clearIcon={null}
                      getOptionLabel={({ titles }) => titles[0]}
                      options={salesTaxCodes || []}
                      renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.titles[0], inputValue)}
                      size="small"
                      // onBlur={() => trigger('salesTaxCode')}
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.code}>{errors.salesTaxCode?.code?.message as string}</FormHelperText>
                  </FormControl>
                )}
                control={control}
              />
            </SimpleGrid>

            <SimpleGrid xs={12} item>
              <Controller
                name="certainty"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      label="Certainty"
                      inputprops={{
                        error: !!errors.certainty,
                      }}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          field.onChange('')

                          return
                        }
                        field.onChange(value.value)
                        void trigger('certainty')
                      }}
                      value={
                        field.value
                          ? (certainties.find((option) => {
                              return field.value === option.value
                            }) ?? null)
                          : null
                      }
                      clearIcon={null}
                      getOptionLabel={({ titles }) => titles[0]}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      options={certainties || []}
                      renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.titles[0], inputValue)}
                      size="small"
                      // onBlur={() => trigger('certainty')}
                    />
                    <FormHelperText error={!!errors.certainty}>{errors.certainty?.message as string}</FormHelperText>
                  </FormControl>
                )}
                control={control}
              />
            </SimpleGrid>
          </SimpleGrid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              isDirty ? setIsOpenConfirmCancelModal(true) : handleCloseModal(undefined)
            }}
            color="primary"
            variant="text"
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            loading={isLoading}
            type="submit"
            // disabled={!isValid || !isDirty}
            variant="contained"
          >
            {action === ModalAction.ADD ? 'Add rule' : 'Edit rule'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmCancelModal
        action={action}
        actionName="rule"
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        onClose={() => handleCloseModal(undefined)}
        open={isOpenConfirmCancelModal}
      />
    </form>
  )
}
