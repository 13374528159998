import './index.scss'

import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useErrorBoundary } from 'react-error-boundary'
import { useSearchParams } from 'react-router-dom'

import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import ButtonGroup from '@admin/components/shared/ButtonGroup/ButtonGroup'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderExpandableCell from '@admin/components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchSalesTaxCodes } from '@admin/store/slices/Settings/dictionariesSlice'
import { ITaxCode, ITaxRule, ITaxStatus, TDataGridRowItem } from '@admin/store/slices/Settings/interface'
import {
  fetchChangeTaxStatus,
  fetchTaxCodes,
  fetchTaxRules,
  selectTaxCodes,
  selectTaxesStatus,
  selectTaxRules,
  selectTotalCount,
} from '@admin/store/slices/Settings/taxesSlice'
import { ModalAction, Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderActionsCell, RenderCategoryCell, RenderCertaintyCell, RenderPassionsCell, RenderStatusSelect } from './components'
import { ErrorCodes, SalesTaxGroup, selectableTaxStatuses } from './constants'
import { ChangeStatusConfirmModal, ChangeStatusWarningModal, TaxCodeModal, TaxRuleModal } from './modals'

type TTaxRuleModal =
  | {
      action: ModalAction.ADD
      item: null
    }
  | {
      action: ModalAction.EDIT
      item: ITaxRule
    }

export const Taxes: FC = () => {
  const dispatch = useAppDispatch()
  const apiReference = useGridApiRef()

  const [searchParameters, setSearchParameters] = useSearchParams()
  const ruleId = searchParameters.get('ruleId')

  const userPermissions = useAppSelector(selectUserPermissions)
  const taxCodes = useAppSelector(selectTaxCodes)
  const taxRules = useAppSelector(selectTaxRules)
  const totalCount = useAppSelector(selectTotalCount)
  const status = useAppSelector(selectTaxesStatus)
  const isLoading = status === 'loading'

  const { showBoundary } = useErrorBoundary()
  const [isTaxCodeModalOpen, setIsTaxCodeModalOpen] = useState(false)
  const [isTaxRuleModalOpen, setIsTaxRuleModalOpen] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [highlightedRuleId, setHighlightedRuleId] = useState<null | string>(null)
  const [fetchingError, setFetchingError] = useState<boolean>(false)

  const [salesTaxGroup, setSalesTaxGroup] = useState<string>(SalesTaxGroup.RULES)
  const [taxRuleModalState, setTaxRuleModalState] = useState<null | TTaxRuleModal>(null)

  const [paginationModelCodes, setPaginationModelCodes] = useState({
    page: 0,
    pageSize: 25,
  })

  const [paginationModelRules, setPaginationModelRules] = useState({
    page: 0,
    pageSize: 25,
  })
  const [statusMenuAnchorElement, setStatusMenuAnchorElement] = useState<HTMLElement | null>(null)
  const statusMenuOpen = Boolean(statusMenuAnchorElement)

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITaxStatus | null>(null)
  const [currentSelectedRow, setCurrentSelectedRow] = useState<(ITaxCode & ITaxRule) | null>(null)

  const columns: [] | GridColDef[] = useMemo(() => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      return [
        {
          minWidth: 140,
          field: 'category',
          flex: 1,
          headerName: 'Category',
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'code',
          flex: 1,
          headerName: 'Tax code',
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
          sortable: false,
        },
        {
          minWidth: 280,
          field: 'description',
          flex: 3,
          headerName: 'Description',
          renderCell: (parameters) => <RenderExpandableCell {...parameters} />,
          sortable: false,
        },
        {
          minWidth: 100,
          field: 'status',
          flex: 0.4,
          headerName: 'Status',
          renderCell: (parameters) => (
            <RenderStatusSelect
              disablePermission={[Permissions.SETTINGS_CHANGE_TAX_CODE_STATUS]}
              isMenuOpen={statusMenuOpen}
              onClick={(event, data) => handleStatusMenuClick(event, data)}
              props={parameters}
              selectedCellData={currentSelectedRow}
            />
          ),
          sortable: false,
        },
      ]
    } else
      return [
        {
          minWidth: 140,
          field: 'entityType',
          flex: 0.6,
          headerName: 'Entity',
          renderCell: ({ value }) => <span>{value ? startCase(value?.toLowerCase()) : '—'}</span>,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'format',
          flex: 0.6,
          headerName: 'Format',
          renderCell: ({ value }) => <span>{value ? startCase(value?.toLowerCase()) : '—'}</span>,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'eventOnlineFormatType',
          flex: 0.6,
          headerName: 'Type',
          renderCell: ({ value }) => <span>{value ? startCase(value?.toLowerCase()) : '—'}</span>,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'eventCategories',
          flex: 0.6,
          headerName: 'YZZY category',
          renderCell: (parameters) => <RenderCategoryCell props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 160,
          field: 'passions',
          flex: 1,
          headerName: 'Passions',
          renderCell: (parameters) => <RenderPassionsCell props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 160,
          field: 'salesTaxCode.category',
          flex: 1,
          headerName: 'Sales tax category',
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
          sortable: false,
          valueGetter: (_, row) => row.salesTaxCode?.category,
        },
        {
          minWidth: 140,
          field: 'salesTaxCode.code',
          flex: 1,
          headerName: 'Sales tax code',
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
          sortable: false,
          valueGetter: (_, row) => row.salesTaxCode?.code,
        },
        {
          minWidth: 120,
          field: 'certainty',
          flex: 0.6,
          headerName: 'Certainty',
          renderCell: (parameters) => <RenderCertaintyCell props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 100,
          field: 'status',
          flex: 0.4,
          headerName: 'Status',
          renderCell: (parameters) => (
            <RenderStatusSelect
              disablePermission={[Permissions.SETTINGS_CHANGE_TAXES_RULE_STATUS]}
              isMenuOpen={statusMenuOpen}
              onClick={(event, data) => handleStatusMenuClick(event, data)}
              props={parameters}
              selectedCellData={currentSelectedRow}
            />
          ),
          sortable: false,
        },
        {
          minWidth: 70,
          field: 'hidden',
          flex: 0.4,
          headerName: '',
          renderCell: (parameters) => <RenderActionsCell onClick={(event, data) => handleActionMenuClick(event, data)} props={parameters} />,
          sortable: false,
        },
      ]
  }, [salesTaxGroup, statusMenuOpen])

  const rows: [] | ITaxCode[] | ITaxRule[] = useMemo(() => {
    if (salesTaxGroup === SalesTaxGroup.CODES && taxCodes) {
      return taxCodes
    } else if (taxRules) {
      return taxRules
    } else return []
  }, [salesTaxGroup, taxRules, taxCodes])

  const isEmptyData = !rows.length

  const rowCount: number = useMemo(() => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      return totalCount.taxCodesCount
    } else return totalCount.taxRulesCount
  }, [salesTaxGroup, totalCount])

  const page: number = useMemo(() => {
    return salesTaxGroup === SalesTaxGroup.CODES ? paginationModelCodes.page : paginationModelRules.page
  }, [paginationModelCodes.page, paginationModelRules.page, salesTaxGroup])

  const pageSize: number = useMemo(() => {
    return salesTaxGroup === SalesTaxGroup.CODES ? paginationModelCodes.pageSize : paginationModelRules.pageSize
  }, [paginationModelCodes.pageSize, paginationModelRules.pageSize, salesTaxGroup])

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: ITaxCode & ITaxRule) => {
    event.stopPropagation()
    setStatusMenuAnchorElement(event.currentTarget)
    setCurrentSelectedStatus(null)
    setCurrentSelectedRow(data)
  }

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorElement(null)
  }

  const handleChangeStatusClick = (data: ITaxStatus) => {
    setCurrentSelectedStatus(data)
    handleStatusMenuClose()
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      setPaginationModelCodes((previousState) => {
        if (previousState.pageSize !== newPaginationModel.pageSize) {
          void fetchTaxesGrid(0, newPaginationModel.pageSize, salesTaxGroup)

          return { page: 0, pageSize: newPaginationModel.pageSize }
        }
        void fetchTaxesGrid(newPaginationModel.page, newPaginationModel.pageSize, salesTaxGroup)

        return newPaginationModel
      })
    } else {
      setPaginationModelRules((previousState) => {
        if (previousState.pageSize !== newPaginationModel.pageSize) {
          void fetchTaxesGrid(0, newPaginationModel.pageSize, salesTaxGroup)

          return { page: 0, pageSize: newPaginationModel.pageSize }
        }
        void fetchTaxesGrid(newPaginationModel.page, newPaginationModel.pageSize, salesTaxGroup)

        return newPaginationModel
      })
    }
  }

  const fetchTaxesInitial = useCallback(
    async (page: number, pageSize: number, selectedTaxGroup?: string, salesTaxRuleId?: string) => {
      try {
        if (selectedTaxGroup === SalesTaxGroup.CODES) {
          await dispatch(fetchTaxCodes({ page, pageSize })).unwrap()
        } else {
          await dispatch(fetchTaxRules({ page, pageSize, salesTaxRuleId })).unwrap()
        }
      } catch (error) {
        showBoundary(error)
      }
    },
    [dispatch],
  )

  const fetchTaxesGrid = useCallback(
    async (page: number, pageSize: number, selectedTaxGroup?: string, salesTaxRuleId?: string) => {
      if (selectedTaxGroup === SalesTaxGroup.CODES) {
        dispatch(fetchTaxCodes({ page, pageSize })).then((result: TAny) => {
          if (result.meta && result.meta.requestStatus === 'rejected') {
            enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
            setFetchingError(true)
          } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
            setFetchingError(false)
          }
        })
      } else {
        dispatch(fetchTaxRules({ page, pageSize, salesTaxRuleId })).then((result: TAny) => {
          if (result.meta && result.meta.requestStatus === 'rejected') {
            enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
            setFetchingError(true)
          } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
            setPaginationModelRules((previousState) => ({ ...previousState, page: result.payload.currentPage - 1 }))
            setFetchingError(false)
          }
        })
      }
    },
    [dispatch],
  )

  const handleChangeSalesTaxGroup = useCallback(
    (value: string) => {
      if (searchParameters.has('ruleId')) {
        searchParameters.delete('ruleId')
        setSearchParameters(searchParameters)
      }
      setPaginationModelCodes((previousState) => ({ ...previousState, page: 0 }))
      setPaginationModelRules((previousState) => ({ ...previousState, page: 0 }))
      setSalesTaxGroup(value)

      void fetchTaxesGrid(page, pageSize, value)
    },
    [fetchTaxesGrid, searchParameters, pageSize],
  )

  const handleOverlayButtonClick = () => {
    if (salesTaxGroup === SalesTaxGroup.RULES) {
      setIsTaxCodeModalOpen(false)
      setIsTaxRuleModalOpen(true)
      setTaxRuleModalState({ action: ModalAction.ADD, item: null })
    } else {
      setIsTaxRuleModalOpen(false)
      setIsTaxCodeModalOpen(true)
    }
  }

  const handleTaxRuleModalClose = (data: ITaxRule | undefined) => {
    setIsTaxRuleModalOpen(false)
    if (data) {
      setHighlightedRuleId(data.salesTaxRuleId)
      setTimeout(() => {
        setHighlightedRuleId(null)
      }, 2000)
    }
  }

  const handleWarningModalClose = () => {
    setShowWarningModal(false)
  }

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false)
  }

  const repeatFetchingRequest = useCallback(async () => {
    fetchTaxesGrid(page, pageSize, salesTaxGroup)
  }, [dispatch, page, pageSize])

  const initialFetchData = useCallback(async () => {
    await fetchTaxesInitial(paginationModelRules.page, paginationModelRules.pageSize, SalesTaxGroup.RULES, ruleId ? ruleId : undefined)
  }, [paginationModelRules, ruleId])

  const handleActionMenuClick = (event: MouseEvent<HTMLButtonElement>, data: ITaxCode & ITaxRule) => {
    setCurrentSelectedRow(data)
    setCurrentSelectedStatus(null)
    setTaxRuleModalState({ action: ModalAction.EDIT, item: data as ITaxRule })
    data && setIsTaxRuleModalOpen(true)
  }

  const handleChangeStatus = useCallback(async () => {
    const result = await dispatch(
      fetchChangeTaxStatus({
        currentSelectedRow: currentSelectedRow,
        currentSelectedStatus: currentSelectedStatus,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      void fetchTaxesGrid(0, pageSize, salesTaxGroup)
      dispatch(fetchSalesTaxCodes())
    } else if (result.meta.requestStatus === 'rejected' && result.payload.code === ErrorCodes.TAX_CODE_USED_IN_RULES) {
      setShowWarningModal(true)
      dispatch(fetchSalesTaxCodes())
    } else if (result.meta.requestStatus === 'rejected' && result.payload.code === ErrorCodes.INCORRECT_TAX_CODE_STATUS) {
      setShowConfirmModal(true)
      dispatch(fetchSalesTaxCodes())
    } else {
      enqueueSnackbar(result.payload.title ?? `An error occurred, try again later`, {
        variant: 'error' as VariantType,
      })
    }
  }, [currentSelectedStatus, currentSelectedRow])

  useEffect(() => {
    initialFetchData()
  }, [])

  useEffect(() => {
    currentSelectedStatus?.status !== currentSelectedRow?.status && currentSelectedStatus && handleChangeStatus()
  }, [currentSelectedStatus, currentSelectedRow, handleChangeStatus])

  useEffect(() => {
    if (apiReference && ruleId && taxRules && !isLoading) {
      apiReference.current.selectRow(ruleId, true)
    }
  }, [apiReference, ruleId, taxRules, isLoading])

  useEffect(() => {
    const handleSelectRow = () => {
      if (apiReference.current && ruleId) {
        apiReference.current.selectRow(ruleId, false)
      }
    }

    document.addEventListener('mousedown', handleSelectRow)

    return () => {
      document.removeEventListener('mousedown', handleSelectRow)
    }
  }, [apiReference, ruleId])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (apiReference.current && ruleId && taxRules && !isLoading) {
        const rowIndex = apiReference.current.getRowIndexRelativeToVisibleRows(ruleId)

        apiReference.current.scrollToIndexes({ rowIndex: page * pageSize + rowIndex })
        searchParameters.delete('ruleId')
        setSearchParameters(searchParameters)
      }
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [apiReference, ruleId, taxRules, isLoading, page, pageSize])

  return (
    <div className="taxes-table-container">
      <div className="taxes-table-container__actions">
        <ButtonGroup className="taxes-button-group" color="primary" size="medium">
          <Button
            className={salesTaxGroup === SalesTaxGroup.RULES ? 'Mui-selected' : ''}
            disabled={!checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TAXES_RULES])}
            onClick={() => handleChangeSalesTaxGroup(SalesTaxGroup.RULES)}
          >
            Rules For Sales Tax
          </Button>
          <Button
            className={salesTaxGroup === SalesTaxGroup.CODES ? 'Mui-selected' : ''}
            disabled={!checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TAXES_CODES])}
            onClick={() => handleChangeSalesTaxGroup(SalesTaxGroup.CODES)}
          >
            Sales tax codes
          </Button>
        </ButtonGroup>
        {checkPermissions(userPermissions, [
          salesTaxGroup === SalesTaxGroup.CODES ? Permissions.SETTINGS_ADD_TAX_CODE : Permissions.SETTINGS_ADD_TAXES_RULE,
        ]) && (
          <Button onClick={handleOverlayButtonClick} variant="contained">
            <Typography variant="buttonMedium">{salesTaxGroup === SalesTaxGroup.CODES ? 'Add tax code' : 'Add rule'}</Typography>
          </Button>
        )}
      </div>
      <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGrid
            hideFooter={isEmptyData && !fetchingError}
            hideFooterSelectedRowCount
            columnVisibilityModel={{
              hidden: checkPermissions(userPermissions, [Permissions.SETTINGS_EDIT_SALES_TAX]),
            }}
            slots={{
              footer: CustomFooter,
              noRowsOverlay: () =>
                CustomNoRowsOverlay({
                  fetchingError: fetchingError,
                  isFiltersApplied: false,
                  refreshData: () => repeatFetchingRequest(),
                  renderNoDataComponent: () => (
                    <>
                      <Typography color="text.secondary" variant="body2">
                        No {salesTaxGroup === SalesTaxGroup.RULES ? 'rules for sales tax have' : 'sales tax code has'} been added yet
                      </Typography>
                      {checkPermissions(userPermissions, [
                        salesTaxGroup === SalesTaxGroup.CODES ? Permissions.SETTINGS_ADD_TAX_CODE : Permissions.SETTINGS_ADD_TAXES_RULE,
                      ]) && (
                        <Button color="primary" onClick={handleOverlayButtonClick} variant="text">
                          <Typography variant="buttonMedium">{salesTaxGroup === SalesTaxGroup.CODES ? 'Add tax code' : 'Add rule'}</Typography>
                        </Button>
                      )}
                    </>
                  ),
                }),
            }}
            apiRef={apiReference}
            className="taxes-table"
            columnHeaderHeight={64}
            columns={columns}
            getRowClassName={(parameters) => `${parameters.row.salesTaxRuleId === highlightedRuleId ? 'taxes-table__highlighted-row' : ''}`}
            getRowHeight={() => 'auto'}
            getRowId={(row) => (salesTaxGroup === SalesTaxGroup.RULES ? row.salesTaxRuleId : row.id)}
            loading={isLoading}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            paginationModel={{ page, pageSize }}
            rowCount={rowCount}
            rows={rows}
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
            disableVirtualization
            pagination
          />
        </Box>
      </Box>{' '}
      <Menu
        id="status-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={statusMenuAnchorElement}
        onClose={handleStatusMenuClose}
        open={statusMenuOpen}
      >
        {selectableTaxStatuses.map((status: ITaxStatus) => (
          <MenuItem key={status.title} onClick={() => handleChangeStatusClick(status)} selected={status.status === currentSelectedRow?.status}>
            {status.title}
          </MenuItem>
        ))}
      </Menu>
      <TaxCodeModal onClose={() => setIsTaxCodeModalOpen(false)} open={isTaxCodeModalOpen} page={page} pageSize={pageSize} />
      {taxRuleModalState && (
        <TaxRuleModal
          action={taxRuleModalState.action}
          item={taxRuleModalState.action === ModalAction.EDIT ? (taxRuleModalState.item as TDataGridRowItem) : null}
          onClose={(data) => handleTaxRuleModalClose(data)}
          open={isTaxRuleModalOpen}
          page={paginationModelRules.page}
          pageSize={paginationModelRules.pageSize}
        />
      )}
      <ChangeStatusWarningModal onClose={handleWarningModalClose} open={showWarningModal} />
      <ChangeStatusConfirmModal data={currentSelectedRow} onClose={handleConfirmModalClose} open={showConfirmModal} page={page} pageSize={pageSize} />
    </div>
  )
}
