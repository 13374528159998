import { Dispatch, Fragment, SetStateAction } from 'react'

import { alpha } from '@mui/material'
import dayjs from 'dayjs'
import startCase from 'lodash/startCase'
import { createSearchParams, Link } from 'react-router-dom'

import ArrowBack from '@admin/assets/img/ArrowBack'
import ChevronRightIcon from '@admin/assets/img/ChevronRightIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import HistoryIcon from '@admin/assets/img/HistoryIcon'
import Box from '@admin/components/shared/Box/Box'
import Breadcrumbs from '@admin/components/shared/Breadcrumbs/Breadcrumbs'
import Button from '@admin/components/shared/Button/Button'
import Divider from '@admin/components/shared/Divider/Divider'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LinearProgress from '@admin/components/shared/LinearProgress/LinearProgress'
import LinkMUI from '@admin/components/shared/Link/Link'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { TaxModerationRoutes } from '@admin/routes/enum'
import {
  ITaxModerationCard,
  ITaxModerationCardSolution,
  ITaxModerationCardStatus,
  TaxModerationSolution,
  TaxModerationStatus,
} from '@admin/store/slices/TaxModeration/interface'
import colors from '@admin/theme/constants/colors'
import { IGroupedDropdownItem } from '@admin/types/commonTypes'

interface IRenderTaxModerationCardProps {
  currentSolution: ITaxModerationCardSolution | undefined
  currentStatus: ITaxModerationCardStatus | undefined
  handleApproveButtonClick: () => void
  isDisabledApproveButton: boolean
  isDisabledChangeButton: boolean
  isLoading: boolean
  isNewTaxCode: boolean
  setIsOpenChangeTaxCodeModal: Dispatch<SetStateAction<boolean>>
  taxModerationCardDetails: ITaxModerationCard | null
}

export const RenderTaxModerationCard = ({
  currentSolution,
  currentStatus,
  handleApproveButtonClick,
  isDisabledApproveButton,
  isDisabledChangeButton,
  isLoading,
  isNewTaxCode,
  setIsOpenChangeTaxCodeModal,
  taxModerationCardDetails,
}: IRenderTaxModerationCardProps) => {
  const EntityParametersInfo = () => {
    return (
      <Stack flex="30%" gap={0.5} p="8px 0">
        <Typography color="text.primary" sx={{ padding: '6px 24px 6px 16px' }} variant="subtitle2">
          Entity parameters
        </Typography>
        <Stack alignItems="flex-start" alignSelf="flex-start" direction="row">
          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Entity
                </Typography>
              }
              secondary={
                <Typography color="text.primary" variant="body2">
                  {startCase(taxModerationCardDetails?.entityType?.toLowerCase()) || '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Format
                </Typography>
              }
              secondary={
                <Typography color="text.primary" variant="body2">
                  {startCase(taxModerationCardDetails?.format?.toLowerCase()) || '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Type
                </Typography>
              }
              secondary={
                <Typography color="text.primary" variant="body2">
                  {startCase(taxModerationCardDetails?.eventOnlineFormatType?.toLowerCase()) || '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>
        </Stack>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                YZZY category
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {taxModerationCardDetails?.eventCategory || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Passions
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {taxModerationCardDetails?.passions?.join(', ') || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Description
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {taxModerationCardDetails?.entityDescription || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
      </Stack>
    )
  }

  const TaxRuleInfo = () => {
    const renderPassion = (passionGroup: IGroupedDropdownItem[] | string[] | void, index: number) => (
      <Fragment key={index}>
        <>
          {passionGroup &&
            passionGroup.map((passion, subIndex) => (
              <Typography key={subIndex} color="text.primary" display="inline" variant="body2">
                <>
                  {passion}
                  {subIndex < passionGroup.length - 1 && (
                    <Typography color={colors.outlinedBorder} component="span" display="inline" variant="body2">
                      {' + '}
                    </Typography>
                  )}
                </>
              </Typography>
            ))}

          <Typography key={`word-divider-${index}`} color={colors.outlinedBorder} display="inline" variant="body2">
            {taxModerationCardDetails?.salesTaxRule?.passions &&
              taxModerationCardDetails.salesTaxRule.passions.length > 1 &&
              index < taxModerationCardDetails.salesTaxRule.passions.length - 1 &&
              ' or '}
          </Typography>
        </>
      </Fragment>
    )

    return (
      <Stack gap={1}>
        <Stack
          sx={{
            border: `1px solid ${colors.outlinedBorder}`,
            borderRadius: '4px',
          }}
          p="8px 0"
        >
          <Typography color="text.primary" sx={{ padding: '6px 24px 6px 16px' }} variant="subtitle2">
            Rule for sales tax
          </Typography>
          <Stack direction="row">
            <Stack flex="70%" gap={0.5}>
              <Stack alignItems="flex-start" alignSelf="flex-start" direction="row">
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Entity
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="body2">
                        {startCase(taxModerationCardDetails?.salesTaxRule?.entityType?.toLowerCase()) || '—'}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Format
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="body2">
                        {startCase(taxModerationCardDetails?.salesTaxRule?.format?.toLowerCase()) || '—'}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Type
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="body2">
                        {startCase(taxModerationCardDetails?.salesTaxRule?.eventOnlineFormatType?.toLowerCase()) || '—'}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
              </Stack>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      YZZY category
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="body2">
                      {taxModerationCardDetails?.salesTaxRule.eventCategories?.join(', ') || '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography color="text.secondary" variant="body2">
                    Passions
                  </Typography>
                  {taxModerationCardDetails?.salesTaxRule?.passions?.flat().length
                    ? taxModerationCardDetails.salesTaxRule.passions.map(renderPassion)
                    : '—'}
                </ListItemText>
              </ListItem>
            </Stack>
            <Divider orientation="vertical" sx={{ borderStyle: 'dashed' }} flexItem />
            <Stack flex="30%">
              <ListItem>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Sales tax code
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="body2">
                      {taxModerationCardDetails?.salesTaxRule?.salesTaxCode?.code}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Sales tax category
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="body2">
                      {taxModerationCardDetails?.salesTaxRule?.salesTaxCode?.category}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Certainty
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="body2">
                      {startCase(taxModerationCardDetails?.salesTaxRule?.certainty?.toLowerCase()) || '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
            </Stack>
          </Stack>
          <ListItem sx={{ pl: 1 }}>
            <Link
              to={{
                pathname: '/ui/payments-settings/taxes',
                search: `?${createSearchParams({ ruleId: taxModerationCardDetails?.salesTaxRule.id ?? '' })}`,
              }}
              target="_blank"
            >
              <Button>Go to rule</Button>
            </Link>
          </ListItem>
        </Stack>
      </Stack>
    )
  }

  const TaxCodeInfo = () => {
    const handleChangeButtonClick = () => {
      setIsOpenChangeTaxCodeModal(true)
    }

    return (
      <Stack
        sx={{
          backgroundColor: !isNewTaxCode ? alpha(colors.primary, 0.08) : alpha(colors.success, 0.12),
          borderRadius: '4px',
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" p="16px 24px 8px 0px">
          <Typography color="text.primary" sx={{ padding: '6px 24px 6px 16px' }} variant="subtitle2">
            Applied sales tax
          </Typography>
          {!isDisabledChangeButton && (
            <Button onClick={handleChangeButtonClick} size="small" startIcon={<EditIcon />} variant="contained">
              Change
            </Button>
          )}
        </Stack>

        <Stack alignItems="flex-start" direction="row" gap={2}>
          <Stack flex="70%">
            <ListItem>
              <ListItemText disableTypography>
                <Typography color="text.secondary" variant="body2">
                  Description of the sale tax code
                </Typography>
                <Typography color="text.primary" variant="body2">
                  {taxModerationCardDetails?.salesTaxCode.description}
                </Typography>
              </ListItemText>
            </ListItem>
          </Stack>

          <Stack flex="30%">
            <ListItem>
              <ListItemText
                primary={
                  <Typography color="text.secondary" variant="body2">
                    Sales tax code
                  </Typography>
                }
                secondary={
                  <Typography color="text.primary" variant="body2">
                    {taxModerationCardDetails?.salesTaxCode?.code}
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography color="text.secondary" variant="body2">
                    Sales tax category
                  </Typography>
                }
                secondary={
                  <Typography color="text.primary" variant="body2">
                    {taxModerationCardDetails?.salesTaxCode?.category}
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack className="tax-moderation-carousel__card-wrapper">
      {isLoading && <LinearProgress className="progress" variant="query" />}

      <Box className="card-breadcrumbs" component="div">
        <Link className="unstyled-link" color="default" to={TaxModerationRoutes.TAX_MODERATION}>
          <IconButton color="default" size="small">
            <ArrowBack />
          </IconButton>
        </Link>
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <LinkMUI color="text.secondary" component={Link} to={TaxModerationRoutes.TAX_MODERATION} underline="hover" variant="h6">
            Table
          </LinkMUI>
          <div>
            <Typography color="text.primary" display="inline" sx={{ wordBreak: 'break-word' }} variant="h6">
              {`${taxModerationCardDetails?.entityName} `}
            </Typography>
            <Typography color="text.secondary" display="inline" variant="h6">
              (ID {taxModerationCardDetails?.id})
            </Typography>
          </div>
        </Breadcrumbs>
      </Box>

      <Stack alignItems="flex-start" alignSelf="stretch" className="card-content" direction="row" gap={2} p="0 8px 8px 8px">
        <EntityParametersInfo />
        <Stack flex="70%" gap={1}>
          <TaxRuleInfo />
          <TaxCodeInfo />
        </Stack>
      </Stack>

      <Divider orientation="horizontal" flexItem />

      <Stack alignItems="center" direction="row" justifyContent="space-between" pl={1} pr={3}>
        <Stack direction="row" gap={2}>
          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Received
                </Typography>
              }
              secondary={
                <>
                  <Typography color="text.primary" display="inline" variant="body2">
                    {dayjs(taxModerationCardDetails?.createdAt).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography color="text.primary" display="inline" ml={1} variant="body2">
                    {dayjs(taxModerationCardDetails?.createdAt).format('h:mm A')}
                  </Typography>
                </>
              }
              sx={{ whiteSpace: 'nowrap' }}
              disableTypography
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Status
                </Typography>
              }
              secondary={
                <Typography color={currentStatus?.color} variant="body2">
                  {currentStatus && taxModerationCardDetails?.status
                    ? taxModerationCardDetails.status.replace(taxModerationCardDetails.status, currentStatus?.title)
                    : '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>
          {currentSolution && (
            <ListItem>
              <ListItemText
                primary={
                  <Typography color="text.secondary" variant="body2">
                    Solution
                  </Typography>
                }
                secondary={
                  <Typography color={currentSolution?.color} variant="body2">
                    {taxModerationCardDetails?.solution.replace(taxModerationCardDetails?.solution, currentSolution.title)}
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
          )}
        </Stack>
        <Stack direction="row" gap={2} maxHeight="42px" p="8px 0 8px 8px">
          <Button size="small" startIcon={<HistoryIcon />} disabled>
            History
          </Button>
          {isDisabledApproveButton && (
            <Button color="success" onClick={handleApproveButtonClick} size="large" variant="contained">
              Approve
              {currentSolution?.solution === TaxModerationSolution.MODIFIED &&
                (currentStatus?.status === TaxModerationStatus.RESOLVED || currentStatus?.status === TaxModerationStatus.CALCULATED) &&
                ' changes'}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
