import { DataGridPro } from '@mui/x-data-grid-pro'

import type { DataGridProProps } from '@mui/x-data-grid-pro'

interface IDataGridProps extends DataGridProProps {
  label?: string
  primary?: boolean
}

const DataGrid = (props: IDataGridProps) => {
  return <DataGridPro {...props} />
}

export default DataGrid
