import { useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'
import { useForm } from 'react-hook-form'

import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Select, { ISelectOptions } from '@admin/components/shared/Select/Select'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import Stack from '@admin/components/shared/Stack/Stack'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { OperationActions } from '@admin/store/slices/Payments/interface'
import { fetchOperationAction, selectOperationActionStatus } from '@admin/store/slices/Payments/operationsSlice'
import { IRefundReason } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

import getValidationSchema from '../utils/getValidationSchema'
import { ConfirmCancelModal } from './ConfirmCancelModal'

interface IRefundModalProps {
  currency: string
  isInAppOperation: boolean
  onClose: () => void
  onDataRefresh: () => void
  open: boolean
  operationId: string
  refundReasons: IRefundReason[]
  total: number
}

export const RefundModal = ({ currency, isInAppOperation, onClose, onDataRefresh, open, operationId, refundReasons, total }: IRefundModalProps) => {
  const dispatch = useAppDispatch()
  const schema = useMemo(() => getValidationSchema(), [])
  const operationActionStatus = useAppSelector(selectOperationActionStatus)
  const isOperationActionLoading = operationActionStatus === 'loading'

  const refundReasonsLength = refundReasons.length

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      comment: '',
      reason: '',
    },
    resolver: yupResolver(schema),
  })

  const handleClose = () => {
    reset()
    onClose()
  }

  const onSubmit = async (data: { comment?: string; reason: string }) => {
    void trigger()

    const formattedData = {
      action: OperationActions.REFUND,
      comment: data.comment?.trim(),
      inApp: isInAppOperation ? isInAppOperation : null,
      operationId: operationId,
      reason: data.reason,
    }

    try {
      const result = await dispatch(fetchOperationAction(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose()
        enqueueSnackbar('Refund has been completed', {
          variant: 'success' as VariantType,
        })
        onClose()

        onDataRefresh()
      } else if (result.payload) {
        const { title } = result.payload

        enqueueSnackbar(`An error occurred during the refund process: ${title}`, {
          variant: 'error' as VariantType,
        })
      } else {
        enqueueSnackbar('An error occurred during the refund process', {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar('An error occurred during the refund process', {
        variant: 'error' as VariantType,
      })
    }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Refund payment</DialogTitle>
          <DialogContent>
            <Stack direction="row">
              <ListItem>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Refund amount
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="subtitle1">
                      {total ? currencyFormatter(total, 'en-US', 'USD', true) : '—'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography color="text.secondary" variant="body2">
                      Currency
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.primary" variant="subtitle1">
                      {currency || 'USD'}
                    </Typography>
                  }
                  disableTypography
                />
              </ListItem>
            </Stack>
            <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} sx={{ paddingTop: 2 }} container>
              <SimpleGrid pt={2} xs={12} item>
                <Select
                  {...register('reason')}
                  fullWidth
                  name="reason"
                  options={
                    refundReasonsLength > 0
                      ? refundReasons.map(
                          ({ name }) =>
                            ({
                              text: name,
                              value: name,
                            }) as ISelectOptions,
                        )
                      : []
                  }
                  error={!!errors.reason}
                  onBlur={() => trigger('reason')}
                  placeholder="Reason"
                  size="small"
                  variant="outlined"
                />
                {errors.reason?.message && <FormHelperText error={!!errors.reason}>{errors.reason.message}</FormHelperText>}
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('comment')}
                  fullWidth
                  label="Comment"
                  name="comment"
                  error={!!errors.comment}
                  helperText={errors.comment && (errors.comment?.message as string)}
                  maxRows={4}
                  onBlur={() => trigger('comment')}
                  size="small"
                  type="text"
                  multiline
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose()
              }}
              color="primary"
              variant="text"
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              loading={isOperationActionLoading}
              type="submit"
              // disabled={!isValid || !isDirty}
              variant="contained"
            >
              Refund
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal closeModal={() => setIsOpenConfirmCancelModal(false)} onClose={handleClose} open={isOpenConfirmCancelModal} />
    </>
  )
}
