import { StyledEngineProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom'

import Layout from '@admin/components/Layout/Layout'
import LoaderFallback from '@admin/components/LoaderFallback/LoaderFallback'
import Login from '@admin/containers/Authorization/Login'
import SetUpPassword from '@admin/containers/Authorization/SetUpPassword'
import BannerDetails from '@admin/containers/BannerDetails/BannerDetails'
import CollectionDetails from '@admin/containers/CollectionDetails/CollectionDetails'
import ConsumptionRequests from '@admin/containers/ConsumptionRequests/ConsumptionRequests'
import { ConsumptionRequestsCarousel } from '@admin/containers/ConsumptionRequests/views'
import ContentDisplay from '@admin/containers/ContentDisplay/ContentDisplay'
import { ErrorPage } from '@admin/containers/ErrorPage/ErrorPage'
import Moderation from '@admin/containers/Moderation/Moderation'
import NotFound from '@admin/containers/NotFound/NotFound'
import Payments from '@admin/containers/Payments/Payments'
import { Operations } from '@admin/containers/Payments/views'
import Settings from '@admin/containers/PaymentsSettings/PaymentsSettings'
import { InAppTiers, PaymentFees, TariffDetails, TariffGroups, Taxes } from '@admin/containers/PaymentsSettings/views'
import Profile from '@admin/containers/Profile/Profile'
import SnackbarContainer from '@admin/containers/SnackbarContainer'
import { CustomNoCardsOverlay } from '@admin/containers/TaxModeration/components'
import TaxModeration from '@admin/containers/TaxModeration/TaxModeration'
import { TaxModerationCarousel } from '@admin/containers/TaxModeration/views'
import TimMembers from '@admin/containers/TimMembers/TimMembers'
import UserProfile from '@admin/containers/UserProfile/UserProfile'
import Users from '@admin/containers/Users/Users'
import WorldManagement from '@admin/containers/WorldManagement/WorldManagement'

import { ConsumptionRequestsRoutes, MainRoutes, PaymentsRoutes, PaymentsSettingsRoutes, TaxModerationRoutes } from './enum'

const SnackbarProvider = () => {
  return (
    <SnackbarContainer>
      <Outlet />
    </SnackbarContainer>
  )
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <SnackbarProvider />
        </ErrorBoundary>
      }
    >
      <Route element={<LoaderFallback />}>
        <Route element={<Login />} path="/ui/login" />
        <Route element={<SetUpPassword />} path="/ui/members/password-setup" />
        <Route element={<Layout />} path="">
          <Route element={<Users />} path="/ui/users/:tabId?" />
          <Route element={<UserProfile />} path="/ui/users/user/:userId" />
          <Route element={<TimMembers />} path="/ui/members" />
          <Route element={<ContentDisplay />} path="/ui/content-display/:tabId?" />
          <Route element={<Moderation />} path="/ui/moderation/:tabId?" />
          <Route element={<Moderation />} path="/ui/moderation/:tabId?/:userId?" />
          <Route element={<Payments />} path={MainRoutes.PAYMENTS}>
            <Route element={<Navigate to={PaymentsRoutes.OPERATIONS} replace />} path="" />
            <Route element={<Operations />} path={PaymentsRoutes.OPERATIONS} />
            <Route element={<h1>BULK_PAYOUTS</h1>} path={PaymentsRoutes.BULK_PAYOUTS} />
            <Route element={<h1>FINANCE_DATA</h1>} path={PaymentsRoutes.FINANCE_DATA} />
            <Route element={<h1>USER_FINANCE_DATA</h1>} path={PaymentsRoutes.USER_FINANCE_DATA} />
          </Route>
          <Route element={<TaxModeration />} path={TaxModerationRoutes.TAX_MODERATION}>
            <Route element={<TaxModerationCarousel />} path={TaxModerationRoutes.TAX_MODERATION_DETAILS} />
            <Route element={<CustomNoCardsOverlay />} path={TaxModerationRoutes.TAX_MODERATION_ALL_RESOLVED} />
          </Route>
          <Route element={<Settings />} path={MainRoutes.PAYMENTS_SETTINGS}>
            <Route element={<Navigate to={PaymentsSettingsRoutes.TARIFFS} replace />} path="" />
            <Route element={<TariffGroups />} path={PaymentsSettingsRoutes.TARIFFS} />
            <Route element={<TariffDetails />} path={PaymentsSettingsRoutes.TARIFFS_DETAILS} />
            <Route element={<PaymentFees />} path={PaymentsSettingsRoutes.PAYMENT_FEES} />
            <Route element={<InAppTiers />} path={PaymentsSettingsRoutes.IN_APP_TIERS} />
            <Route element={<Taxes />} path={PaymentsSettingsRoutes.TAXES} />
          </Route>
          <Route element={<ConsumptionRequests />} path={ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS}>
            <Route element={<ConsumptionRequestsCarousel />} path={ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS_DETAILS} />
          </Route>
          <Route element={<ContentDisplay />} path="/ui/content-display" />
          <Route element={<Profile />} path="/ui/profile" />
          <Route element={<WorldManagement />} path="/ui/world-management/:tabId?" />
          <Route element={<CollectionDetails />} path="/ui/world-management/collections/:collectionId/:userId?" />
          <Route element={<BannerDetails />} path="/ui/world-management/banners/:bannerId" />
          <Route element={<Navigate to="/ui/users" replace />} path="/" />
          <Route element={<NotFound />} path="*" />
        </Route>
      </Route>
    </Route>,
  ),
)

export const AppRoutes = () => (
  <StyledEngineProvider injectFirst>
    <RouterProvider router={router} />
  </StyledEngineProvider>
)
