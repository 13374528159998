import './index.scss'

import { ChangeEvent, useCallback, useState } from 'react'

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import CloseIcon from '@admin/assets/img/CloseIcon'
import DragIndicatorIcon from '@admin/assets/img/DragIndicatorIcon'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Divider from '@admin/components/shared/Divider/Divider'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Modal from '@admin/components/shared/Modal/Modal'
import Stack from '@admin/components/shared/Stack/Stack'
import Switch from '@admin/components/shared/Switch/Switch'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'
import {
  addColumn,
  applyColumns,
  deletePreset,
  fetchColumnsPresets,
  removeColumn,
  savePreset,
  setActivePreset,
  setNewColumnsArray,
} from '@admin/store/columnsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import colors from '@admin/theme/constants/colors'
import { IColumnItem, IPreset } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

const ColumnsDrawer = (props: TAny) => {
  const dispatch = useAppDispatch()

  const selectedColumns = useAppSelector((state) => state.columns.currentData)
  const presets = useAppSelector((state) => state.columns.presets)
  const allColumns = useAppSelector((state) => state.columns.columns)

  const setPreset = useCallback(
    (presetId: TAny) => {
      dispatch(setActivePreset({ presetId: presetId, type: props.type }))
    },
    [dispatch],
  )

  const activePresetId = useAppSelector((state) => state.columns.activePresetId)

  const [savePresetModal, setSavePresetModal] = useState<boolean>(false)
  const [presetName, setPresetName] = useState<string>('')
  const [deletePresetModal, setDeletePresetModal] = useState<boolean>(false)
  const [deletedPreset, setDeletedPreset] = useState<IPreset | null>(null)

  const showDeletePresetModal = useCallback((preset: IPreset) => {
    setDeletedPreset(preset)
    setDeletePresetModal(true)
  }, [])

  const handleChangeSavePresetModalClose = useCallback(() => {
    setSavePresetModal(false)
  }, [])

  const handleChangeDeletePresetModalClose = useCallback(() => {
    setDeletePresetModal(false)
  }, [])

  const handleSavePreset = useCallback(
    async (presetName: string) => {
      await dispatch(savePreset({ presetName: presetName, type: props.type }))
      handleChangeSavePresetModalClose()
      await dispatch(fetchColumnsPresets(props.type))
    },
    [props.type],
  )

  const handleDeletePreset = useCallback(
    async (presetId: string) => {
      await dispatch(deletePreset({ presetId: presetId, type: props.type }))
      handleChangeDeletePresetModalClose()
      await dispatch(fetchColumnsPresets(props.type))
    },
    [props.type],
  )

  const reorder = (list: IColumnItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newArray = reorder(selectedColumns, result.source.index, result.destination.index)

    dispatch(setNewColumnsArray(newArray))
  }

  // const inactiveColumns = useMemo(() => {
  //   return allColumns.filter(
  //     (item: TAny) => data.findIndex((dataItem: TAny) => dataItem.columnName === item.columnName) === -1,
  //   );
  // }, [data]);

  const handleSwitchChange = (column: IColumnItem, isChecked: boolean) => {
    if (isChecked) {
      dispatch(addColumn(column))
    } else {
      const indexToRemove = selectedColumns.findIndex((item) => item.columnName === column.columnName)

      if (indexToRemove !== -1) {
        dispatch(removeColumn({ index: indexToRemove }))
      }
    }
  }

  return (
    <div className="columnsDrawer--container">
      <div className="columnsDrawer--header">
        <Typography variant="h5">Columns</Typography>
        <IconButton onClick={props.close} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="columnsDrawer--content">
        {presets.length > 0 && (
          <div className="columnsDrawer--group">
            <Typography mb={1} variant="h6">
              Presets
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1.5} mb={2}>
              {presets.map((preset: IPreset) => {
                return (
                  <Chip
                    key={preset.presetId}
                    label={preset.presetName}
                    active={preset.presetId === activePresetId}
                    onClick={() => setPreset(preset.presetId)}
                    onDelete={() => showDeletePresetModal(preset)}
                  />
                )
              })}
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </div>
        )}
        <div className="columnsDrawer--group">
          <Typography variant="h6">Selected columns</Typography>
          <div style={{ marginBottom: '16px' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable direction="vertical" droppableId="droppable" ignoreContainerClipping={false} isCombineEnabled={false} isDropDisabled={false}>
                {(provided: TAny) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedColumns.map((item: IColumnItem, index: TAny) =>
                      item.columnName === 'username' ? (
                        <div key={item.columnName} className="columnsDrawer--item" style={{ padding: '8px 0' }}>
                          <div
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div className="columnsDrawer--item-title">
                              <DragIndicatorIcon sx={{ color: colors.actionDisabled, opacity: '40%' }} />
                              <div>{item.title}</div>
                            </div>
                            <div style={{ justifySelf: 'end' }}>
                              <Switch checked={true} disabled />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Draggable key={item.columnName} draggableId={item.columnName} index={index} isDragDisabled={item.columnName === 'username'}>
                          {(providedItem, snapshot) => {
                            const iconOpacity = snapshot.isDragging ? '40%' : '100%'

                            return (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                className={`columnsDrawer--item${snapshot.isDragging ? '--dragging' : ''}`}
                              >
                                <div style={{ padding: '8px 0' }}>
                                  <div
                                    style={{
                                      width: '100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div className="columnsDrawer--item-title">
                                      <DragIndicatorIcon sx={{ color: colors.actionDisabled, opacity: iconOpacity }} />
                                      <div>{item.title}</div>
                                    </div>
                                    <div style={{ justifySelf: 'end' }}>
                                      <Switch
                                        checked={true}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleSwitchChange(item, event.target.checked)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }}
                        </Draggable>
                      ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Divider orientation="horizontal" flexItem />
        </div>
        {allColumns.length > 0 && (
          <div className="columnsDrawer--group">
            <Typography variant="h6">Available columns</Typography>
            {allColumns.map((item: IColumnItem) => (
              <div key={item.columnName} style={{ padding: '8px 0' }}>
                <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                  <div>{item.title}</div>
                  <div style={{ justifySelf: 'end' }}>
                    <Switch
                      checked={selectedColumns.findIndex((selectedItem: IColumnItem) => selectedItem.columnName === item.columnName) !== -1}
                      disabled={item.columnName === 'username'}
                      onChange={(event) => handleSwitchChange(item, event.target.checked)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="columnsDrawer--footer">
        <Button
          onClick={() => {
            dispatch(applyColumns())
            props.apply()
          }}
          style={{ width: '214px', height: '42px', marginRight: '24px' }}
          variant="contained"
        >
          Apply
        </Button>
        <Button
          onClick={() => {
            setSavePresetModal(true)
          }}
          style={{ width: '114px', height: '42px' }}
          variant="outlined"
          disabled
        >
          Save set
        </Button>
      </div>
      <Dialog fullWidth maxWidth="xs" open={savePresetModal}>
        <DialogTitle variant="h6">Save new preset</DialogTitle>
        <DialogContent>
          <Typography mb={2} variant="subtitle1">
            Enter name for new filter preset
          </Typography>
          <TextField
            fullWidth
            label="Name"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPresetName(event.target.value)}
            size="small"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleChangeSavePresetModalClose()} variant="text">
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleSavePreset(presetName)} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Modal customstyle={{ minHeight: 188 }} onClose={handleChangeDeletePresetModalClose} open={deletePresetModal}>
        <div className="deletePresetModal">
          <div>
            <div className="deletePresetModal--header">Delete preset?</div>
            <div className="deletePresetModal--text">You are going to delete preset {deletedPreset?.presetName}</div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', justifySelf: 'end' }}>
            <Button onClick={() => handleChangeDeletePresetModalClose()} style={{ marginRight: '16px' }}>
              Cancel
            </Button>
            <Button onClick={() => deletedPreset?.presetId && handleDeletePreset(deletedPreset.presetId)} variant="contained">
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ColumnsDrawer
