import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { reducers } from './reducers'

export const store = configureStore({
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(Sentry.createReduxEnhancer({})),
  reducer: reducers,
})

export type TAppDispatch = typeof store.dispatch
export type TAppThunk<ReturnType = void> = ThunkAction<ReturnType, TRootState, unknown, Action<string>>
export type TRootState = ReturnType<typeof store.getState>
