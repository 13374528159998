import './index.scss'

import { useCallback, useEffect, useState } from 'react'

// import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router-dom'

import withAuth from '@admin/components/Authorization/withAuth'
import Modal from '@admin/components/shared/Modal/Modal'
import BannerDetailsCloseModal from '@admin/components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/BannerDetailsCloseModal'
import CollectionDetailsForm from '@admin/components/WorldManagement/Collections/CollectionDetails/CollectionDetailsForm'
// import { ErrorBoundaryErrors } from '@admin/containers/ErrorPage/ErrorPage'
import { fetchCollection, selectCollectionDetails, selectCollectionStatus } from '@admin/store/collectionDetailsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchAllCohorts, selectAllCohorts } from '@admin/store/worldManagementSlice'

import type { TAny } from '@yzzy/types'

function CollectionDetails() {
  const [isOpenClosingModal, setIsOpenClosingModal] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  const collectionDetails = useAppSelector(selectCollectionDetails)
  const status = useAppSelector(selectCollectionStatus)
  const allCohorts = useAppSelector(selectAllCohorts)

  const { collectionId } = useParams()

  // const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const initialFetchData = useCallback(async () => {
    // Promise.all([
    collectionId && dispatch(fetchCollection(collectionId))
    dispatch(fetchAllCohorts())
    // ]).then(result => {
    //   result.forEach((value: any) => {
    //     if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
    //       showBoundary( new Error(value.error.message));
    //     }
    //   })
    // });
  }, [collectionId])

  const handleScroll = (event: TAny) => {
    setScrollTop(event.target.scrollTop)
  }

  useEffect(() => {
    initialFetchData()
  }, [])

  return (
    <div className="collectionDetails" onScroll={handleScroll}>
      {collectionDetails && status === 'idle' && (
        <CollectionDetailsForm
          allCohorts={allCohorts}
          collectionDetails={collectionDetails}
          scrollTop={scrollTop}
          setIsOpenClosingModal={setIsOpenClosingModal}
        />
      )}
      {/* todo почему то я решила использовать баннерную модалку и туть :)
          вот ее надо открывать и при попытке уйти со страницы, а не просто по клику на кнопку "назад"
       */}
      <Modal customstyle={{ minHeight: 188 }} open={isOpenClosingModal}>
        <BannerDetailsCloseModal closeModal={() => setIsOpenClosingModal(false)} onClose={() => navigate('/ui/world-management/collections')} />
      </Modal>
    </div>
  )
}

export default withAuth(CollectionDetails)
