import './index.scss'

import { useMemo } from 'react'

import styled from '@emotion/styled'
import { closeSnackbar, MaterialDesignContent, SnackbarProvider } from 'notistack'
import { useLocation } from 'react-router-dom'

import CheckCircleIcon from '@admin/assets/img/CheckCircleIcon'
import CloseIcon from '@admin/assets/img/CloseIcon'
import ErrorIcon from '@admin/assets/img/ErrorIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import WarningIcon from '@admin/assets/img/WarningIcon'

import type { TAny } from '@yzzy/types'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-error': {
    '& svg': {
      marginRight: '13px',
    },
    backgroundColor: '#B42929',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 0,
    padding: '6px 16px',
  },
  '&.notistack-MuiContent-info': {
    '& svg': {
      marginRight: '13px',
    },
    backgroundColor: '#0288D1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 0,
    padding: '6px 16px',
  },
  '&.notistack-MuiContent-success': {
    '& svg': {
      marginRight: '13px',
    },
    backgroundColor: '#4CA478',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 0,
    padding: '6px 16px',
  },
  '&.notistack-MuiContent-warning': {
    '& svg': {
      marginRight: '13px',
    },
    backgroundColor: '#DA612B',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 0,
    padding: '6px 16px',
  },
}))

const SnackbarContainer = (props: TAny) => {
  const location = useLocation()
  const hasSidebar = useMemo(() => {
    const noSidebarLocations = ['/ui/login', '/ui/members/password-setup']

    return !noSidebarLocations.includes(location.pathname)
  }, [location])

  return (
    <SnackbarProvider
      autoHideDuration={5000}
      classes={{ containerRoot: `snackbarContainer__positioning${hasSidebar ? ' sidebarOffset' : ''}` }}
      maxSnack={3}
      disableWindowBlurListener
      {...props}
      Components={{
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      iconVariant={{
        error: <ErrorIcon sx={{ fontSize: 22 }} />,
        info: <InfoIcon sx={{ fontSize: 22 }} />,
        success: <CheckCircleIcon sx={{ fontSize: 22 }} />,
        warning: <WarningIcon sx={{ fontSize: 22 }} />,
      }}
    />
  )
}

export const action = (snackbarId: TAny) => (
  <div
    onClick={() => {
      closeSnackbar(snackbarId)
    }}
    style={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}
  >
    <CloseIcon />
  </div>
)

export default SnackbarContainer
